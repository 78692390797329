import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { JWTPayload } from "./thunk";
import RoleId from "../../enums/roleId";

export interface AuthState {
  isAuth: boolean;
  userId: string;
  tgUserId: string | null;
  tgUserName: string;
  tgFirstName: string;
  tgLastName: string | null;
  discordUserId: string | null;
  discordUsername: string | null;
  discordGlobalname: string | null;
  email: string;
  roleId: RoleId;
  subscriptionId: number;
  subscriptionStarts: string;
  subscriptionEnds: string;
}

const initialState: AuthState = {
  isAuth: false,
  userId: "",
  tgUserId: null,
  tgUserName: "",
  tgFirstName: "",
  tgLastName: null,
  discordUserId: null,
  discordUsername: null,
  discordGlobalname: null,
  email: "",
  roleId: RoleId.GUEST,
  subscriptionId: -1,
  subscriptionStarts: "",
  subscriptionEnds: "",
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    loginSuccess: (state, action: PayloadAction<JWTPayload>) => {
      state.isAuth = true;
      state.userId = action.payload.id;
      state.tgUserId = action.payload.tgUserId;
      state.tgUserName = action.payload.tgUserName;
      state.tgFirstName = action.payload.tgFirstName;
      state.tgLastName = action.payload.tgLastName;
      state.discordUserId = action.payload.discordUserId;
      state.discordUsername = action.payload.discordUsername;
      state.discordGlobalname = action.payload.discordGlobalname;
      state.email = action.payload.email;
      state.roleId = action.payload.roleId;
      state.subscriptionId = action.payload.subscriptionId;
      state.subscriptionStarts = action.payload.subscriptionStarts;
      state.subscriptionEnds = action.payload.subscriptionEnds;
    },

    logout: (state) => {
      state.isAuth = false;
      state.userId = "";
      state.tgUserId = null;
      state.tgUserName = "";
      state.tgFirstName = "";
      state.tgLastName = null;
      state.discordUserId = null;
      state.discordUsername = null;
      state.discordGlobalname = null;
      state.email = state.roleId = RoleId.GUEST;
      state.subscriptionId = -1;
      state.subscriptionStarts = "";
      state.subscriptionEnds = "";
    },
  },
});

// Action creators are generated for each case reducer function
export const { loginSuccess, logout } = authSlice.actions;

export default authSlice.reducer;
