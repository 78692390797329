import styled from "@emotion/styled";
import { Typography, Container } from "@mui/material";
import React, { useEffect } from "react";
import { WhiteSectionTitle } from "../utils/customStyle";
import Grid from "@mui/material/Unstable_Grid2";
import { useNavigate } from "react-router";
import { useAppDispatch, useAppSelector } from "../stores/hook";
import { RootState } from "../stores/store";
import { useSearchParams } from "react-router-dom";
import { fetchEmailVerification } from "../stores/auth/thunk";
import CustomButtonYellowBg from "../styles/CustomButtonYellowBg";

function EmailVerification() {
  const CustomDescription = styled(Typography)({
    color: "white",
    marginLeft: "10px",
  }) as typeof Typography;

  const isAuth = useAppSelector((state: RootState) => state.auth.isAuth);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const token = searchParams.get("token");

  useEffect(() => {
    if (isAuth) {
      navigate("/my_account");
    }
  }, [isAuth]);

  const onSumbit = () => {
    dispatch(fetchEmailVerification(token));
  };

  return (
    <Container maxWidth="lg" component="main">
      {!isAuth && (
        <>
          <WhiteSectionTitle>Email 地址驗證</WhiteSectionTitle>
          <CustomDescription>您只需點擊下面的連結即可啟動您的帳號：</CustomDescription>
          <Grid container spacing={2} justifyContent="center">
            <Grid xs={12} sm={8} md={6} display="flex" justifyContent="center">
              <CustomButtonYellowBg type={"submit"} color={"primary"} variant="contained" onClick={onSumbit}>
                啟動您的帳號
              </CustomButtonYellowBg>
            </Grid>
          </Grid>
        </>
      )}
    </Container>
  );
}

export default EmailVerification;
