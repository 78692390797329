import { Container, CardMedia, Typography } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import React from "react";
import CustomDescription from "../styles/customDescription";
import SectionTitle from "../styles/sectionTitle";
import SubHeader from "../styles/subHeader";

import AccordionHeader from "../styles/CustomAccordionHeader";
import { WhiteSectionTitle } from "../utils/customStyle";
import indicatorTutorial from "../images/indicatorTutorial/indicatorTutorial.png";
import CustomBox from "../styles/CustomBox";
import CustomAccordion from "../styles/CustomAccordion";
import CustomAccordionSummary from "../styles/CustomAccordionSummary";
import CustomAccordionDetails from "../styles/CustomAccordionDetails";

function IndicatorTutorial() {
  const [expanded, setExpanded] = React.useState<string | false>(false);

  const handleChange = (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
    setExpanded(newExpanded ? panel : false);
  };

  return (
    <>
      <Container maxWidth="lg" component="main">
        <SectionTitle>Daytrade指標派教學</SectionTitle>
        <Container maxWidth="lg" component="main" sx={{ paddingTop: "50px", paddingBottom: "70px" }}>
          <Grid container spacing={3} alignItems="center" justifyContent="center" textAlign="center" color="white">
            <Grid xs={12} md={6} lg={6}>
              <SubHeader>Daytrade指標派</SubHeader>
              <CustomDescription>指標派既可以用作Daytrade，亦可以嘗試捕捉細Timeframe下的高低位以買入/平Swing倉</CustomDescription>
            </Grid>
            <Grid xs={12} md={6} lg={6}>
              <CardMedia
                sx={{ height: "360px", borderWidth: 0, boxShadow: "0px 0px 43px 15px rgba(255, 255, 255, 0.5)" }}
                component="iframe"
                src="https://www.youtube.com/embed/M29ygkzQWDE?si=4Pwj0h7ReOSpJrWJ"
                allowFullScreen
              ></CardMedia>
            </Grid>
          </Grid>
        </Container>

        <WhiteSectionTitle>如何判斷即市做好定淡?</WhiteSectionTitle>

        <CustomAccordion expanded={expanded === "panel1"} onChange={handleChange("panel1")}>
          <CustomAccordionSummary aria-controls="panel1d-content" id="panel1d-header">
            <AccordionHeader>Step 1: 開市前5分鐘到0930</AccordionHeader>
          </CustomAccordionSummary>
          <CustomAccordionDetails>
            <CustomBox>
              <Typography>我會一開市嗰下睇3指係點走</Typography>
              <Typography>先有個大方向 再睇個股</Typography>
              <Typography>我喺牛牛有張list 係有3指/羅素/一堆個股</Typography>
              <Typography>再開多股同列 一眼望晒</Typography>
              <Typography>save定一堆你想玩嘅 例如TSLA/AAPL/NVDA/META/AMZN</Typography>
            </CustomBox>

            <CustomBox>
              <Typography>例如你想玩TSLA 我會建議你save埋佢d朋友 姐係三傻(LI/XPEV/NIO)同RIVN喺張list度</Typography>
              <Typography>點解呢? 因為我會想有個概念 呢堆車股今日點走 big picture好重要 唔好淨9衝入一隻股度 好似瞎子摸象咁</Typography>
            </CustomBox>

            <CustomBox>
              <Typography>仲有可以留意港股嘅走勢</Typography>
              <Typography>例如最近航運股觸發買入(316)加上大大話有聰明錢流入 咁美股ZIM可以坐下</Typography>
            </CustomBox>

            <CustomBox>
              <Typography>仲要留意返Excel d 1-3T/1-3ER嘅位</Typography>
              <Typography>因為係食糊位 要留意返</Typography>
            </CustomBox>

            <CustomBox>
              <Typography>然後如果3指RSI/KDJ低開 而個價又升就會入call</Typography>
              <Typography>反之亦然</Typography>
              <Typography>但留意 唔係次次都準(我諗7成準?)</Typography>
            </CustomBox>
          </CustomAccordionDetails>
        </CustomAccordion>

        <CustomAccordion expanded={expanded === "panel2"} onChange={handleChange("panel2")}>
          <CustomAccordionSummary aria-controls="panel2d-content" id="panel2d-header">
            <AccordionHeader>Step 2: 開市到開市後一兩分鐘</AccordionHeader>
          </CustomAccordionSummary>
          <CustomAccordionDetails>
            <CustomBox>
              <Typography>我唔入手住 等佢fing完頭嗰下就入</Typography>
              <Typography>至於入嘅時機 係跟返熊大指標派嗰套</Typography>
              <Typography>順便證實下自己喺Step 1嘅假設係咪啱</Typography>
            </CustomBox>

            <CustomBox>
              <Typography>記得入場前心入面要有個止蝕位</Typography>
              <Typography>張option蝕幾多個%你會走佬?</Typography>
              <Typography>風險管理好重要 唔好就咁9衝入場</Typography>
              <Typography>同樣都要有止盈位(建議跟Excel食)</Typography>
            </CustomBox>

            <CustomBox>
              <Typography>仲有唔好all-in 唔係想講風險大</Typography>
              <Typography>而係你有可能會想加倉(下面有講)</Typography>
            </CustomBox>
          </CustomAccordionDetails>
        </CustomAccordion>
        <CustomAccordion expanded={expanded === "panel3"} onChange={handleChange("panel3")}>
          <CustomAccordionSummary aria-controls="panel3d-content" id="panel3d-header">
            <AccordionHeader>Step 3: 建倉後</AccordionHeader>
          </CustomAccordionSummary>
          <CustomAccordionDetails>
            <CustomBox>
              <Typography>個方向係咪同你想像中一樣?</Typography>
              <Typography>如果佢回 係真回 定係小回調only?</Typography>
              <Typography>喺小回調嘅時候 你會加倉? 定其實睇錯方向?</Typography>
              <Typography>呢d好靠你嘅盤感/觸覺/對嗰個板塊嘅了解程度</Typography>
              <Typography>未諗到點樣可以做好d 諗到再講🙏🏻</Typography>
            </CustomBox>
          </CustomAccordionDetails>
        </CustomAccordion>

        <WhiteSectionTitle>交易小習慣分享</WhiteSectionTitle>
        <Container maxWidth="lg" component="main" sx={{ paddingTop: "0px", paddingBottom: "50px" }}>
          <Grid container alignItems="center" justifyContent="center" textAlign="left" color="white">
            <Grid xs={12} md={6} lg={6}>
              <CustomBox>
                <CustomDescription>唔知大家會唔會用兩部device去Trade</CustomDescription>
                <CustomDescription>我自己就習慣一部iPad搭一部電話</CustomDescription>
                <CustomDescription>iPad睇市 電話落trade</CustomDescription>
              </CustomBox>

              <CustomBox>
                <CustomDescription>有時間Mon住做短炒指數嘅話</CustomDescription>
                <CustomDescription>我會開牛牛嘅多排並列</CustomDescription>
                <CustomDescription>可以一眼睇哂你想睇嘅股</CustomDescription>
                <CustomDescription>以炒納指為例 我會Group哂一堆火車頭入去 方便我睇到邊隻托緊市 邊隻拖低個市</CustomDescription>
                <CustomDescription>再開埋KDJ指標</CustomDescription>
                <CustomDescription>(呢到我擺左 TSLA NVDA AMZN GOOGL MSFT APPL META SMH)</CustomDescription>
                <CustomDescription>呢幾隻加埋嘅比重{">"}納指40%權重</CustomDescription>
                <CustomDescription>而SMH就方便睇返半導體嘅整體表現</CustomDescription>
              </CustomBox>

              <CustomBox>
                <CustomDescription>唔知幫唔幫到大家</CustomDescription>
                <CustomDescription>但我個人認為幫到我自己炒</CustomDescription>
              </CustomBox>
            </Grid>
            <Grid xs={12} md={6} lg={6}>
              <Container>
                <img src={indicatorTutorial} alt="indicatorTutorial" style={{ width: "inherit" }} />
              </Container>
            </Grid>
          </Grid>
        </Container>
      </Container>
    </>
  );
}

export default IndicatorTutorial;
