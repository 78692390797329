import { deauthorizeDiscord, login, register, verifyEmail } from "../../api/auth.api";
import { AppDispatch, RootState } from "../store";
import { jwtDecode } from "jwt-decode";
import { loginSuccess, logout } from "./authSlice";
import { AxiosError } from "axios";
import { clearError, createError } from "../error/errorSlice";
import { Register } from "../../pages/authentication/Register";
import { doneAuthLoading, doneLoading, startAuthLoading, startLoading } from "../loading/loadingSlice";
import { createSuccessMsg } from "../success/successSlice";
import RoleId from "../../enums/roleId";

interface Login {
  email: string;
  password: string;
}

export interface JWTPayload {
  id: string;
  tgUserId: string | null;
  tgUserName: string;
  tgFirstName: string;
  tgLastName: string | null;
  discordUserId: string | null;
  discordUsername: string | null;
  discordGlobalname: string | null;
  email: string;
  password: string;
  roleId: RoleId;
  subscriptionId: number;
  subscriptionStarts: string;
  subscriptionEnds: string;
}

export function fetchLogin(data: Login) {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  return async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
      dispatch(clearError());
      dispatch(startAuthLoading());
      const result = await login(data);
      if (result.success) {
        const payload: JWTPayload = jwtDecode(result.data);
        dispatch(loginSuccess(payload));
        localStorage.setItem("token", result.data);
      }
      dispatch(doneAuthLoading());
    } catch (error) {
      dispatch(createError(error as AxiosError));
      dispatch(doneAuthLoading());
    }
  };
}

export function fetchAutoLogin() {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  return async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
      dispatch(startLoading());
      dispatch(clearError());
      const token = localStorage.getItem("token");

      if (token) {
        const payload: JWTPayload = jwtDecode(token);
        const { email, password } = payload;

        const isTokenValid = (value: JWTPayload): value is JWTPayload => value?.id !== undefined && value?.discordUserId !== undefined;

        if (!isTokenValid(payload)) {
          dispatch(logout());
          localStorage.removeItem("token");
        } else {
          const result = await login({ email, password });
          if (result.success) {
            const payload: JWTPayload = jwtDecode(result.data);
            dispatch(loginSuccess(payload));
            localStorage.setItem("token", result.data);
          } else {
            dispatch(logout());
            localStorage.removeItem("token");
          }
        }
      }
      dispatch(doneLoading());
    } catch (error) {
      dispatch(logout());
      localStorage.removeItem("token");
      dispatch(createError(error as AxiosError));
      dispatch(doneLoading());
    }
  };
}

export function fetchDeauthorizeDiscord() {
  return async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
      dispatch(clearError());
      dispatch(startLoading());

      const databaseUserId = getState().auth.userId;
      const discordUserId = getState().auth.discordUserId;

      if (!discordUserId) {
        dispatch(doneLoading());
        return;
      }

      await deauthorizeDiscord({ databaseUserId, discordUserId });

      const token = localStorage.getItem("token");
      if (token) {
        const payload: JWTPayload = jwtDecode(token);
        const { email, password } = payload;

        const isTokenValid = (value: JWTPayload): value is JWTPayload => value?.id !== undefined && value?.discordUserId !== undefined;

        if (!isTokenValid(payload)) {
          dispatch(logout());
          localStorage.removeItem("token");
        } else {
          const result = await login({ email, password });
          if (result.success) {
            const payload: JWTPayload = jwtDecode(result.data);
            dispatch(loginSuccess(payload));
            localStorage.setItem("token", result.data);
          } else {
            dispatch(logout());
            localStorage.removeItem("token");
          }
        }
      }

      dispatch(doneLoading());
    } catch (error) {
      dispatch(createError(error as AxiosError));
      dispatch(doneLoading());
    }
  };
}

export function fetchRegister(data: Register) {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  return async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
      dispatch(clearError());
      dispatch(startAuthLoading());

      const { tgUserName, email, password, passwordConfirmation } = data;

      const result = await register({ tgUserName, email, password, passwordConfirmation });

      if (result.success) {
        dispatch(
          createSuccessMsg({
            successMessage: "註冊成功!",
            buttonMessage: "前往登入頁面",
            buttonUrl: (process.env.REACT_APP_SERVER as string) + "/login",
            successExtraMessage: "驗證電子郵件已傳送至您指定的電子郵件地址。",
          })
        );
      }
      dispatch(doneAuthLoading());
    } catch (error) {
      dispatch(createError(error as AxiosError));
      dispatch(doneAuthLoading());
    }
  };
}

export function fetchEmailVerification(token: string | null) {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  return async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
      dispatch(clearError());
      dispatch(startLoading());

      const result = await verifyEmail(token);
      if (result.success) {
        const payload: JWTPayload = jwtDecode(result.data);
        dispatch(loginSuccess(payload));
        localStorage.setItem("token", result.data);
      }

      dispatch(doneLoading());
    } catch (error) {
      dispatch(createError(error as AxiosError));
      dispatch(doneLoading());
    }
  };
}
