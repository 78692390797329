import { AccordionProps, styled } from "@mui/material";

import MuiAccordion from "@mui/material/Accordion";

const CustomAccordion = styled((props: AccordionProps) => <MuiAccordion disableGutters elevation={0} square {...props} />)(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  marginBottom: "25px",
  background: "rgb(26, 25, 46)",
  borderRadius: "10px",
  boxShadow: "0px 0px 5px 5px rgba(255, 255, 255, 0.1)",
  color: "white",
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&:before": {
    display: "none",
  },
}));

export default CustomAccordion;
