import { Container, CardMedia } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import React from "react";
import CustomDescription from "../styles/customDescription";
import SectionTitle from "../styles/sectionTitle";
import SubHeader from "../styles/subHeader";

export default function ChartTutorial() {
  return (
    <Container maxWidth="lg" component="main">
      <SectionTitle>圖表派教學</SectionTitle>
      <Container maxWidth="lg" component="main" sx={{ paddingTop: "50px", paddingBottom: "70px" }}>
        <Grid container spacing={3} alignItems="center" justifyContent="center" textAlign="center" color="white">
          <Grid xs={12} md={6} lg={6}>
            <SubHeader>圖表分析必用工具</SubHeader>
            <CustomDescription>TradingView 教學</CustomDescription>
          </Grid>
          <Grid xs={12} md={6} lg={6}>
            <CardMedia
              sx={{ height: "360px", borderWidth: 0, boxShadow: "0px 0px 43px 15px rgba(255, 255, 255, 0.5)" }}
              component="iframe"
              src="https://www.youtube.com/embed/XG0QAeAwUu4?si=0Ae-yXxZq02eelP_"
              allowFullScreen
            ></CardMedia>
          </Grid>
        </Grid>
      </Container>

      <Container maxWidth="lg" component="main" sx={{ paddingTop: "50px", paddingBottom: "70px" }}>
        <Grid container spacing={3} alignItems="center" justifyContent="center" textAlign="center" color="white">
          <Grid xs={12} md={6} lg={6}>
            <CardMedia
              sx={{ height: "360px", borderWidth: 0, boxShadow: "0px 0px 43px 15px rgba(255, 255, 255, 0.5)" }}
              component="iframe"
              src="https://www.youtube.com/embed/YMRZeJrOamQ?si=3HhpfIvsBk5KkIUC"
              allowFullScreen
            ></CardMedia>
          </Grid>
          <Grid xs={12} md={6} lg={6}>
            <SubHeader>繪圖+副圖指標應用心得</SubHeader>
            <CustomDescription>如何觀察圖表走勢配合訊號 發揮最大威力？</CustomDescription>
          </Grid>
        </Grid>
      </Container>

      <Container maxWidth="lg" component="main" sx={{ paddingTop: "50px", paddingBottom: "70px" }}>
        <Grid container spacing={3} alignItems="center" justifyContent="center" textAlign="center" color="white">
          <Grid xs={12} md={6} lg={6}>
            <SubHeader>熊大TradingView圖表派教學</SubHeader>
            <CustomDescription>炒股心態+實戰圖例</CustomDescription>
          </Grid>
          <Grid xs={12} md={6} lg={6}>
            <CardMedia
              sx={{ height: "360px", borderWidth: 0, boxShadow: "0px 0px 43px 15px rgba(255, 255, 255, 0.5)" }}
              component="iframe"
              src="https://www.youtube.com/embed/R0wE0NFe9_A?si=zkdxjT4eg0EFV0C9"
              allowFullScreen
            ></CardMedia>
          </Grid>
        </Grid>
      </Container>
    </Container>
  );
}
