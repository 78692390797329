import axios, { AxiosError, AxiosInstance, AxiosResponse, InternalAxiosRequestConfig } from "axios";

export const instance = axios.create({
  baseURL: process.env.REACT_APP_API_SERVER,
  withCredentials: true,
});

const onRequest = (config: InternalAxiosRequestConfig): InternalAxiosRequestConfig => {
  console.info(`[request] ${config.method?.toUpperCase()} - /${config.url}`, config);
  return config;
};

const onRequestError = (error: AxiosError): Promise<AxiosError> => {
  console.error("[request error]", error);
  return Promise.reject(error);
};

const onResponse = (response: AxiosResponse): AxiosResponse => {
  console.info(`[response] ${response.config.method?.toUpperCase()} - /${response.config.url}`, response);
  return response;
};

const onResponseError = (error: AxiosError): Promise<AxiosError> => {
  console.error("[response error]", error);
  return Promise.reject(error);
};

function setupInterceptorsTo(axiosInstance: AxiosInstance): AxiosInstance {
  axiosInstance.interceptors.request.use(onRequest, onRequestError);
  axiosInstance.interceptors.response.use(onResponse, onResponseError);

  return axiosInstance;
}

const client = setupInterceptorsTo(instance);

export default client;

export const buildLink = (link: string) => {
  const { baseURL } = instance.defaults;
  return `${baseURL}${link}`;
};
