import React from "react";
import { Box, Container, Typography, styled } from "@mui/material";
import { WhiteSectionTitle } from "../utils/customStyle";

function AboutUs() {
  const CustomDescription = styled(Typography)({
    color: "white",
    // marginLeft: "10px",
  }) as typeof Typography;

  const CustomBox = styled(Box)({
    paddingBottom: "30px",
  });

  return (
    <Container maxWidth="lg" component="main" sx={{ color: "white" }}>
      <WhiteSectionTitle>Smart Money House(SMH)背景</WhiteSectionTitle>

      <CustomBox>
        <CustomDescription>SMH喺一個屬於HongKonger嘅投資交易社群，亦喺聰明人嘅聚腳點。</CustomDescription>
      </CustomBox>

      <CustomBox>
        <CustomDescription>
          SMH成立於2021年，目標向同路人分享自家製AI分析系統，透過唔同分析工具及算法拆解市場數據，精準追蹤聰明錢動向。同時亦透過教學及資源分享，全面提升投資股票/指數/期貨/期權/虛擬貨幣/外匯/商品等等勝率。
        </CustomDescription>
      </CustomBox>

      <CustomBox>
        <CustomDescription>目前SMH設有多個Telegram頻道及Discord社群(詳情可參閱頻道介紹)</CustomDescription>
      </CustomBox>

      <CustomBox>
        <CustomDescription>包括：</CustomDescription>
        <CustomDescription>SMH港股免費頻道</CustomDescription>
        <CustomDescription>SMH美股免費頻道</CustomDescription>
        <CustomDescription>SMH免費CFD頻道</CustomDescription>
        <CustomDescription>SMH x Pionex免費虛擬貨幣頻道</CustomDescription>
      </CustomBox>

      <CustomBox>
        <CustomDescription>以及收費頻道：</CustomDescription>
        <CustomDescription>Smart Money House Topaz Channel（美股及港股）</CustomDescription>
        <CustomDescription>Topaz喺我哋嘅VIP會員頻道</CustomDescription>
        <CustomDescription>
          透過AI信號/技術分析教學/市場數據分析/ Trader’s Chatroom/ Live教學/教科書資源共享等方式，已向過千位付費會員提供優質及精進的市場資訊。
        </CustomDescription>
      </CustomBox>

      <CustomBox>
        <CustomDescription>SMH希望繼續透過精準且客觀的數據分析，以及經驗/專業交易員的教學、互動和分享，提升交易盈利及準確度。</CustomDescription>
      </CustomBox>

      {/* <CustomBox>
        <CustomDescription>Smart Money House成立於2021年3月</CustomDescription>
      </CustomBox>

      <CustomBox>
        <CustomDescription>為向同路人分享自家製AI分析系統</CustomDescription>
        <CustomDescription>透過數據分析精準捕足聰明錢動向</CustomDescription>
        <CustomDescription>全面提升投資股票/指數/期貨/虛擬貨幣/外匯/商品等等勝率</CustomDescription>
      </CustomBox>

      <CustomBox>
        <CustomDescription>
          Smart Money House透過不同分析工具及算法拆解市場數據，當中包括自家皇牌AI系統, 獨家短炒指標, 期權異動分析, 期權成交分怖等等。
        </CustomDescription>
      </CustomBox>

      <CustomBox>
        <CustomDescription>整體分析方法原理是透過收集哂每日不同指數/期貨/股票/期權的交易數據，配合自家AI系統追蹤Smart Money的動向。</CustomDescription>
      </CustomBox>

      <CustomBox>
        <CustomDescription>Smart Money, 一般坊間會譯為「聰明錢」</CustomDescription>
        <CustomDescription>這類錢會在股票拉升/爆升前靜靜流入，於隻股票升到高位，而散戶追貨買入時慢慢流走。 (最後就剩下一排排韭菜被割)</CustomDescription>
      </CustomBox>

      <CustomBox>
        <CustomDescription>
          相反，這類錢亦會在一隻股票下跌/暴跌前賣出或進場沽空，直至跌到低位時平倉，通常中途會出現散戶拋售/進場沽空，但當聰明錢平倉時，股價便會出現支持而反彈。
          (最後又剩返下一排排追沽的韭菜被收割)
        </CustomDescription>
      </CustomBox>

      <CustomBox>
        <CustomDescription>至於什麼原因導致這類錢可以如此聰明？</CustomDescription>
        <CustomDescription>不排除他們眼光獨到， 但大部份的原因就是「資訊不對等」。</CustomDescription>
      </CustomBox>

      <CustomBox>
        <CustomDescription>這類市場參與者擁有的資訊比市場更多及更快，可惜我們沒法偷聽到這些「資訊」，即使你聽到了，你也無法分辨真偽。</CustomDescription>
      </CustomBox>

      <CustomBox>
        <CustomDescription>所以我們不信傳言，而是信數據，數據不會說謊，以交易數據捕捉Smart Money動向。</CustomDescription>
      </CustomBox>

      <CustomBox>
        <CustomDescription>
          Smart Money House
          成立的目是讓大家避免成為最後那一排排嘅韭菜，盡可能跟隨「聰明錢」入場，再跟隨「聰明錢」離場，無需再跟隨市場上的「散戶」和「消息」盲目交易。
        </CustomDescription>
      </CustomBox> */}
    </Container>
  );
}

export default AboutUs;
