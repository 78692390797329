import { Container, Link, Typography } from "@mui/material";
import React from "react";
import SectionTitle from "../styles/sectionTitle";
import { WhiteSectionTitle } from "../utils/customStyle";
import CustomBox from "../styles/CustomBox";
import CustomDescription from "../styles/customDescription";
import CustomAccordion from "../styles/CustomAccordion";
import CustomAccordionDetails from "../styles/CustomAccordionDetails";
import AccordionHeader from "../styles/CustomAccordionHeader";
import CustomAccordionSummary from "../styles/CustomAccordionSummary";
import earningsTutorial1 from "../images/earningsTutorial/earningsTutorial1.png";
import earningsTutorial2 from "../images/earningsTutorial/earningsTutorial2.png";

function EarningsTutorial() {
  const [expanded, setExpanded] = React.useState<string | false>(false);

  const handleChange = (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
    setExpanded(newExpanded ? panel : false);
  };

  return (
    <Container maxWidth="lg" component="main">
      <SectionTitle>賭業績須知</SectionTitle>
      <WhiteSectionTitle>賭業績必備網站📈 </WhiteSectionTitle>
      <CustomBox>
        <CustomDescription>
          查業績日期: <Link href="https://www.earningswhispers.com/">https://www.earningswhispers.com/</Link>
        </CustomDescription>
        <CustomDescription>
          日程表: <Link href="https://twitter.com/eWhispers">https://twitter.com/eWhispers</Link>
        </CustomDescription>
        <CustomDescription>
          查Expected Move: <Link href="https://marketchameleon.com">https://marketchameleon.com</Link>/
        </CustomDescription>
      </CustomBox>

      <CustomAccordion expanded={expanded === "panel1"} onChange={handleChange("panel1")}>
        <CustomAccordionSummary aria-controls="panel1d-content" id="panel1d-header">
          <AccordionHeader>查Expected move方法</AccordionHeader>
        </CustomAccordionSummary>
        <CustomAccordionDetails>
          <CustomBox>
            <Container>
              <img src={earningsTutorial1} alt={"Earnings_Tutorial_1"} style={{ width: "inherit" }} />
            </Container>
            <Container>
              <CustomBox>
                <Typography>Step 1: 輸入要查的個股Ticker</Typography>
                <Typography>Step 2: 按Earnings</Typography>
                <Typography>Step 3: 再按Release Dates</Typography>
              </CustomBox>
            </Container>
            <Container>
              <img src={earningsTutorial2} alt={"Earnings_Tutorial_2"} style={{ width: "inherit" }} />
            </Container>
            <Container>
              <CustomBox>
                <Typography>即可顯示是次業績日期、Expected move及往績</Typography>
              </CustomBox>
            </Container>
          </CustomBox>
        </CustomAccordionDetails>
      </CustomAccordion>

      <CustomAccordion expanded={expanded === "panel2"} onChange={handleChange("panel2")}>
        <CustomAccordionSummary aria-controls="panel2d-content" id="panel2d-header">
          <AccordionHeader>Expected move對用Option賭業績的重要性</AccordionHeader>
        </CustomAccordionSummary>
        <CustomAccordionDetails>
          <CustomBox>
            <CustomDescription>
              請參考以下例子：
              <Link href="https://www.instagram.com/s/aGlnaGxpZ2h0OjE3OTg0MDI2ODA0NDU2OTU4?igshid=MzRlODBiNWFlZA==">
                https://www.instagram.com/s/aGlnaGxpZ2h0OjE3OTg0MDI2ODA0NDU2OTU4?igshid=MzRlODBiNWFlZA==
              </Link>
            </CustomDescription>
          </CustomBox>
        </CustomAccordionDetails>
      </CustomAccordion>
    </Container>
  );
}

export default EarningsTutorial;
