import { Box, Container, Link, useMediaQuery, useTheme } from "@mui/material";
import React, { useEffect } from "react";
import { Controller, FormContainer, SubmitHandler, useForm } from "react-hook-form-mui";
import Grid from "@mui/material/Unstable_Grid2";
import { useAppDispatch, useAppSelector } from "../../stores/hook";
import { fetchLogin } from "../../stores/auth/thunk";
import { RootState } from "../../stores/store";
import { useNavigate } from "react-router-dom";
import { CustomTextField } from "../../styles/customTextField";
import CustomButtonYellowBg from "../../styles/CustomButtonYellowBg";

interface Login {
  email: string;
  password: string;
}

function Login() {
  const formContext = useForm<Login>();
  const {
    control,
    formState: { errors },
  } = formContext;
  const { handleSubmit } = formContext;

  const dispatch = useAppDispatch();

  const onSubmit: SubmitHandler<Login> = async (data) => {
    dispatch(fetchLogin(data));
  };

  const isAuth = useAppSelector((state: RootState) => state.auth.isAuth);

  const navigate = useNavigate();

  useEffect(() => {
    if (isAuth) {
      navigate("/my_account");
    }
  }, [isAuth]);

  const theme = useTheme();
  const isAboveSm = useMediaQuery(theme.breakpoints.up("sm"));

  return (
    <Container maxWidth="lg" component="main" sx={{ color: "white" }}>
      {!isAuth && (
        <Box>
          <FormContainer formContext={formContext} handleSubmit={handleSubmit(onSubmit)}>
            <Grid container spacing={2} justifyContent="center">
              {isAboveSm && <Grid xs={12} sm={2} md={3} display="flex" justifyContent="left"></Grid>}
              <Grid xs={12} sm={8} md={6} display="flex" justifyContent="center" sx={{ fontSize: "30px", fontWeight: "700" }}>
                登入
              </Grid>
              {isAboveSm && <Grid xs={12} sm={2} md={3} display="flex" justifyContent="left"></Grid>}

              {isAboveSm && <Grid xs={12} sm={2} md={3} display="flex" justifyContent="left"></Grid>}
              <Grid xs={12} sm={8} md={6} display="flex" justifyContent="left">
                <Controller
                  control={control}
                  name={"email"}
                  rules={{
                    required: "電郵地址必須填寫",
                    pattern: {
                      value: /\S+@\S+\.\S+/,
                      message: "電郵地址格式不正確",
                    },
                  }}
                  defaultValue={""}
                  render={({ field }) => {
                    return (
                      <CustomTextField
                        {...field}
                        error={errors.email && true}
                        helperText={errors.email && errors.email.message}
                        id="email"
                        label="電郵地址"
                        variant="filled"
                        size="small"
                        fullWidth
                      />
                    );
                  }}
                />
              </Grid>
              {isAboveSm && <Grid xs={12} sm={2} md={3} display="flex" justifyContent="left"></Grid>}

              {isAboveSm && <Grid xs={12} sm={2} md={3} display="flex" justifyContent="left"></Grid>}
              <Grid xs={12} sm={8} md={6} display="flex" justifyContent="left">
                <Controller
                  control={control}
                  name={"password"}
                  rules={{ required: "密碼必須填寫" }}
                  defaultValue={""}
                  render={({ field }) => {
                    return (
                      <CustomTextField
                        {...field}
                        error={errors.password && true}
                        helperText={errors.password && errors.password.message}
                        id="password"
                        label="密碼"
                        variant="filled"
                        size="small"
                        type="password"
                        fullWidth
                      />
                    );
                  }}
                />
              </Grid>
              {isAboveSm && <Grid xs={12} sm={2} md={3} display="flex" justifyContent="left"></Grid>}
              {isAboveSm && <Grid xs={12} sm={2} md={3} display="flex" justifyContent="left"></Grid>}
              <Grid xs={12} sm={8} md={6} display="flex" justifyContent="center">
                <CustomButtonYellowBg type={"submit"} color={"primary"} variant="contained">
                  登入
                </CustomButtonYellowBg>
              </Grid>
              {isAboveSm && <Grid xs={12} sm={2} md={3} display="flex" justifyContent="left"></Grid>}

              {isAboveSm && <Grid xs={12} sm={2} md={3} display="flex" justifyContent="left"></Grid>}
              <Grid xs={12} sm={8} md={6} display="flex" justifyContent="center">
                <Link href={"/forgot_password"}>忘記密碼</Link>
              </Grid>
              {isAboveSm && <Grid xs={12} sm={2} md={3} display="flex" justifyContent="left"></Grid>}
            </Grid>
          </FormContainer>
        </Box>
      )}
    </Container>
  );
}

export default Login;
