/* eslint-disable indent */
// import useWindowDimensions from "../hooks/useWindowDimensions";
import { Box, Container, styled } from "@mui/material";
import React, { useEffect, useState } from "react";
import { WhiteSectionTitle } from "../utils/customStyle";
import { DataGrid, GridRowsProp, GridColDef, GridRenderCellParams, GridCellParams } from "@mui/x-data-grid";
import moment from "moment";
import { getUsSignal } from "../api/signal.api";
import CurrentSignal from "../enums/currentSignal";

interface RowData {
  id: string;
  ticker: string;
  industry: string;
  currentSignal: CurrentSignal;
  newBuySignal: boolean;
  confirmedBuySignal: boolean;
  newSellSignal: boolean;
  confirmedSellSignal: boolean;
  strongBuySignal: boolean;
  strongSellSignal: boolean;
  buyDate: Date | null;
  buyEntry: number | null;
  stopLoss: number | null;
  buyTarget1: number | null;
  buyTarget2: number | null;
  buyTarget3: number | null;
  buyTarget4: number | null;
  buyTarget5: number | null;
  buyTarget6: number | null;
  buyTarget7: number | null;
  buyTarget8: number | null;
  buyTarget9: number | null;
  buyTarget10: number | null;
  buyTarget11: number | null;
  buyTarget12: number | null;
  buyMaxPrice: number | null;
  buyMaxProfit: number | null;
  sellDate: Date | null;
  sellEntry: number | null;
  sellTarget1: number | null;
  sellTarget2: number | null;
  sellTarget3: number | null;
  sellTarget4: number | null;
  sellTarget5: number | null;
  sellTarget6: number | null;
  sellTarget7: number | null;
  sellTarget8: number | null;
  sellTarget9: number | null;
  sellTarget10: number | null;
  sellTarget11: number | null;
  sellTarget12: number | null;
  sellMaxPrice: number | null;
  sellMaxProfit: number | null;
  spot: number;
  tdyChg: number | null;
  dayHigh: number | null;
  dayLow: number | null;
}

function USSignal() {
  //   const ref = React.useRef() as React.MutableRefObject<HTMLIFrameElement>;
  //   const [height, setHeight] = React.useState("0px");
  //   const onLoad = () => {
  //     setHeight(ref.current.contentWindow?.document.body.scrollHeight + "px");
  //   };
  const [rows, setRows] = useState<GridRowsProp<RowData>>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    const fetching = async () => {
      const result = await getUsSignal();
      if (result.success) {
        setRows(result.data);
      }
      setIsLoading(false);
    };
    fetching();
  }, []);

  // const { height } = useWindowDimensions();

  const checkSignalUpdate = (params: GridRenderCellParams<RowData>) => {
    const { buyDate, sellDate, newBuySignal, confirmedBuySignal, newSellSignal, confirmedSellSignal, buyEntry, sellEntry } = params.row;

    const currentDate = moment(new Date());
    const differenceToBuyDate = currentDate.diff(moment(buyDate), "days");
    const differenceToSellDate = currentDate.diff(moment(sellDate), "days");

    return (
      <div>
        {/* <div>
          {strongBuySignal && <div className="strongBuySignal">黃燈</div>}
          {strongSellSignal && <div className="strongSellSignal">橙燈</div>}
        </div> */}
        {newBuySignal && (
          <div>
            <span className={"newBullSignal"}>刷新牛區 {buyEntry} (1日前)</span>
          </div>
        )}
        {newSellSignal && (
          <div>
            <span className={"newBearSignal"}>刷新熊口 {sellEntry} (1日前)</span>
          </div>
        )}
        {confirmedBuySignal && (
          <div>
            <span className={"newBullSignal"}>觸發買入 ({differenceToBuyDate}日前)</span>
          </div>
        )}
        {confirmedSellSignal && (
          <div>
            <span className={"newBearSignal"}>觸發賣出 ({differenceToSellDate}日前)</span>
          </div>
        )}
      </div>
    );
  };

  const checkCurrentSignal = (params: GridRenderCellParams<RowData>) => {
    const { currentSignal, buyDate, sellDate } = params.row;
    const currentBuySignalDate = moment(buyDate);
    const currentSellSignalDate = moment(sellDate);
    const currentDate = moment(new Date());
    const differenceToBuySignalDate = currentDate.diff(currentBuySignalDate, "days");
    const differenceToSellSignalDate = currentDate.diff(currentSellSignalDate, "days");
    const buySignalFormattedDate = moment(buyDate).format("D/M");
    const sellSignalFormattedDate = moment(sellDate).format("D/M");

    return (
      <div>
        <div>
          <span className={differenceToBuySignalDate === 1 || differenceToSellSignalDate === 1 ? "newSignal currentSignal" : "currentSignal"}>
            {currentSignal === CurrentSignal.BUY ? buySignalFormattedDate : currentSignal === CurrentSignal.SELL ? sellSignalFormattedDate : ""}
            {currentSignal === CurrentSignal.BUY
              ? " (" + differenceToBuySignalDate + "日前)"
              : currentSignal === CurrentSignal.SELL
              ? " (" + differenceToSellSignalDate + "日前)"
              : ""}
          </span>
        </div>
        <div className={currentSignal === CurrentSignal.BUY ? "buySignal" : currentSignal === CurrentSignal.SELL ? "sellSignal" : "neutralSignal"}>
          {currentSignal === CurrentSignal.BUY ? "買入" : currentSignal === CurrentSignal.SELL ? "賣出" : "中性"}
        </div>
      </div>
    );
  };

  const getTargetPrice = (params: GridCellParams<RowData>, buyTarget: number | null, sellTarget: number | null) => {
    if (params.row.currentSignal === CurrentSignal.BUY) {
      return <div>{buyTarget ? buyTarget : ""}</div>;
    }

    if (params.row.currentSignal === CurrentSignal.SELL) {
      return <div>{sellTarget ? sellTarget : ""}</div>;
    }

    return <div></div>;
  };

  const getCurrentSignalStyle = (params: GridCellParams<RowData>) => {
    return params.row.strongBuySignal ? "strongBuySignal" : params.row.strongSellSignal ? "strongSellSignal" : "";
  };

  const getEntryPriceStyle = (params: GridCellParams<RowData>) => {
    let style = "";
    const { spot, currentSignal, buyEntry, sellEntry, buyTarget1, sellTarget1 } = params.row;

    if (currentSignal === CurrentSignal.BUY) {
      style = style + "bullEntryPrice";
    }

    if (currentSignal === CurrentSignal.SELL) {
      style = style + "bearEntryPrice";
    }

    if (currentSignal === CurrentSignal.BUY && buyEntry && buyTarget1 && spot >= buyEntry && spot < buyTarget1) {
      style = style + " currentPriceRight";
    }

    if (currentSignal === CurrentSignal.BUY && buyEntry && buyTarget1 && spot < buyEntry) {
      style = style + " currentPriceLeft";
    }

    if (currentSignal === CurrentSignal.SELL && sellEntry && sellTarget1 && spot <= sellEntry && spot > sellTarget1) {
      style = style + " currentPriceRight";
    }

    if (currentSignal === CurrentSignal.SELL && sellEntry && sellTarget1 && spot > sellEntry) {
      style = style + " currentPriceLeft";
    }

    return style;
  };

  const getTargetPriceStyle = (
    currentSignal: string,
    spot: number,
    buyMaxPrice: number | null,
    sellMaxPrice: number | null,
    dayHigh: number | null,
    dayLow: number | null,
    buyTarget1: number | null,
    buyTarget2: number | null,
    sellTarget1: number | null,
    sellTarget2: number | null
  ) => {
    let style = "";

    if (currentSignal === CurrentSignal.BUY && buyTarget1) {
      if (buyTarget2 && spot >= buyTarget1 && spot < buyTarget2) {
        style = style + "currentPriceRight";
      }

      if (!buyTarget2 && spot >= buyTarget1) {
        style = style + "currentPriceRight";
      }

      if ((buyMaxPrice && buyMaxPrice >= buyTarget1) || (dayHigh && dayHigh >= buyTarget1)) {
        style = style + " bullTargetPrice";
      }

      if (buyMaxPrice && buyMaxPrice < buyTarget1 && dayHigh && dayHigh >= buyTarget1) {
        style = style + " newHitTargetPrice";
      }
    }

    if (currentSignal === CurrentSignal.SELL && sellTarget1) {
      if (sellTarget2 && spot <= sellTarget1 && spot > sellTarget2) {
        style = style + "currentPriceRight";
      }

      if (!sellTarget2 && spot <= sellTarget1) {
        style = style + "currentPriceRight";
      }

      if ((sellMaxPrice && sellMaxPrice <= sellTarget1) || (dayLow && dayLow <= sellTarget1)) {
        style = style + " bearTargetPrice";
      }

      if (sellMaxPrice && sellMaxPrice > sellTarget1 && dayLow && dayLow <= sellTarget1) {
        style = style + " newHitTargetPrice";
      }
    }
    return style;
  };

  const getTodayChange = (params: GridCellParams<RowData>) => {
    if (params.row.tdyChg && params.row.tdyChg > 0) {
      return <div className="newBullSignal">+{params.row.tdyChg}%</div>;
    }

    if (params.row.tdyChg && params.row.tdyChg < 0) {
      return <div className="newBearSignal">{params.row.tdyChg}%</div>;
    }

    return <div>ERROR</div>;
  };

  const getMaxProfit = (params: GridCellParams<RowData>) => {
    if (params.row.currentSignal === CurrentSignal.BUY && params.row.buyMaxProfit) {
      return (
        <div className={params.row.buyMaxProfit > 0 ? "newBullSignal" : params.row.buyMaxProfit < 0 ? "newBearSignal" : ""}>{params.row.buyMaxProfit}%</div>
      );
    }

    if (params.row.currentSignal === CurrentSignal.SELL && params.row.sellMaxProfit) {
      return (
        <div className={params.row.sellMaxProfit > 0 ? "newBullSignal" : params.row.sellMaxProfit < 0 ? "newBearSignal" : ""}>{params.row.sellMaxProfit}%</div>
      );
    }

    return <div></div>;
  };

  const columns: GridColDef[] = [
    { field: "ticker", headerName: "股票代碼", width: 80 },
    {
      field: "signalUpdate",
      headerName: "今日訊號更新",
      valueGetter: (value, row: RowData) => row.confirmedBuySignal,
      renderCell: checkSignalUpdate,
      width: 200,
    },
    {
      field: "currentSignal",
      headerName: "現時訊號",
      valueGetter: (value, row: RowData) => row.currentSignal,
      renderCell: checkCurrentSignal,
      cellClassName: (params: GridCellParams<RowData>) => {
        return getCurrentSignalStyle(params);
      },
      width: 90,
    },
    { field: "maxProfit", headerName: "最高盈利", renderCell: getMaxProfit, width: 70 },
    {
      field: "entry",
      headerName: "入場價",
      valueGetter: (value, row: RowData) => {
        if (row.currentSignal === CurrentSignal.BUY) {
          return row.buyEntry;
        }

        if (row.currentSignal === CurrentSignal.SELL) {
          return row.sellEntry;
        }

        return null;
      },
      cellClassName: (params: GridCellParams<RowData>) => {
        return getEntryPriceStyle(params);
      },
      width: 90,
    },
    {
      field: "t1",
      headerName: "目標價1",
      renderCell: (params: GridCellParams<RowData>) => {
        return getTargetPrice(params, params.row.buyTarget1, params.row.sellTarget1);
      },
      cellClassName: (params: GridCellParams<RowData>) => {
        return getTargetPriceStyle(
          params.row.currentSignal,
          params.row.spot,
          params.row.buyMaxPrice,
          params.row.sellMaxPrice,
          params.row.dayHigh,
          params.row.dayLow,
          params.row.buyTarget1,
          params.row.buyTarget2,
          params.row.sellTarget1,
          params.row.sellTarget2
        );
      },
      width: 90,
    },
    {
      field: "t2",
      headerName: "目標價2",
      renderCell: (params: GridCellParams<RowData>) => {
        return getTargetPrice(params, params.row.buyTarget2, params.row.sellTarget2);
      },
      cellClassName: (params: GridCellParams<RowData>) => {
        return getTargetPriceStyle(
          params.row.currentSignal,
          params.row.spot,
          params.row.buyMaxPrice,
          params.row.sellMaxPrice,
          params.row.dayHigh,
          params.row.dayLow,
          params.row.buyTarget2,
          params.row.buyTarget3,
          params.row.sellTarget2,
          params.row.sellTarget3
        );
      },
      width: 90,
    },
    {
      field: "t3",
      headerName: "目標價3",
      renderCell: (params: GridCellParams<RowData>) => {
        return getTargetPrice(params, params.row.buyTarget3, params.row.sellTarget3);
      },
      cellClassName: (params: GridCellParams<RowData>) => {
        return getTargetPriceStyle(
          params.row.currentSignal,
          params.row.spot,
          params.row.buyMaxPrice,
          params.row.sellMaxPrice,
          params.row.dayHigh,
          params.row.dayLow,
          params.row.buyTarget3,
          params.row.buyTarget4,
          params.row.sellTarget3,
          params.row.sellTarget4
        );
      },
      width: 90,
    },
    {
      field: "t4",
      headerName: "目標價4",
      renderCell: (params: GridCellParams<RowData>) => {
        return getTargetPrice(params, params.row.buyTarget4, params.row.sellTarget4);
      },
      cellClassName: (params: GridCellParams<RowData>) => {
        return getTargetPriceStyle(
          params.row.currentSignal,
          params.row.spot,
          params.row.buyMaxPrice,
          params.row.sellMaxPrice,
          params.row.dayHigh,
          params.row.dayLow,
          params.row.buyTarget4,
          params.row.buyTarget5,
          params.row.sellTarget4,
          params.row.sellTarget5
        );
      },
      width: 90,
    },
    {
      field: "t5",
      headerName: "目標價5",
      renderCell: (params: GridCellParams<RowData>) => {
        return getTargetPrice(params, params.row.buyTarget5, params.row.sellTarget5);
      },
      cellClassName: (params: GridCellParams<RowData>) => {
        return getTargetPriceStyle(
          params.row.currentSignal,
          params.row.spot,
          params.row.buyMaxPrice,
          params.row.sellMaxPrice,
          params.row.dayHigh,
          params.row.dayLow,
          params.row.buyTarget5,
          params.row.buyTarget6,
          params.row.sellTarget5,
          params.row.sellTarget6
        );
      },
      width: 90,
    },
    {
      field: "t6",
      headerName: "目標價6",
      renderCell: (params: GridCellParams<RowData>) => {
        return getTargetPrice(params, params.row.buyTarget6, params.row.sellTarget6);
      },
      cellClassName: (params: GridCellParams<RowData>) => {
        return getTargetPriceStyle(
          params.row.currentSignal,
          params.row.spot,
          params.row.buyMaxPrice,
          params.row.sellMaxPrice,
          params.row.dayHigh,
          params.row.dayLow,
          params.row.buyTarget6,
          params.row.buyTarget7,
          params.row.sellTarget6,
          params.row.sellTarget7
        );
      },
      width: 90,
    },
    {
      field: "t7",
      headerName: "目標價7",
      renderCell: (params: GridCellParams<RowData>) => {
        return getTargetPrice(params, params.row.buyTarget7, params.row.sellTarget7);
      },
      cellClassName: (params: GridCellParams<RowData>) => {
        return getTargetPriceStyle(
          params.row.currentSignal,
          params.row.spot,
          params.row.buyMaxPrice,
          params.row.sellMaxPrice,
          params.row.dayHigh,
          params.row.dayLow,
          params.row.buyTarget7,
          params.row.buyTarget8,
          params.row.sellTarget7,
          params.row.sellTarget8
        );
      },
      width: 90,
    },
    {
      field: "t8",
      headerName: "目標價8",
      renderCell: (params: GridCellParams<RowData>) => {
        return getTargetPrice(params, params.row.buyTarget8, params.row.sellTarget8);
      },
      cellClassName: (params: GridCellParams<RowData>) => {
        return getTargetPriceStyle(
          params.row.currentSignal,
          params.row.spot,
          params.row.buyMaxPrice,
          params.row.sellMaxPrice,
          params.row.dayHigh,
          params.row.dayLow,
          params.row.buyTarget8,
          params.row.buyTarget9,
          params.row.sellTarget8,
          params.row.sellTarget9
        );
      },
      width: 90,
    },
    {
      field: "t9",
      headerName: "目標價9",
      renderCell: (params: GridCellParams<RowData>) => {
        return getTargetPrice(params, params.row.buyTarget9, params.row.sellTarget9);
      },
      cellClassName: (params: GridCellParams<RowData>) => {
        return getTargetPriceStyle(
          params.row.currentSignal,
          params.row.spot,
          params.row.buyMaxPrice,
          params.row.sellMaxPrice,
          params.row.dayHigh,
          params.row.dayLow,
          params.row.buyTarget9,
          params.row.buyTarget10,
          params.row.sellTarget9,
          params.row.sellTarget10
        );
      },
      width: 90,
    },
    {
      field: "t10",
      headerName: "目標價10",
      renderCell: (params: GridCellParams<RowData>) => {
        return getTargetPrice(params, params.row.buyTarget10, params.row.sellTarget10);
      },
      cellClassName: (params: GridCellParams<RowData>) => {
        return getTargetPriceStyle(
          params.row.currentSignal,
          params.row.spot,
          params.row.buyMaxPrice,
          params.row.sellMaxPrice,
          params.row.dayHigh,
          params.row.dayLow,
          params.row.buyTarget10,
          params.row.buyTarget11,
          params.row.sellTarget10,
          params.row.sellTarget11
        );
      },
      width: 90,
    },
    {
      field: "t11",
      headerName: "目標價11",
      renderCell: (params: GridCellParams<RowData>) => {
        return getTargetPrice(params, params.row.buyTarget11, params.row.sellTarget11);
      },
      cellClassName: (params: GridCellParams<RowData>) => {
        return getTargetPriceStyle(
          params.row.currentSignal,
          params.row.spot,
          params.row.buyMaxPrice,
          params.row.sellMaxPrice,
          params.row.dayHigh,
          params.row.dayLow,
          params.row.buyTarget11,
          params.row.buyTarget12,
          params.row.sellTarget11,
          params.row.sellTarget12
        );
      },
      width: 90,
    },
    {
      field: "t12",
      headerName: "目標價12",
      renderCell: (params: GridCellParams<RowData>) => {
        return getTargetPrice(params, params.row.buyTarget12, params.row.sellTarget12);
      },
      cellClassName: (params: GridCellParams<RowData>) => {
        return getTargetPriceStyle(
          params.row.currentSignal,
          params.row.spot,
          params.row.buyMaxPrice,
          params.row.sellMaxPrice,
          params.row.dayHigh,
          params.row.dayLow,
          params.row.buyTarget12,
          null,
          params.row.sellTarget12,
          null
        );
      },
      width: 90,
    },
    { field: "spot", headerName: "現價", width: 100 },
    { field: "tdyChg", headerName: "今日漲跌", renderCell: getTodayChange, width: 100 },
  ];

  const StyledDataGrid = styled(DataGrid)(() => ({
    "& .bullEntryPrice": {
      backgroundColor: "rgba(226, 239, 217)",
    },
    "& .bearEntryPrice": {
      backgroundColor: "rgba(253,228,214)",
    },
    "& .bullTargetPrice": {
      backgroundColor: "rgba(146, 196, 124)",
    },
    "& .bearTargetPrice": {
      backgroundColor: "rgba(249, 202, 173)",
    },
    "& .newHitTargetPrice": {
      fontWeight: 700,
    },
    "& .currentPriceRight": {
      borderRight: "2px solid rgb(242, 82, 227)",
    },
    "& .currentPriceLeft": {
      borderLeft: "2px solid rgb(242, 82, 227)",
    },
    "& .newBullSignal": {
      color: "green",
    },
    "& .newBearSignal": {
      color: "red",
    },
    "& .newSignal": {
      color: "red",
    },
    "& .currentSignal": {
      fontSize: "12px",
    },
    "& .strongBuySignal": {
      // padding: "3px 12px",
      // borderRadius: "20px",
      backgroundColor: "yellow",
      // color: "black",
      // fontWeight: 700,
      // width: "fit-content",
    },
    "& .strongSellSignal": {
      // padding: "3px 12px",
      // borderRadius: "20px",
      backgroundColor: "orange",
      // color: "white",
      // fontWeight: 700,
      // width: "fit-content",
    },
    "& .buySignal": {
      padding: "3px 12px",
      borderRadius: "20px",
      backgroundColor: "green",
      color: "white",
      fontWeight: 700,
      width: "fit-content",
    },
    "& .sellSignal": {
      padding: "3px 12px",
      borderRadius: "20px",
      backgroundColor: "red",
      color: "white",
      fontWeight: 700,
      width: "fit-content",
    },
    "& .neutralSignal": {
      padding: "3px 12px",
      borderRadius: "20px",
      backgroundColor: "grey",
      color: "black",
      fontWeight: 700,
      width: "fit-content",
    },
  }));

  return (
    <Container maxWidth="xl" component="main">
      <WhiteSectionTitle>US大股list</WhiteSectionTitle>

      <Box
        sx={{
          height: 800,
          width: "100%",
          marginBottom: "10px",
          "& .super-app-theme--header": {
            backgroundColor: "rgba(85, 130, 52)",
            color: "white",
            fontWeight: "700",
          },
          "& .super-app-theme--date": {
            backgroundColor: "grey",
            color: "white",
            fontWeight: "700",
          },
          "& .super-app-theme--ticker": {
            backgroundColor: "yellow",
            color: "black",
            fontWeight: "700",
          },
        }}
      >
        <StyledDataGrid
          loading={isLoading}
          columnHeaderHeight={35}
          rowHeight={50}
          rows={rows}
          columns={columns}
          sx={{ backgroundColor: "white" }}
          // getRowClassName={(params) => params.row.bull > && "bullTargetPrice"}
        />
      </Box>

      {/* <iframe
        src="https://docs.google.com/spreadsheets/d/e/2PACX-1vQEggeTfdF_5p0b7og-3YLLyUpRD7l8M_abMUsTeqv2KWXialR7DpVXHzEvhgpzGhiGz-MBDcZBbtr8/pubhtml?widget=true&amp;headers=false"
        width="100%"
        height={height - 350}
      ></iframe> */}
    </Container>
  );
}

export default USSignal;
