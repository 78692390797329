import { PayloadAction, createSlice } from "@reduxjs/toolkit";

interface SuccessMessage {
  successMessage: string;
  buttonMessage: string | "繼續";
  buttonUrl: string | null;
  successExtraMessage?: string;
}

export interface SuccessState {
  isSuccess: boolean;
  successMessage: string;
  successExtraMessage?: string;
  buttonMessage: string;
  buttonUrl: string | null;
}

const initialState: SuccessState = {
  isSuccess: false,
  successMessage: "",
  buttonMessage: "繼續",
  buttonUrl: null,
};

export const successSlice = createSlice({
  name: "success",
  initialState,
  reducers: {
    createSuccessMsg: (state, action: PayloadAction<SuccessMessage>) => {
      state.isSuccess = true;
      state.successMessage = action.payload.successMessage;
      state.buttonMessage = action.payload.buttonMessage;
      state.buttonUrl = action.payload.buttonUrl;
      state.successExtraMessage = action.payload.successExtraMessage;
    },
    clearSuccessMsg: (state) => {
      state.isSuccess = false;
      state.successMessage = "";
      state.buttonMessage = "繼續";
      state.buttonUrl = null;
      state.successExtraMessage = undefined;
    },
  },
});

// Action creators are generated for each case reducer function
export const { createSuccessMsg, clearSuccessMsg } = successSlice.actions;

export default successSlice.reducer;
