import React from "react";
import { increment, decrement } from "../stores/counterSlice";
import { RootState } from "../stores/store";
import { useAppDispatch, useAppSelector } from "../stores/hook";
// import { useLoaderData } from "react-router-dom";

export function Counter() {
  const count = useAppSelector((state: RootState) => state.counter.value);
  const dispatch = useAppDispatch();
  // const albums = useLoaderData();
  // console.log(albums);

  return (
    <div>
      <div>
        <button aria-label="Increment value" onClick={() => dispatch(increment())}>
          Increment
        </button>
        <span>{count}</span>
        <button aria-label="Decrement value" onClick={() => dispatch(decrement())}>
          Decrement
        </button>
      </div>
    </div>
  );
}
