import { combineReducers, compose, configureStore } from "@reduxjs/toolkit";
import authReducer from "./auth/authSlice";
import counterReducer from "./counterSlice";
import errorReducer from "./error/errorSlice";
import loadingReducer from "./loading/loadingSlice";
import successReducer from "./success/successSlice";

declare global {
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__: typeof compose;
  }
}

export const store = configureStore({
  reducer: combineReducers({
    auth: authReducer,
    counter: counterReducer,
    error: errorReducer,
    loading: loadingReducer,
    success: successReducer,
  }),
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
