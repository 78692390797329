import * as React from "react";
import {
  AppBar,
  Avatar,
  Box,
  Button,
  ClickAwayListener,
  Collapse,
  Grow,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Menu,
  MenuItem,
  Paper,
  Popper,
  ThemeProvider,
  Toolbar,
  Tooltip,
  TooltipProps,
  Typography,
  createTheme,
  styled,
  tooltipClasses,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import smhLogo from "../../images/smhLogo.png";
import { yellow } from "@mui/material/colors";
import { useLocation, useNavigate } from "react-router-dom";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import LoginIcon from "@mui/icons-material/Login";
import { useAppDispatch, useAppSelector } from "../../stores/hook";
import { RootState } from "../../stores/store";
import { logout } from "../../stores/auth/authSlice";
import HowToRegIcon from "@mui/icons-material/HowToReg";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import LogoutIcon from "@mui/icons-material/Logout";
import PersonIcon from "@mui/icons-material/Person";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import RoleId from "../../enums/roleId";

interface Page {
  emoji: string;
  title: string;
  path: string;
  href?: string;
  disabled: boolean;
  children: Child[];
}

interface Child {
  emoji: string;
  title: string;
  path: string;
  href?: string;
}

const pages: Page[] = [
  { emoji: "🏠", title: "主頁", path: "/", disabled: false, children: [] },
  { emoji: "？", title: "關於我們", path: "/about_us", disabled: false, children: [] },
  {
    emoji: "🧑‍🏫",
    title: "教學資源工具簡介",
    path: "",
    disabled: false,
    children: [
      { emoji: "🤖", title: "獨家皇牌AI信號系統", path: "/signal" },
      { emoji: "📈", title: "短炒指標教學", path: "/indicator_intro" },
      { emoji: "🏦", title: "大戶期權異動Bot", path: "/option_bot_intro" },
      { emoji: "📊", title: "技術分析教學 - 圖表派", path: "/chart_analysis_intro" },
    ],
  },
  {
    emoji: "🆓",
    title: "免費頻道",
    path: "",
    disabled: false,
    children: [
      { emoji: "💷", title: "免費CFD頻道", path: "/cfd_channel_intro" },
      { emoji: "🪙", title: "免費PIONEX-CRYPTO頻道", path: "/pionex_crypto_channel_intro" },
      { emoji: "🔷", title: "其他頻道", path: "/other_channel_intro" },
    ],
  },
  {
    emoji: "💰",
    title: "收費頻道",
    path: "/price_plan",
    disabled: false,
    children: [],
  },
  { emoji: "🚀", title: "會員戰績回顧", path: "/testimonial", disabled: false, children: [] },
  { emoji: "💷", title: "專屬開戶獎賞", path: "", href: "https://linktr.ee/smart_money_house", disabled: false, children: [] },
  {
    emoji: "💳",
    title: "會員區",
    path: "",
    disabled: false,
    children: [
      { emoji: "📡", title: "信號更新區 (🇺🇸美股)", path: "/us_signal" },
      { emoji: "📚", title: "新手分享／資源區", path: "/members_orientation" },
      // { emoji: "📡", title: "信號更新區 (🇭🇰港股)", path: "/hk_signal" },
      { emoji: "📚", title: "AI信號派教學", path: "/signal_tutorial" },
      { emoji: "📚", title: "圖表教學", path: "/chart_tutorial" },
      { emoji: "📚", title: "Daytrade指標派教學", path: "/indicator_tutorial" },
      { emoji: "📚", title: "期權教學區", path: "/option_tutorial" },
      { emoji: "📚", title: "期權異動Bot教學", path: "/option_bot_tutorial" },
      { emoji: "📚", title: "賭業績須知", path: "/earnings_tutorial" },
      { emoji: "📚", title: "CFD開戶及操作", path: "/cfd_tutorial" },
      { emoji: "📚", title: "投資書籍及學習資源", path: "", href: "https://drive.google.com/drive/folders/1-oziY_x98MvMV-b2Yicqj_a5K3RO_rW_?usp=sharing" },
    ],
  },
];

const adminSettings = [
  { title: "上載會員資料", icon: <UploadFileIcon sx={{ mr: 1 }} />, path: "/upload_users" },
  { title: "我的會籍", icon: <PersonIcon sx={{ mr: 1 }} />, path: "/my_account" },
  { title: "登出", icon: <LogoutIcon sx={{ mr: 1 }} />, path: "" },
];

const settings = [
  { title: "我的會籍", icon: <PersonIcon sx={{ mr: 1 }} />, path: "/my_account" },
  { title: "登出", icon: <LogoutIcon sx={{ mr: 1 }} />, path: "" },
];
// const settings = ["Profile", "Account", "Dashboard", "Logout"];

function NavBar() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const theme = createTheme({
    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        md: 1030,
        lg: 1200,
        xl: 1536,
      },
    },
  });

  const CustomTooltip = styled(({ className, ...props }: TooltipProps) => <Tooltip {...props} classes={{ popper: className }} />)(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: "rgba(26, 25, 50, 1)",
      color: "rgba(0, 0, 0, 0.87)",
      maxWidth: 300,
      fontSize: theme.typography.pxToRem(12),
      border: "2px solid #ffffff",
      borderRadius: "10px",
      padding: 0,
    },
  }));

  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(null);

  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const navigateToPath = (path: string) => {
    navigate(path);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const isLoading = useAppSelector((state: RootState) => state.loading.isLoading);
  const isAuth = useAppSelector((state: RootState) => state.auth.isAuth);
  const roleId = useAppSelector((state: RootState) => state.auth.roleId);

  const onLogout = () => {
    dispatch(logout());
    localStorage.removeItem("token");
    handleCloseUserMenu();
  };

  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef<HTMLButtonElement>(null);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: Event | React.SyntheticEvent) => {
    if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
      return;
    }

    setOpen(false);
  };

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current!.focus();
    }

    prevOpen.current = open;
  }, [open]);

  const [openIntro, setOpenIntro] = React.useState({
    settings: [
      { title: "教學資源工具簡介", open: false },
      { title: "免費頻道", open: false },
      { title: "會員區", open: false },
    ],
  });

  const handleClick = (title: string) => {
    setOpenIntro((state) => ({
      ...state,
      settings: state.settings.map((item) => (item.title === title ? { ...item, open: !item.open } : item)),
    }));
  };

  return (
    <ThemeProvider theme={theme}>
      <AppBar position="static" sx={{ backgroundColor: "transparent", boxShadow: "none" }}>
        {!isLoading && (
          <Toolbar disableGutters>
            <Box
              component="img"
              src={smhLogo}
              sx={{ display: { xs: "none", md: "flex" }, mr: 1, width: "40px", height: "40px", cursor: "pointer" }}
              onClick={() => {
                navigateToPath("/");
              }}
            />

            <Box sx={{ display: { xs: "flex", md: "none" } }}>
              <div>
                <IconButton
                  ref={anchorRef}
                  id="composition-button"
                  aria-controls={open ? "composition-menu" : undefined}
                  aria-expanded={open ? "true" : undefined}
                  aria-haspopup="true"
                  onClick={handleToggle}
                  color="inherit"
                >
                  <MenuIcon />
                </IconButton>
                <Popper
                  open={open}
                  anchorEl={anchorRef.current}
                  role={undefined}
                  placement="bottom-start"
                  transition
                  disablePortal
                  sx={{ width: "-webkit-fill-available", zIndex: "3" }}
                >
                  {({ TransitionProps, placement }) => (
                    <Grow
                      {...TransitionProps}
                      style={{
                        transformOrigin: placement === "bottom-start" ? "left top" : "left bottom",
                      }}
                    >
                      <Paper sx={{ backgroundColor: "none" }}>
                        <ClickAwayListener onClickAway={handleClose}>
                          <List
                            sx={{
                              backgroundColor: "rgba(36, 35, 65, 1)",
                              color: "white",
                              border: "3px solid #ffffff",
                              borderRadius: "5px",
                              maxHeight: 700,
                              overflow: "auto",
                            }}
                            component="nav"
                            aria-labelledby="nested-list-subheader"
                            // subheader={
                            //   <ListSubheader component="div" id="nested-list-subheader">
                            //     Nested List Items
                            //   </ListSubheader>
                            // }
                          >
                            {pages
                              .filter((page) => (!isAuth ? page.title !== "會員區" : page))
                              .map((page) => (
                                <React.Fragment key={page.title}>
                                  <ListItemButton
                                    onClick={() => {
                                      if (page.children.length === 0) {
                                        if (page.href) {
                                          window.open(page.href);
                                        } else {
                                          navigateToPath(page.path);
                                        }
                                        handleToggle();
                                      } else {
                                        handleClick(page.title);
                                      }
                                    }}
                                  >
                                    <ListItemText
                                      primary={
                                        <Typography fontWeight="700">
                                          {page.emoji} {page.title}
                                        </Typography>
                                      }
                                    />
                                    {page.children.length === 0 ? null : openIntro.settings.find((item) => item.title === page.title)?.open ? (
                                      <ExpandLess />
                                    ) : (
                                      <ExpandMore />
                                    )}
                                  </ListItemButton>

                                  {page.children.length !== 0 && (
                                    <Collapse in={openIntro.settings.find((item) => item.title === page.title)?.open} timeout="auto" unmountOnExit>
                                      <List component="div" disablePadding>
                                        {page.children.map((child: Child, index) => {
                                          return (
                                            <ListItemButton
                                              disabled={child.path === "" && child.href === ""}
                                              sx={{ pl: 4 }}
                                              key={index}
                                              onClick={(e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
                                                if (child.href) {
                                                  window.open(child.href);
                                                  handleClose(e);
                                                }
                                                navigateToPath(child.path);
                                                handleClose(e);
                                              }}
                                            >
                                              <ListItemText
                                                primary={
                                                  <Typography fontWeight="500">
                                                    {child.emoji} {child.title}
                                                  </Typography>
                                                }
                                              />
                                            </ListItemButton>
                                          );
                                        })}
                                      </List>
                                    </Collapse>
                                  )}
                                </React.Fragment>
                              ))}
                          </List>
                        </ClickAwayListener>
                      </Paper>
                    </Grow>
                  )}
                </Popper>
              </div>
            </Box>

            <Box
              sx={{
                display: { xs: "flex", md: "none" },
                mr: 1,
                flexGrow: 1,
                justifyContent: "center",
              }}
            >
              <Box
                component="img"
                src={smhLogo}
                sx={{
                  width: "40px",
                  height: "40px",
                  cursor: "pointer",
                }}
                onClick={() => {
                  navigateToPath("/");
                }}
              />
            </Box>

            <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}>
              {pages
                .filter((page) => (!isAuth ? page.title !== "會員區" : page))
                .map((page) => (
                  <CustomTooltip
                    placement="bottom-start"
                    sx={{ popper: "white", minWidth: "230px" }}
                    key={page.title}
                    disableFocusListener={page.disabled || page.children.length !== 0}
                    disableTouchListener={page.disabled || page.children.length !== 0}
                    title={
                      page.children.length === 0 ? (
                        ""
                      ) : (
                        <Box sx={{ fontSize: "15px", justifyContent: "flex-end" }}>
                          <List sx={{ padding: 0 }}>
                            {page.children.map((child: Child, index) => {
                              return (
                                <ListItem disablePadding key={index} dense sx={{ fontSize: "10px", color: "white" }}>
                                  <ListItemButton
                                    disabled={child.path === "" && !child.href}
                                    component="button"
                                    onClick={() => {
                                      if (child.href) {
                                        window.open(child.href);
                                      }
                                      navigateToPath(child.path);
                                    }}
                                  >
                                    <ListItemText
                                      primary={
                                        <Typography fontWeight={700}>
                                          {child.emoji} {child.title}
                                        </Typography>
                                      }
                                      // primaryTypographyProps={{ fontWeight: 700 }}
                                    />
                                  </ListItemButton>
                                </ListItem>
                              );
                            })}
                          </List>
                        </Box>
                      )
                    }
                  >
                    <Button
                      disableRipple={page.children.length !== 0}
                      disabled={page.disabled}
                      onClick={() => {
                        if (page.children.length === 0) {
                          if (page.href) {
                            window.open(page.href);
                          } else {
                            navigateToPath(page.path);
                          }
                        }
                      }}
                      sx={{
                        my: 1,
                        display: "flex",
                        fontWeight: page.path === pathname ? "bolder" : "400",
                        textShadow: page.disabled ? "none" : "4px 4px 20px #F1C024",
                        borderBottom: page.children.length === 0 && page.path === pathname ? "1px solid darkgray" : "1px solid rgb(26, 25, 46)",
                        borderRadius: "0px",
                        color: page.path === pathname ? "white" : "#F1C024",
                        "&:hover": {
                          borderBottom: page.children.length === 0 ? "1px solid white" : "1px solid rgb(26, 25, 46)",
                        },
                        "&:disabled": {
                          color: "rgba(241, 192, 36, 0.3)",
                        },
                      }}
                    >
                      {page.emoji}
                      {"  "}
                      {page.title}
                      {page.children.length !== 0 && <ExpandMoreIcon />}
                    </Button>
                  </CustomTooltip>
                ))}
            </Box>

            <Box sx={{ flexGrow: 0, display: "flex" }}>
              <Box sx={{ paddingRight: "10px" }}>
                {!isAuth && (
                  <Button
                    variant="contained"
                    sx={{
                      // paddingRight: "30px",
                      backgroundColor: "rgb(26, 25, 46)",
                      color: "white",
                      fontWeight: "bolder",
                      borderRadius: "20px",
                      "&:hover": {
                        backgroundColor: "#F1C024",
                        color: "rgb(26, 25, 46)",
                      },
                    }}
                    onClick={() => {
                      navigate("/register");
                    }}
                  >
                    註冊
                    <HowToRegIcon fontSize="small" sx={{ ml: 1 }} />
                  </Button>
                )}
              </Box>

              <Box>
                {!isAuth && (
                  <Button
                    variant="contained"
                    sx={{
                      backgroundColor: "rgb(26, 25, 46)",
                      color: "white",
                      fontWeight: "bolder",
                      borderRadius: "20px",
                      "&:hover": {
                        backgroundColor: "#F1C024",
                        color: "rgb(26, 25, 46)",
                      },
                    }}
                    onClick={() => {
                      navigate("/login");
                    }}
                  >
                    登入
                    <LoginIcon fontSize="small" sx={{ ml: 1 }} />
                  </Button>
                )}
                {isAuth && (
                  <>
                    <Tooltip title="">
                      <IconButton
                        onClick={handleOpenUserMenu}
                        sx={{
                          p: 0,
                        }}
                      >
                        <Avatar alt="Remy Sharp" sx={{ bgcolor: yellow[700] }} />
                      </IconButton>
                    </Tooltip>
                    <Menu
                      sx={{
                        mt: "45px",
                        "& .MuiMenu-paper": { backgroundColor: "rgba(26, 25, 50, 1)", color: "white", border: "2px solid #ffffff" },
                      }}
                      id="menu-appbar"
                      anchorEl={anchorElUser}
                      anchorOrigin={{
                        vertical: "top",
                        horizontal: "right",
                      }}
                      keepMounted
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "right",
                      }}
                      open={Boolean(anchorElUser)}
                      onClose={handleCloseUserMenu}
                    >
                      {roleId === RoleId.MEMBER &&
                        settings.map((setting, index) => (
                          <MenuItem
                            key={index}
                            onClick={() => {
                              setting.title === "登出" ? onLogout() : navigateToPath(setting.path);
                              handleCloseUserMenu();
                            }}
                          >
                            <Typography textAlign="center" display="flex" alignItems={"center"}>
                              {setting.icon}
                              {setting.title}
                            </Typography>
                          </MenuItem>
                        ))}
                      {roleId === RoleId.ADMIN &&
                        adminSettings.map((setting, index) => (
                          <MenuItem
                            key={index}
                            onClick={() => {
                              setting.title === "登出" ? onLogout() : navigateToPath(setting.path);
                              handleCloseUserMenu();
                            }}
                          >
                            <Typography textAlign="center" display="flex" alignItems={"center"}>
                              {setting.icon}
                              {setting.title}
                            </Typography>
                          </MenuItem>
                        ))}
                    </Menu>
                  </>
                )}
              </Box>
            </Box>
          </Toolbar>
        )}
      </AppBar>
    </ThemeProvider>
  );
}
export default NavBar;
