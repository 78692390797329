import { Container } from "@mui/material";
import React from "react";
import PriceCard from "../components/Home/PriceCard";
import { WhiteSectionTitle } from "../utils/customStyle";

function PricePlan() {
  return (
    <Container maxWidth="lg" component="main">
      <WhiteSectionTitle>Topaz Channel 收費模式</WhiteSectionTitle>
      <PriceCard />
    </Container>
  );
}

export default PricePlan;
