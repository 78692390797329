import { CardMedia, Container, Grid, Link } from "@mui/material";
import React from "react";
import SectionTitle from "../styles/sectionTitle";
import CustomDescription from "../styles/customDescription";
import SubHeader from "../styles/subHeader";
import CustomAccordion from "../styles/CustomAccordion";
import CustomAccordionDetails from "../styles/CustomAccordionDetails";
import AccordionHeader from "../styles/CustomAccordionHeader";
import CustomAccordionSummary from "../styles/CustomAccordionSummary";
import CustomBox from "../styles/CustomBox";

function CfdTutorial() {
  const [expanded, setExpanded] = React.useState<string | false>(false);

  const handleChange = (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
    setExpanded(newExpanded ? panel : false);
  };

  return (
    <Container maxWidth="lg" component="main">
      <SectionTitle>CFD開戶及操作</SectionTitle>
      <Container maxWidth="lg" component="main" sx={{ paddingTop: "50px", paddingBottom: "70px" }}>
        <Grid container spacing={3} alignItems="center" justifyContent="center" textAlign="center" color="white">
          <Grid xs={12} md={6} lg={6}>
            <SubHeader>Vantage x SMH</SubHeader>
            <CustomDescription>開戶優惠領取方法+操作教學</CustomDescription>
          </Grid>
          <Grid xs={12} md={6} lg={6}>
            <CardMedia
              sx={{ height: "360px", borderWidth: 0, boxShadow: "0px 0px 43px 15px rgba(255, 255, 255, 0.5)" }}
              component="iframe"
              src="https://www.youtube.com/embed/I10wbtrMz6k"
              allowFullScreen
            ></CardMedia>
          </Grid>
        </Grid>
      </Container>

      <Container maxWidth="lg" component="main" sx={{ paddingTop: "50px", paddingBottom: "70px" }}>
        <Grid container spacing={3} alignItems="center" justifyContent="center" textAlign="center" color="white">
          <Grid xs={12} md={6} lg={6}>
            <CardMedia
              sx={{ height: "360px", borderWidth: 0, boxShadow: "0px 0px 43px 15px rgba(255, 255, 255, 0.5)" }}
              component="iframe"
              src="https://www.youtube.com/embed/BDlP6uLYnug"
              allowFullScreen
            ></CardMedia>
          </Grid>
          <Grid xs={12} md={6} lg={6}>
            <SubHeader>使用MT4交易CFD教學</SubHeader>
          </Grid>
        </Grid>
      </Container>

      <CustomAccordion expanded={expanded === "panel1"} onChange={handleChange("panel1")}>
        <CustomAccordionSummary aria-controls="panel1d-content" id="panel1d-header">
          <AccordionHeader>CFD簡單介紹</AccordionHeader>
        </CustomAccordionSummary>
        <CustomAccordionDetails>
          <CustomBox>
            <CustomDescription>Vantage .r 同 FT.r 分別</CustomDescription>
          </CustomBox>
          <CustomBox>
            <CustomDescription>FT.r swing trade用</CustomDescription>
            <CustomDescription>無存倉費但點差大啲 會自動轉合約，今個月到期自動轉下個月，然後每月轉一次收一次點差</CustomDescription>
          </CustomBox>

          <CustomBox>
            <CustomDescription>短炒用.r著數d </CustomDescription>
            <CustomDescription>點差細啲 但收過夜費</CustomDescription>
          </CustomBox>

          <CustomBox>
            <CustomDescription>常用Ticker</CustomDescription>
            <CustomDescription>DJ30=道指</CustomDescription>
            <CustomDescription>NAS100=納指100</CustomDescription>
            <CustomDescription>SP500=標普</CustomDescription>
            <CustomDescription>US2000=羅素2000</CustomDescription>
            <CustomDescription>HK50=恒指</CustomDescription>
            <CustomDescription>USDX=美匯指數</CustomDescription>
            <CustomDescription>XAU=金 XAG=銀 CL=油</CustomDescription>
          </CustomBox>

          <CustomBox>
            <CustomDescription>開倉時留意預付比</CustomDescription>
            <CustomDescription>新手最好在開倉後保留至少 500+%</CustomDescription>
            <CustomDescription>熟習後可放寬200-300%</CustomDescription>
            <CustomDescription>用作賭Event可以100-150%</CustomDescription>
            <CustomDescription>指標派每個勾落注大細建議：</CustomDescription>
          </CustomBox>

          <CustomBox>
            <CustomDescription>新手：3-5 lot</CustomDescription>
            <CustomDescription>進階：10-20 lot</CustomDescription>
            <CustomDescription>鬼級：50 lot</CustomDescription>
            <CustomDescription>大大：100+ lot</CustomDescription>
          </CustomBox>
        </CustomAccordionDetails>
      </CustomAccordion>

      <CustomAccordion expanded={expanded === "panel2"} onChange={handleChange("panel2")}>
        <CustomAccordionSummary aria-controls="panel2d-content" id="panel2d-header">
          <AccordionHeader>Vanatage開戶</AccordionHeader>
        </CustomAccordionSummary>
        <CustomAccordionDetails>
          <CustomBox>
            <CustomDescription>只要你經我哋條Link開戶：</CustomDescription>
            <CustomDescription>
              <Link href="https://vigco.co/EBfAJj">https://vigco.co/EBfAJj</Link>
            </CustomDescription>
            <CustomDescription></CustomDescription>
            <CustomDescription>⭐️推薦人寫：under 106399⭐️</CustomDescription>
            <CustomDescription></CustomDescription>
            <CustomDescription>1️⃣首次入金送你50% Credit Bonus (上限USD 500)</CustomDescription>
            <CustomDescription>即係你入USD 1000 戶口就會有USD 1500去Trade</CustomDescription>
            <CustomDescription></CustomDescription>
            <CustomDescription>2️⃣其後每次入金用就送20% Credit Bonus 即係每次入錢都會有額外獎賞</CustomDescription>
          </CustomBox>
        </CustomAccordionDetails>
      </CustomAccordion>

      <CustomAccordion expanded={expanded === "panel3"} onChange={handleChange("panel3")}>
        <CustomAccordionSummary aria-controls="panel3d-content" id="panel3d-header">
          <AccordionHeader>TMGM開戶</AccordionHeader>
        </CustomAccordionSummary>
        <CustomAccordionDetails>
          <CustomBox>
            <CustomDescription>
              <Link href="https://portal.tmgm.com/register?node=MTExMjY0&language=zh-Hans">
                https://portal.tmgm.com/register?node=MTExMjY0&language=zh-Hans
              </Link>
            </CustomDescription>
          </CustomBox>
        </CustomAccordionDetails>
      </CustomAccordion>

      <CustomAccordion expanded={expanded === "panel4"} onChange={handleChange("panel4")}>
        <CustomAccordionSummary aria-controls="panel4d-content" id="panel4d-header">
          <AccordionHeader>Ultima Markets開戶</AccordionHeader>
        </CustomAccordionSummary>
        <CustomAccordionDetails>
          <CustomBox>
            <CustomDescription>✅首次入金送50% Credit Bonus </CustomDescription>
            <CustomDescription>(上限入1000usd 送500usd )</CustomDescription>
            <CustomDescription>✅其後多左嘅入金/之後入金就送20% bonus 無上限</CustomDescription>
          </CustomBox>

          <CustomBox>
            <CustomDescription>SMH開戶Link：</CustomDescription>
            <CustomDescription>
              <Link href="https://ultgo.com/fQ77nq">https://ultgo.com/fQ77nq</Link>
            </CustomDescription>
          </CustomBox>

          <CustomBox>
            <CustomDescription>開戶選擇 MetaTrader 4 and Standard type account</CustomDescription>
            <CustomDescription>Referral codes可填：71559</CustomDescription>
          </CustomBox>

          <CustomBox>
            <CustomDescription>WikiFX 評分：</CustomDescription>
            <CustomDescription>
              <Link href="https://www.fxeye555.com/zh-cn/dealer/4052654322.html">https://www.fxeye555.com/zh-cn/dealer/4052654322.html</Link>
            </CustomDescription>
          </CustomBox>

          <CustomBox>
            <CustomDescription>開戶後網頁版登入：</CustomDescription>
            <CustomDescription>
              <Link href="https://myaccount.ultimamarkets.com/login">https://myaccount.ultimamarkets.com/login</Link>
            </CustomDescription>
          </CustomBox>
        </CustomAccordionDetails>
      </CustomAccordion>
    </Container>
  );
}

export default CfdTutorial;
