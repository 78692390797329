import React from "react";
import Grid from "@mui/material/Unstable_Grid2";
import { Container } from "@mui/material";
import CustomDescription from "../styles/customDescription";
import SectionTitle from "../styles/sectionTitle";
import SubHeader from "../styles/subHeader";
import chartIntro1 from "../images/chartIntro/chartIntro1.png";
import chartIntro2 from "../images/chartIntro/chartIntro2.png";

function ChartAnalysisIntro() {
  return (
    <>
      <Container maxWidth="lg" component="main">
        <SectionTitle>圖表分析</SectionTitle>
        <Container maxWidth="lg" component="main" sx={{ py: "50px" }}>
          <Grid container spacing={3} alignItems="center" justifyContent="center" textAlign="center" color="white">
            <Grid xs={12} md={6} lg={6}>
              <SubHeader>由零開始打好基本功</SubHeader>
              <CustomDescription>
                圖表分析可謂炒股一門必學的基本功，但坊間教學花多眼亂，新手難以入手。有見及此SMH
                Admin為新手度身訂做一套易學之圖表分析框架，配合影片教學方便隨時重溫。
              </CustomDescription>
            </Grid>
            <Grid xs={12} md={6} lg={6}>
              <Container>
                <img src={chartIntro1} alt="chartIntro1" style={{ width: "inherit" }} />
              </Container>
            </Grid>
          </Grid>
          <Grid container spacing={3} alignItems="center" justifyContent="center" textAlign="center" color="white">
            <Grid xs={12} md={6} lg={6}>
              <Container>
                <img src={chartIntro2} alt="chartIntro2" style={{ width: "inherit" }} />
              </Container>
            </Grid>
            <Grid xs={12} md={6} lg={6}>
              <SubHeader>信號x圖表分析勝率大增</SubHeader>
              <CustomDescription>
                透過SMH獨家AI系統分析聰明錢取態，再配合Admin每日開市前更新指數/個股圖表分析，兩強聯手發揮最強功效，助你勝率大增；更可以在Group內隨時發問圖表，一同研究共同成長，絕非「Number台」可比擬。
              </CustomDescription>
            </Grid>
          </Grid>
        </Container>
      </Container>
    </>
  );
}

export default ChartAnalysisIntro;
