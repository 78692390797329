/* eslint-disable indent */
import React from "react";
import { Card, CardHeader, CardContent, Box, Typography, CardActions, Button, styled, useMediaQuery, useTheme } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import StarIcon from "@mui/icons-material/StarBorder";
import CheckIcon from "@mui/icons-material/Check";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const hkTopics = [
  {
    title: "港股Topaz 主Channel(單向) 🇭🇰",
    descriptions: [
      "每日update 王牌AI系統Excel表",
      "內含各大指數及13個板塊50幾隻龍頭大股信號 幾時要升幾時會跌 升到咩位跌到咩位 一眼睇哂",
      "每日大市分析",
      "Admins交易實時分享",
      "拆解重點經濟數據",
      "每日分析恒指牛熊證分佈數據",
      "每日篩選牛熊號碼（篩選市場上相對條件公平嘅牛熊證 避開伏number）",
    ],
  },
  {
    title: "港股Trader’s Chatroom (互動💬) 🇭🇰",
    descriptions: [
      "為同路人而設嘅交易社群討論區",
      "7位admins + 過百位會員每日瘋狂交流",
      "高手雲集 互相學習中成長 搵到屬於自己嘅交易節奏及系統",
      "一同分享投資心得/分析技巧/即市部署",
      "一同進行賽後檢討/心理輔導 互相提醒及支持 交易路上不再孤單",
    ],
  },
  {
    title: "港股AI信號問股專區(互動💬) 🇭🇰",
    descriptions: ["過千隻港股任你問 ", "幫你逐隻入皇派AI系統檢閱", "直接解剖聰明錢資金動向 ", "搵到最適當嘅入場位/食糊位 "],
  },
  {
    title: "港股指標派期指石仔Bot(單向📲) 🇭🇰",
    descriptions: ["星期一至五期指交易時段內", "自動推送恒指期貨指標短炒提示"],
  },
];

const usTopics = [
  {
    title: "美股主Channel(單向📲) 🇺🇸",
    descriptions: [
      "每日update 王牌AI系統Excel表",
      "內含各大指數及18個板塊過百隻龍頭大股信號 幾時要升幾時會跌 升到咩位跌到咩位 一眼睇哂",
      "大市市況分析",
      "圖表分析每日焦點",
      "Admins交易實時分享",
      "拆解重點經濟數據",
      "AI系統捕捉潛力爆升股",
    ],
  },
  {
    title: "美股Trader’s Chatroom (互動💬) 🇺🇸",
    descriptions: [
      "為同路人而設嘅交易社群討論區",
      "7位admins + 過百位會員每日瘋狂交流",
      "高手雲集 互相學習中成長 搵到屬於自己嘅交易節奏及系統",
      "一同分享投資心得/分析技巧/即市部署",
      "一同進行賽後檢討/心理輔導 互相提醒及支持 交易路上不再孤單",
    ],
  },
  {
    title: "美股AI信號問股專區(互動💬) 🇺🇸",
    descriptions: ["過萬隻美股任你問 ", "幫你逐隻入皇派AI系統檢閱", "直接解剖聰明錢資金動向 ", "搵到最適當嘅入場位/食糊位 "],
  },
  {
    title: "美股期權工作坊(互動💬) 🇺🇸",
    descriptions: ["由零開始期權全教學", "前投行期權Trader教學 有問必答", "即市短炒/Daytrade 指標教學", "Tradingview畫圖教學"],
  },
  {
    title: "美股期權異動Bot Channel(單向📲) 🇺🇸",
    descriptions: [
      "自家製Bot",
      "交易時段內即時推送Out of the Money掃貨大單",
      "零時差捕捉大戶部署",
      "所有異動大單一目了然",
      "即市追動資金動向",
      "篩選教學",
      "精準搵出數倍升幅大單",
      "合埋眼跟住大戶晚晚翻倍",
    ],
  },
  {
    title: "美股指標派期指石仔Bot(單向📲) 🇺🇸",
    descriptions: ["星期一至五期指交易時段內", "自動推送三指期貨指標短炒提示"],
  },
  {
    title: "休閑馬會台(互動💬) 🇺🇸",
    descriptions: ["AI賭波信號", "賭仔聚腳點", "遇上悶市/休市時齊齊贏錢嘅地方"],
  },
  {
    title: "除此之外 🇺🇸",
    descriptions: [
      "仲有大量投資理財共享資源",
      "除咗SMH自家教學影片外",
      "仲有數十本e-book(各類熱門技術分析教學書)",
      "無論你係新手定係老手 ",
      "學海無涯 必定會有所得著",
    ],
  },
];

const hkAndUsTopics = hkTopics.concat(usTopics);
function PriceCard() {
  const tiers = [
    {
      title: "✨Topaz收費頻道✨",
      subheader: "港股🇭🇰",
      price: "550",
      secondPrice: "440",
      // description: ["港股Topaz 主Channel(單向) 🇭🇰", "港股Topaz Family Chatroom 🇭🇰", "港股問股專區 🇭🇰", "港股Topaz 期指石仔Bot (指標派) 🇭🇰", "基本面工作坊"],
      topics: hkTopics,
      buttonText: "加入",
      buttonVariant: "contained",
    },
    {
      title: "✨Topaz收費頻道✨",
      subheader: "美股🇺🇸",
      price: "850",
      secondPrice: "680",
      // description: [
      //   "美股Topaz 主Channel(單向) 🇺🇸",
      //   "美股Topaz Family Chatroom 🇺🇸",
      //   "美股問股專區 🇺🇸",
      //   "美股期權異動Bot Channel 🇺🇸",
      //   "美股Topaz 期指石仔Bot (指標派) 🇺🇸",
      //   "SMH Crypto專區 🔥",
      //   "SMH馬會區 🔥",
      //   "期權工作坊/操作分享台",
      // ],
      topics: usTopics,
      buttonText: "加入",
      buttonVariant: "contained",
    },
    {
      title: "✨Topaz收費頻道✨",
      subheader: "美股🇺🇸 + 港股🇭🇰",
      price: "1199",
      secondPrice: "1019",
      // description: [
      //   "美股Topaz 主Channel(單向) 🇺🇸",
      //   "美股Topaz Family Chatroom 🇺🇸",
      //   "美股問股專區 🇺🇸",
      //   "美股期權異動Bot Channel 🇺🇸",
      //   "美股Topaz 期指石仔Bot (指標派) 🇺🇸",
      //   "港股Topaz 主Channel(單向) 🇭🇰",
      //   "港股Topaz Family Chatroom 🇭🇰",
      //   "港股問股專區 🇭🇰",
      //   "港股Topaz 期指石仔Bot (指標派) 🇭🇰",
      //   "SMH Crypto專區 🔥",
      //   "SMH馬會區 🔥",
      //   "期權工作坊/操作分享台",
      //   "基本面工作坊",
      // ],
      topics: hkAndUsTopics,
      buttonText: "加入",
      buttonVariant: "contained",
    },
  ];

  const tiers2 = [
    {
      title: "✨Topaz收費頻道✨",
      subheader: "港股🇭🇰",
      price: "550",
      secondPrice: "440",
      topics: hkTopics,
      buttonText: "加入",
      buttonVariant: "contained",
    },
    {
      title: "✨Topaz收費頻道✨",
      subheader: "美股🇺🇸",
      price: "850",
      secondPrice: "680",
      topics: usTopics,
      buttonText: "加入",
      buttonVariant: "contained",
    },
    {
      title: "✨Topaz收費頻道✨",
      subheader: "美股🇺🇸 + 港股🇭🇰",
      price: "1199",
      secondPrice: "1019",
      topics: hkAndUsTopics,
      buttonText: "加入",
      buttonVariant: "contained",
    },
  ];

  const CustomButton = styled(Button)({
    backgroundColor: "rgb(25, 33, 49)",
    color: "rgb(255,255,255)",
    fontWeight: "700",
    fontSize: "0.75rem",
    borderRadius: "5px",
    "&:hover": {
      backgroundColor: "rgb(25, 33, 49)",
      color: "rgb(255,255,255)",
      boxShadow: "1px 1px 5px 0px rgba(255,255,255,0.2)",
    },
  }) as typeof Button;

  const theme = useTheme();
  const isBelowMd = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <Grid container spacing={3} alignItems="stretch">
      {!isBelowMd
        ? tiers.map((tier, index) => (
            // Enterprise card is full width at sm breakpoint
            <Grid key={index} xs={12} sm={tier.subheader === "美股🇺🇸 + 港股🇭🇰" ? 12 : 6} md={4} style={{ display: "flex" }}>
              <Card
                sx={{
                  backgroundColor: "initial",
                  border: "1px solid grey",
                  boxShadow: "rgba(0, 0, 0, 0.1) 0rem 0.25rem 0.375rem -0.0625rem, rgba(0, 0, 0, 0.06) 0rem 0.125rem 0.25rem -0.0625rem",
                  borderRadius: "10px",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                  width: "100%",
                }}
              >
                <Box>
                  <CardHeader
                    sx={{ px: "28px", paddingBottom: "8px" }}
                    title={tier.title}
                    subheader={tier.subheader}
                    titleTypographyProps={{
                      align: "left",
                      margin: "0px 0px 4px",
                      fontSize: "24px",
                      textTransform: "none",
                      color: "#F1C024",
                      fontWeight: "700",
                    }}
                    action={tier.title === "Topaz HK + US" ? <StarIcon /> : null}
                    subheaderTypographyProps={{
                      color: "grey",
                      fontSize: "24px",
                      align: "left",
                    }}
                    //   sx={{
                    //     backgroundColor: (theme) => (theme.palette.mode === "light" ? theme.palette.grey[200] : theme.palette.grey[700]),
                    //   }}
                  />

                  <CardContent sx={{ px: "26px", py: "10px !important", position: "relative" }}>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "flex-start",
                        alignItems: "baseline",
                        mb: 1,
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize: "44px",
                          fontWeight: 700,
                          paddingRight: "5px",
                          color: "white",
                          // fontFamily: "\"Roboto Slab\", sans-serif",
                        }}
                      >
                        ${tier.price}
                      </Typography>
                      <Typography
                        sx={{
                          color: "white",
                          fontSize: "1rem",
                          fontWeight: 700,
                          // fontFamily: "\"Roboto Slab\", sans-serif",
                        }}
                      >
                        /月
                      </Typography>
                    </Box>

                    <Box>
                      <Box display="flex" alignItems="center">
                        <Typography fontSize="0.875rem" color="white" align="left">
                          其後續會優惠價: ${tier.secondPrice}/月
                        </Typography>
                      </Box>

                      <Box sx={{ mb: 2 }}>
                        <Typography fontSize="0.7rem" color="grey" align="left">
                          *每月16號設有半月Plan 價格為正價之一半
                        </Typography>
                        <Typography fontSize="0.7rem" color="grey" align="left">
                          名額有限 請先向admin查詢
                        </Typography>
                        <Typography fontSize="0.7rem" color="grey" align="left">
                          *所有會籍均以整月計算 即每月最後一日到期
                        </Typography>
                        <Typography fontSize="0.7rem" color="grey" align="left">
                          (即使中途加入 會籍亦同樣於當月最後一日結束)
                        </Typography>
                      </Box>

                      <Box sx={{ maxHeight: 500, overflow: "auto" }}>
                        {tier.topics.map((topic, index) => (
                          <Box key={index} sx={{ mb: 2 }}>
                            <Typography p={0.75} display="flex" color="#F1C024">
                              {topic.title}
                            </Typography>
                            {topic.descriptions.map((description, index) => (
                              <Box display="flex" alignItems="center" key={index}>
                                <Typography p={0.75} display="flex" color="#F1C024">
                                  <CheckIcon fontSize="small" />
                                </Typography>
                                <Typography fontSize="0.875rem" color="white" align="left" paddingLeft="10px" paddingRight="10px">
                                  {description}
                                </Typography>
                              </Box>
                            ))}
                          </Box>
                        ))}
                      </Box>
                    </Box>

                    <Box
                      sx={{
                        position: "absolute",
                        bottom: 0,
                        left: 0,
                        height: "100px",
                        width: "-webkit-fill-available",
                        background: "linear-gradient(to bottom, rgb(255, 255, 255, 0), rgb(26, 25, 46, 1))",
                      }}
                    ></Box>
                  </CardContent>
                </Box>

                <Box sx={{ px: "24px" }}>
                  <Typography
                    fontSize="0.9rem"
                    color="white"
                    align="left"
                    textAlign="center"
                    fontWeight="700"
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                  >
                    向下滑動
                    <ExpandMoreIcon fontSize="medium" />
                  </Typography>
                </Box>

                <Box sx={{ px: "24px", mt: 3 }}>
                  <Typography fontSize="0.9rem" color="white" align="left" textAlign="center" fontWeight="700">
                    👇聯絡Admin Nicole妹妹報名👇
                  </Typography>
                </Box>

                <Box>
                  <CardActions sx={{ px: "24px", paddingBottom: "24px" }}>
                    <CustomButton fullWidth variant={tier.buttonVariant as "outlined" | "contained"} href="https://t.me/SMH_NCO" target="_blank">
                      {tier.buttonText}
                    </CustomButton>
                  </CardActions>
                </Box>
              </Card>
            </Grid>
          ))
        : tiers2.map((tier, index) => (
            // Enterprise card is full width at sm breakpoint
            <Grid key={index} xs={12} sm={tier.subheader === "美股🇺🇸 + 港股🇭🇰" ? 12 : 6} md={4} style={{ display: "flex" }}>
              <Card
                sx={{
                  backgroundColor: "initial",
                  border: "1px solid grey",
                  boxShadow: "rgba(0, 0, 0, 0.1) 0rem 0.25rem 0.375rem -0.0625rem, rgba(0, 0, 0, 0.06) 0rem 0.125rem 0.25rem -0.0625rem",
                  borderRadius: "10px",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                  width: "100%",
                }}
              >
                <Box>
                  <CardHeader
                    sx={{ px: "24px", paddingBottom: "8px" }}
                    title={tier.title}
                    subheader={tier.subheader}
                    titleTypographyProps={{
                      align: "left",
                      margin: "0px 0px 4px",
                      fontSize: "24px",
                      textTransform: "none",
                      color: "#F1C024",
                      fontWeight: "700",
                    }}
                    action={tier.title === "Topaz HK + US" ? <StarIcon /> : null}
                    subheaderTypographyProps={{
                      color: "grey",
                      fontSize: "18px",
                      align: "left",
                    }}
                    //   sx={{
                    //     backgroundColor: (theme) => (theme.palette.mode === "light" ? theme.palette.grey[200] : theme.palette.grey[700]),
                    //   }}
                  />

                  <CardContent sx={{ px: "24px", paddingTop: "0px", position: "relative" }}>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "flex-start",
                        alignItems: "baseline",
                        mb: 1,
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize: "44px",
                          fontWeight: 700,
                          paddingRight: "5px",
                          color: "white",
                          // fontFamily: "\"Roboto Slab\", sans-serif",
                        }}
                      >
                        ${tier.price}
                      </Typography>
                      <Typography
                        sx={{
                          color: "white",
                          fontSize: "1rem",
                          fontWeight: 700,
                          // fontFamily: "\"Roboto Slab\", sans-serif",
                        }}
                      >
                        /月
                      </Typography>
                    </Box>

                    <Box>
                      <Box display="flex" alignItems="center">
                        <Typography fontSize="0.875rem" color="white" align="left">
                          其後續會優惠價: ${tier.secondPrice}/月
                        </Typography>
                      </Box>

                      <Box sx={{ mb: 2 }}>
                        <Typography fontSize="0.7rem" color="grey" align="left">
                          *每月16號設有半月Plan 價格為正價之一半
                        </Typography>
                        <Typography fontSize="0.7rem" color="grey" align="left">
                          名額有限 請先向admin查詢
                        </Typography>
                        <Typography fontSize="0.7rem" color="grey" align="left">
                          *所有會籍均以整月計算 即每月最後一日到期
                        </Typography>
                        <Typography fontSize="0.7rem" color="grey" align="left">
                          (即使中途加入 會籍亦同樣於當月最後一日結束)
                        </Typography>
                      </Box>

                      <Box sx={{ maxHeight: 300, overflow: "auto" }}>
                        {tier.topics.map((topic, index) => (
                          <Box key={index} sx={{ mb: 2 }}>
                            <Typography p={0.75} display="flex" color="#F1C024">
                              {topic.title}
                            </Typography>
                            {topic.descriptions.map((description, index) => (
                              <Box display="flex" alignItems="center" key={index}>
                                <Typography p={0.75} display="flex" color="#F1C024">
                                  <CheckIcon fontSize="small" />
                                </Typography>
                                <Typography fontSize="0.875rem" color="white" align="left" paddingLeft="10px" paddingRight="10px">
                                  {description}
                                </Typography>
                              </Box>
                            ))}
                          </Box>
                        ))}
                      </Box>
                    </Box>

                    <Box
                      sx={{
                        position: "absolute",
                        bottom: 0,
                        left: 0,
                        height: "100px",
                        width: "-webkit-fill-available",
                        background: "linear-gradient(to bottom, rgb(255, 255, 255, 0), rgb(26, 25, 46, 1))",
                      }}
                    ></Box>
                  </CardContent>
                </Box>

                <Box sx={{ px: "24px" }}>
                  <Typography
                    fontSize="0.9rem"
                    color="white"
                    align="left"
                    textAlign="center"
                    fontWeight="700"
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                  >
                    向下滑動
                    <ExpandMoreIcon fontSize="medium" />
                  </Typography>
                </Box>

                <Box sx={{ px: "24px", mt: 2 }}>
                  <Typography fontSize="0.9rem" color="white" align="left" textAlign="center" fontWeight="700">
                    👇聯絡Admin Nicole妹妹報名👇
                  </Typography>
                </Box>

                <Box>
                  <CardActions sx={{ px: "24px", paddingBottom: "24px" }}>
                    <CustomButton fullWidth variant={tier.buttonVariant as "outlined" | "contained"} href="https://t.me/SMH_NCO" target="_blank">
                      {tier.buttonText}
                    </CustomButton>
                  </CardActions>
                </Box>
              </Card>
            </Grid>
          ))}
    </Grid>
  );
}

export default PriceCard;
