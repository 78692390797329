import { Container, SvgIcon, useMediaQuery, useTheme, Typography, Box } from "@mui/material";
import React from "react";
import Grid from "@mui/material/Unstable_Grid2";
import { useAppDispatch, useAppSelector } from "../stores/hook";
import { RootState } from "../stores/store";
import { fetchDeauthorizeDiscord } from "../stores/auth/thunk";
import LinkOffIcon from "@mui/icons-material/LinkOff";
import moment from "moment";
import CustomButtonYellowBg from "../styles/CustomButtonYellowBg";

function MyAccount() {
  const theme = useTheme();
  const isAboveMd = useMediaQuery(theme.breakpoints.up("md"));

  const hasDiscordUserId = useAppSelector((state: RootState) => state.auth.discordUserId);
  const discordUsername = useAppSelector((state: RootState) => state.auth.discordUsername);
  const userId = useAppSelector((state: RootState) => state.auth.userId);
  const subscriptionId = useAppSelector((state: RootState) => state.auth.subscriptionId);
  const subscriptionEnds = useAppSelector((state: RootState) => state.auth.subscriptionEnds);

  const dispatch = useAppDispatch();
  const onSumbit = () => {
    dispatch(fetchDeauthorizeDiscord());
  };

  // const telegramWrapperRef = useRef<HTMLDivElement>(null);
  // useEffect(() => {
  //   const scriptElement = document.createElement("script");
  //   scriptElement.src = "https://telegram.org/js/telegram-widget.js?22";
  //   scriptElement.setAttribute("data-telegram-login", "smh_ouath_bot");
  //   scriptElement.setAttribute("data-size", "small");
  //   scriptElement.setAttribute("data-radius", "10");
  //   scriptElement.setAttribute("data-auth-url", "https://api.staging.smartmoneyhouse-hk.com/v1/user/login/telegram");
  //   scriptElement.setAttribute("data-request-access", "write");
  //   scriptElement.async = true;

  //   telegramWrapperRef.current?.appendChild(scriptElement);
  // }, []);

  return (
    <Container maxWidth="lg" component="main">
      <Grid container spacing={3} justifyContent="space-between" alignItems="center">
        {isAboveMd && <Grid md={3} display="flex"></Grid>}
        <Grid xs={12} md={6} display="flex">
          <Typography sx={{ color: "white", textAlign: "left", fontSize: "30px", fontWeight: "700", paddingTop: "20px", paddingBottom: "0px" }}>
            我的會籍
          </Typography>
        </Grid>
        {isAboveMd && <Grid md={3} display="flex"></Grid>}

        {isAboveMd && <Grid md={3} display="flex"></Grid>}
        <Grid xs={12} sm={6} color="white">
          <Box display="flex">
            <Typography sx={{ mr: 1, fontWeight: 600 }}>會員號碼:</Typography> <Typography>{userId}</Typography>
          </Box>
        </Grid>
        {isAboveMd && <Grid md={3} display="flex"></Grid>}

        {isAboveMd && <Grid md={3} display="flex"></Grid>}
        <Grid xs={12} sm={6} color="white">
          <Box display="flex">
            <Typography sx={{ mr: 1, fontWeight: 600 }}>會藉狀態:</Typography>
            <Typography>{new Date() < new Date(subscriptionEnds) ? "生效中" : "過期"}</Typography>
          </Box>
        </Grid>
        {isAboveMd && <Grid md={3} display="flex"></Grid>}

        {isAboveMd && <Grid md={3} display="flex"></Grid>}
        <Grid xs={12} sm={6} color="white">
          <Box display="flex">
            <Typography sx={{ mr: 1, fontWeight: 600 }}>會藉類型:</Typography>
            <Typography>{subscriptionId === 1 ? "美股🇺🇸 + 港股🇭🇰" : subscriptionId === 2 ? "港股🇭🇰" : subscriptionId === 3 ? "美股🇺🇸" : "N/A"}</Typography>
          </Box>
        </Grid>
        {isAboveMd && <Grid md={3} display="flex"></Grid>}

        {isAboveMd && <Grid md={3} display="flex"></Grid>}
        <Grid xs={12} sm={6} color="white">
          <Box display="flex">
            <Typography sx={{ mr: 1, fontWeight: 600 }}>到期日:</Typography>
            <Typography>{moment(subscriptionEnds).format("YYYY-MM-DD")}</Typography>
          </Box>
        </Grid>
        {isAboveMd && <Grid md={3} display="flex"></Grid>}

        {isAboveMd && <Grid md={3} display="flex"></Grid>}
        <Grid xs={12} md={6} display="flex">
          <Typography sx={{ color: "white", textAlign: "left", fontSize: "30px", fontWeight: "700", paddingTop: "20px", paddingBottom: "0px" }}>
            Discord帳戶連接
          </Typography>
        </Grid>
        {isAboveMd && <Grid md={3} display="flex"></Grid>}

        {isAboveMd && <Grid md={3} display="flex"></Grid>}
        <Grid xs={7} sm={4} color="white">
          <Box display="flex" alignItems="center">
            <SvgIcon sx={{ fontSize: 40 }}>
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 127.14 96.36">
                <path
                  fill="#fff"
                  d="M107.7,8.07A105.15,105.15,0,0,0,81.47,0a72.06,72.06,0,0,0-3.36,6.83A97.68,97.68,0,0,0,49,6.83,72.37,72.37,0,0,0,45.64,0,105.89,105.89,0,0,0,19.39,8.09C2.79,32.65-1.71,56.6.54,80.21h0A105.73,105.73,0,0,0,32.71,96.36,77.7,77.7,0,0,0,39.6,85.25a68.42,68.42,0,0,1-10.85-5.18c.91-.66,1.8-1.34,2.66-2a75.57,75.57,0,0,0,64.32,0c.87.71,1.76,1.39,2.66,2a68.68,68.68,0,0,1-10.87,5.19,77,77,0,0,0,6.89,11.1A105.25,105.25,0,0,0,126.6,80.22h0C129.24,52.84,122.09,29.11,107.7,8.07ZM42.45,65.69C36.18,65.69,31,60,31,53s5-12.74,11.43-12.74S54,46,53.89,53,48.84,65.69,42.45,65.69Zm42.24,0C78.41,65.69,73.25,60,73.25,53s5-12.74,11.44-12.74S96.23,46,96.12,53,91.08,65.69,84.69,65.69Z"
                />
              </svg>
            </SvgIcon>
            <div style={{ marginLeft: "20px" }}>
              <Typography sx={{ fontSize: "18px", color: "white", fontWeight: "bold" }}>Discord</Typography>
              {hasDiscordUserId && (
                <Typography sx={{ fontSize: "14px", color: "white" }}>
                  已連結帳戶
                  {discordUsername}
                </Typography>
              )}
            </div>
          </Box>
        </Grid>
        <Grid xs={5} sm={2} display="flex" justifyContent="flex-end">
          {hasDiscordUserId ? (
            <CustomButtonYellowBg onClick={onSumbit} type={"submit"} color={"primary"} variant="contained">
              <LinkOffIcon sx={{ mr: 1 }} />
              移除
            </CustomButtonYellowBg>
          ) : (
            <CustomButtonYellowBg href={`${process.env.REACT_APP_API_SERVER}/connect/discord`} type={"submit"} color={"primary"} variant="contained">
              連結
            </CustomButtonYellowBg>
          )}
        </Grid>
        {isAboveMd && <Grid md={3} display="flex"></Grid>}
        {/* {isAboveMd && <Grid md={3} display="flex"></Grid>}
        <Grid xs={6} sm={4} display="flex">
          <SvgIcon sx={{ fontSize: 40 }}>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 127.14 96.36">
              <path
                fill="#fff"
                d="M107.7,8.07A105.15,105.15,0,0,0,81.47,0a72.06,72.06,0,0,0-3.36,6.83A97.68,97.68,0,0,0,49,6.83,72.37,72.37,0,0,0,45.64,0,105.89,105.89,0,0,0,19.39,8.09C2.79,32.65-1.71,56.6.54,80.21h0A105.73,105.73,0,0,0,32.71,96.36,77.7,77.7,0,0,0,39.6,85.25a68.42,68.42,0,0,1-10.85-5.18c.91-.66,1.8-1.34,2.66-2a75.57,75.57,0,0,0,64.32,0c.87.71,1.76,1.39,2.66,2a68.68,68.68,0,0,1-10.87,5.19,77,77,0,0,0,6.89,11.1A105.25,105.25,0,0,0,126.6,80.22h0C129.24,52.84,122.09,29.11,107.7,8.07ZM42.45,65.69C36.18,65.69,31,60,31,53s5-12.74,11.43-12.74S54,46,53.89,53,48.84,65.69,42.45,65.69Zm42.24,0C78.41,65.69,73.25,60,73.25,53s5-12.74,11.44-12.74S96.23,46,96.12,53,91.08,65.69,84.69,65.69Z"
              />
            </svg>
          </SvgIcon>
          <div style={{ marginLeft: "20px" }}>
            <Typography sx={{ fontSize: "18px", color: "white", fontWeight: "bold" }}>Telegram</Typography>
          </div>
        </Grid>
        <Grid xs={6} sm={2} display="flex" justifyContent="flex-end">
          <div ref={telegramWrapperRef}></div>
          <TelegramLoginButton botName="smh_ouath_bot" dataOnauth={(user: TelegramUser) => console.log(user)} />
        </Grid>
        {isAboveMd && <Grid md={3} display="flex"></Grid>} */}
        {/* <Grid xs={6} display="flex">
          <CustomDescription>Login With Google</CustomDescription>
        </Grid>
        <Grid xs={6} display="flex" justifyContent="flex-end">
          <Button
            href={`${process.env.REACT_APP_API_SERVER}/connect/discord`}
            type={"submit"}
            color={"primary"}
            variant="contained"
            // onClick={onLoginGoogle}
            sx={{
              backgroundColor: "#F1C024",
              color: "rgb(26, 25, 46)",
              fontWeight: "bolder",
              borderRadius: "10px",
              "&:hover": {
                backgroundColor: "rgb(26, 25, 46)",
                color: "white",
              },
            }}
          >
            連結
          </Button>
        </Grid> */}
      </Grid>
    </Container>
  );
}

export default MyAccount;
