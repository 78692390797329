import { CardMedia, Container, Typography } from "@mui/material";
import React from "react";
import Grid from "@mui/material/Unstable_Grid2";
import SectionTitle from "../styles/sectionTitle";
import CustomBox from "../styles/CustomBox";
import CustomAccordion from "../styles/CustomAccordion";
import CustomAccordionSummary from "../styles/CustomAccordionSummary";
import CustomAccordionDetails from "../styles/CustomAccordionDetails";
import CustomDescription from "../styles/customDescription";
import AccordionHeader from "../styles/CustomAccordionHeader";
import SubHeader from "../styles/subHeader";

function SignalTutorial() {
  const [expanded, setExpanded] = React.useState<string | false>(false);

  const handleChange = (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <>
      <Container maxWidth="lg" component="main">
        <SectionTitle>AI信號派教學</SectionTitle>
        <Container maxWidth="lg" component="main" sx={{ paddingTop: "50px", paddingBottom: "70px" }}>
          <Grid container spacing={3} alignItems="center" justifyContent="center" textAlign="center" color="white">
            <Grid xs={12} md={6} lg={6}>
              <SubHeader>AI分析信號</SubHeader>
              <CustomDescription>
                Smart Money House所提供的AI分析信號初接觸可能會覺得稍為複雜，但其實掌握當中原理及概念就能將其功能發揮到最大，令大家炒股如虎添翼
              </CustomDescription>
            </Grid>
            <Grid xs={12} md={6} lg={6}>
              <CardMedia
                sx={{ height: "360px", borderWidth: 0, boxShadow: "0px 0px 43px 15px rgba(255, 255, 255, 0.5)" }}
                component="iframe"
                src="https://www.youtube.com/embed/SIkfcVHvQwo"
                allowFullScreen
              ></CardMedia>
            </Grid>
          </Grid>
        </Container>

        <CustomAccordion expanded={expanded === "panel1"} onChange={handleChange("panel1")}>
          <CustomAccordionSummary aria-controls="panel1bh-content" id="panel1bh-header">
            <AccordionHeader>信號說明書</AccordionHeader>
          </CustomAccordionSummary>
          <CustomAccordionDetails>
            <CustomBox>
              <Typography>1.「買入價/B」</Typography>
              <Typography>大家通常見到信號時現價已高於買入價</Typography>
              <Typography>咁嘅情況係好正常嘅 唔洗擔心😂</Typography>
              <Typography>即使我自己都好少會買中佢買入價</Typography>
              <Typography>(偷步除外😂)</Typography>
              <Typography>因為佢觸發咗買入價去到我發現佢再share出嚟都會經歷一段小小嘅時間</Typography>
              <Typography>好多時已經升咗少少 </Typography>
              <Typography>一般情況下 3%內我都會照去買入</Typography>
            </CustomBox>

            <CustomBox>
              <Typography>「止蝕價/C」</Typography>
              <Typography>依個止蝕價一般情況下係建議嚴守嘅</Typography>
              <Typography>但有時情況特別啲 例如：</Typography>
              <Typography> </Typography>
              <Typography>大市大跌 生物技術板塊又跌 佢跟住跌 咁嘅話我會比多3-5%做止蝕buffer ：$17.83x0.96=$17.29</Typography>
              <Typography>大市大升 生物技術板塊又升 但佢跌 咁我可能會唔等止蝕 早過止蝕位離場</Typography>
            </CustomBox>

            <CustomBox>
              <Typography>3.「目標價/1T/2T/3T」</Typography>
              <Typography>依個位就好睇大家個人嘅胃口🤣</Typography>
              <Typography>我個人習慣每到1個T都會減啲</Typography>
              <Typography>雖然會贏少咗 但萬一跌翻落嚟 </Typography>
              <Typography>持貨成本已經低咗 冇咁易輸錢</Typography>
              <Typography>同埋過左1T 去緊2T嘅時候</Typography>
              <Typography>我會用返1T嚟做止賺 </Typography>
              <Typography>過左2T去緊3T都一樣 用返2T做止賺</Typography>
            </CustomBox>

            <CustomBox>
              <Typography>牛勢區域(又稱偷步區)</Typography>
              <Typography>一般個般嘅牛勢區域會因應每日嘅數據而刷新 一般頻率會係2-3日一次</Typography>
              <Typography>係依個區域入面 </Typography>
              <Typography>隻股票嘅好友係較強勢 </Typography>
              <Typography>但一般我會建議衝破咗依個區先入場</Typography>
              <Typography>係依個區域偷步嘅朋友要注意返風險🤣</Typography>
              <Typography>一般而言 收市價高過牛區就會觸發買入信號</Typography>
            </CustomBox>

            <CustomBox>
              <Typography>熊勢入口</Typography>
              <Typography>依個點多數會係一隻股票牛勢完結前刷新</Typography>
              <Typography>唔會有一隻股日日keep住強勢keep一段好長嘅時間 所以detect到隻股嘅強勢減弱同有回落跡象時 依一個點就會刷新</Typography>
              <Typography>如果隻股票係回調時穿咗熊勢入口 姐係依隻股已經進入弱勢 亦姐係大家可以做止賺/沽空嘅時候</Typography>
              <Typography>收市價低過熊口就超大機會變做觸發賣出</Typography>
            </CustomBox>

            <CustomBox>
              <Typography>另一個情況係 一隻股突然好弱時 就會刷新依點</Typography>
              <Typography>當穿咗依一點 就好大機會觸發賣出/沽空信號</Typography>
              <Typography>一般而言：收市價低過熊口就會觸發賣出/沽空信號</Typography>
            </CustomBox>

            <CustomBox>
              <Typography>7.燈號狀態</Typography>
              <Typography>我check股時會睇到埋隻股嘅短線狀態</Typography>
              <Typography>分咗5個 由5隻色代替 </Typography>
              <Typography>會試下係出信號/覆股時加埋上去</Typography>
            </CustomBox>

            <CustomBox>
              <Typography>綠燈：睇好</Typography>
              <Typography>黃燈：偏向進入牛勢</Typography>
              <Typography>白燈：Hold cash/方向唔明確</Typography>
              <Typography>橙橙：偏向進入熊勢</Typography>
              <Typography>紅燈：睇跌</Typography>
            </CustomBox>

            <CustomBox>
              <Typography>NEW (Sep 2022) 有隻新Model加咗入去 ‼️</Typography>
              <Typography>呢一隻Pattern多數會出現係大棍市</Typography>
              <Typography>棍上or棍落都係 </Typography>
              <Typography>E.g. 星期五大棍落完</Typography>
              <Typography>你會見到有啲股刷新左熊口 </Typography>
              <Typography>但明明現價$33 個熊口就刷咗係$33.15 </Typography>
              <Typography>呢個現象大家可以解讀為空軍係即市完成好第一步部署 而熊口係佢哋嘅防守位</Typography>
              <Typography>由於當日市場出現超跌情況 </Typography>
              <Typography>佢哋會驚有輕回嘅情況 </Typography>
              <Typography>所以冇完成全部落注</Typography>
            </CustomBox>

            <CustomBox>
              <Typography>而當第二日隻股都係無力去攻返上熊口</Typography>
              <Typography>就會大機會觸發賣出 </Typography>
              <Typography>亦即係空軍聰明錢完成落注</Typography>
              <Typography>————————————————————</Typography>
            </CustomBox>
          </CustomAccordionDetails>
        </CustomAccordion>

        <CustomAccordion expanded={expanded === "panel2"} onChange={handleChange("panel2")}>
          <CustomAccordionSummary aria-controls="panel2bh-content" id="panel2bh-header">
            <AccordionHeader>實戰應用</AccordionHeader>
          </CustomAccordionSummary>
          <CustomAccordionDetails>
            <CustomBox>
              <Typography>刷新牛區=系統探測到股票出現轉牛勢嘅可能性 </Typography>
              <Typography>收市價高於牛區=完成觸發買入</Typography>
              <Typography>成觸發買入=系統確認股票轉牛勢 建議買入</Typography>
            </CustomBox>

            <CustomBox>
              <Typography>刷新熊口=系統探索到股票轉熊勢嘅可能性</Typography>
              <Typography>收市價低過熊口=觸發沽空/賣出</Typography>
              <Typography>觸發沽空/賣出=系統確認股票轉熊勢 建議賣咗佢 甚至要沽空</Typography>
            </CustomBox>

            <CustomBox>
              <Typography>情況一：</Typography>
              <Typography>股票A觸發沽空 跌跌下刷咗個牛區出嚟 </Typography>
              <Typography>但都係觸發唔到買入 姐系繼續睇跌 </Typography>
              <Typography>因為觸發唔到嘅牛區=nothing</Typography>
            </CustomBox>

            <CustomBox>
              <Typography>情況二：</Typography>
              <Typography>相反 股票A觸發買入 升升下刷咗個熊口 </Typography>
              <Typography>但就打唔穿觸發唔到沽空/賣出 姐係繼續睇升</Typography>
              <Typography>因為打唔穿嘅熊口=nothing</Typography>
            </CustomBox>

            <CustomBox>
              <Typography>常見問題：</Typography>
              <Typography>我跟信號入咗 但隻嘢升完一段 未到價就開始有跌勢點算？</Typography>
            </CustomBox>

            <CustomBox>
              <Typography>突破後試一試個高位就回落 咁樣可能已經轉咗勢</Typography>
              <Typography>信號係依家個環境可以跳得好快 試過今日觸發買入 升左一日4-5% 第二日就測到強熊勢觸發賣出 甚至沽空信號</Typography>
              <Typography>通常我嘅做法係</Typography>
              <Typography>{">"}每日都去睇返最新信號變化</Typography>
              <Typography>{">"}係跌穿買入價前食咗佢 盡量唔比自己係依個環境下出現負數或坐貨</Typography>
              <Typography>E.g. $10觸發買入 目標$11.80 止蝕$9.50</Typography>
              <Typography>升到$11.10 就開始跌返 </Typography>
              <Typography>我就會set大約$10.30止賺 真係回到落嚟就賺少少出貨先 再觀察返入貨時機</Typography>
            </CustomBox>

            <CustomBox>
              <Typography>我跟住止蝕價賣咗後 就見佢開始回升 咁仲應唔應該嚴守止蝕價啊？</Typography>
            </CustomBox>

            <CustomBox>
              <Typography>我研究過一般系統出嘅止蝕位會比較防守型 以保本為目標 但會忽略咗大市同所屬板塊表現對該股嘅影響 </Typography>
              <Typography>所以我加入咗buffer嘅概念 就又我哋人腦透過對比當時大市同板塊嘅表現而比出相對嘅buffer做止蝕</Typography>
              <Typography>E.g. $10觸發買入 目標$11.80 止蝕$9.50</Typography>
              <Typography>觸發後開始跌 跌到$9.55時 </Typography>
              <Typography>發現大市跌緊好多 所屬板塊都表現麻麻</Typography>
              <Typography>就會比3-5%buffer佢 </Typography>
              <Typography>例如set $9.5x0.96=$9.12先止蝕</Typography>
            </CustomBox>

            <CustomBox>
              <Typography>你出完個信號時 個現價都升咗 仲去唔去追好啊？</Typography>
            </CustomBox>

            <CustomBox>
              <Typography>依個情況係正常嘅 </Typography>
              <Typography>如果我想入嘅 一般3%內我都會追</Typography>
              <Typography>E.g. $10觸發買入 目標$11.80 止蝕$9.50 </Typography>
              <Typography>佢升到$10.3我都仲會入 再高啲就唔追了 除非好中意佢😂</Typography>
              <Typography>相反 如果見我出完信號之後佢係咁跌</Typography>
              <Typography>唔好一定覺得係好荀 有平貨執 </Typography>
              <Typography>有時會因為一個假突破而觸發咗買入</Typography>
              <Typography>然後就被沽返落去 所以都係嗰句🤣</Typography>
              <Typography>「寧願買貴 都唔好買廢 」</Typography>
            </CustomBox>

            <CustomBox>
              <Typography>去到牛區/熊口時點先算觸發？</Typography>
              <Typography>用收市價睇最穩陣 </Typography>
              <Typography>收市價高過牛區=絕大機會觸發買入</Typography>
              <Typography>收市價低過熊口=絕大機會觸發賣出/沽空</Typography>
            </CustomBox>

            <CustomBox>
              <Typography>⭐️⭐️⭐️⭐️講到尾最穩陣就係 ⭐️⭐️⭐️⭐️</Typography>
              <Typography>等佢成功觸發買入信號先入貨</Typography>
              <Typography>等佢成功觸發賣出/沽空信號先去做空佢</Typography>
            </CustomBox>
          </CustomAccordionDetails>
        </CustomAccordion>

        <CustomAccordion expanded={expanded === "panel3"} onChange={handleChange("panel3")}>
          <CustomAccordionSummary aria-controls="panel3bh-content" id="panel3bh-header">
            <AccordionHeader>常見問題</AccordionHeader>
          </CustomAccordionSummary>
          <CustomAccordionDetails>
            <CustomBox>
              <Typography>Q1：牛區刷新左 個價一開市已經高過牛區 </Typography>
              <Typography>但理論上 要收市價高過牛區先觸發到 咁仲買唔買好？</Typography>
              <Typography>相反 熊口刷新左 個價一開市就低過熊口 </Typography>
              <Typography>但理論上 要收市價低過熊口先觸法到 咁仲沽唔沽好</Typography>
            </CustomBox>

            <CustomBox>
              <Typography>A1：呢類情況 穩陣派嘅朋友仔就唔建議去追 </Typography>
              <Typography>因為如果盤中跌返穿牛區/升返上熊口就會觸發失敗 隨時比人夾返轉頭 </Typography>
              <Typography>如果你係屬於有啲賭性想去搏嘅朋友仔 </Typography>
              <Typography>
                呢啲情況就可以去偷步買/沽/call/put 因為隻股隨時一去不回頭 到左收市確定觸發嘅時候 可能已經去到1T/2T or 殺左落expected range
              </Typography>
            </CustomBox>

            <CustomBox>
              <Typography>Q2：我係屬於穩陣派 如果隻股收市已經確定咗觸發買入 但已經到咗1T 咁仲追唔追呢？</Typography>
            </CustomBox>

            <CustomBox>
              <Typography>
                A2：好似上一篇文章講咁 每一個T都有沽壓 如果選擇係1T去追買入嘅話 你take嘅risk會大左 但呢個Risk會比偷步後觸發失敗嘅risk低
                因為一般確認觸發後都會轉做黃燈 再殺上去機會都大啲 所以如果係睇啱隻股想入嘅話可以係1T左右追(建議配合埋當時嘅指數信號去做考慮)
              </Typography>
            </CustomBox>

            <CustomBox>
              <Typography>Q3：我係空軍 見到隻股刷左熊口 好想炸佢 但現價已跌左2% 我又唔知個expected range係幾多 咁我仲追唔追炸好？</Typography>
            </CustomBox>

            <CustomBox>
              <Typography>A3：一般要成功觸發賣出/沽空先計到expected range </Typography>
              <Typography>
                所以呢個時候炸係會有Risk 建議你首先睇返過去呢隻股走弱時可以係2-3日跌到幾多% 因為有啲股就算走弱左 都只會跌3%左右 有啲股一走弱就嘔10幾%出嚟
              </Typography>
              <Typography>睇完再去決定追唔追炸</Typography>
            </CustomBox>

            <CustomBox>
              <Typography>Q4：聽你咁講 如果睇淡信號殺到落嚟expected range就大機會出現強support 咁我應唔應該係range低買入/call佢 食盡佢反彈嗰一浪？</Typography>
            </CustomBox>

            <CustomBox>
              <Typography>
                A4：理論上可以 但唔建議穩陣派咁樣做 因為到range底唔係一個買入信號嚟 不過 如果你係賭仔派 係可以咁樣搏 因為理論上 一隻股票信號嘅cycle係👇🏻
              </Typography>
              <Typography>
                刷新牛區{">"}觸發買入{">"}殺到到價{">"}遇上大沽壓{">"}走弱{">"}刷新熊口{">"}觸發賣出/沽空{">"}殺到range底{">"}遇上大support{">"}走強{">"}
                刷新牛區
                {">"}….
              </Typography>
              <Typography>所以揀係邊個位置入場 就睇你承受風險嘅能力</Typography>
              <Typography>記住冇嘢可以打破到一個定律 風險愈大回報愈高</Typography>
            </CustomBox>

            <CustomBox>
              <Typography>Q5:偷步有咩好處同壞處？</Typography>
            </CustomBox>

            <CustomBox>
              <Typography>A5: 同大家講多少少偷步原理</Typography>
            </CustomBox>

            <CustomBox>
              <Typography>1️⃣股票A係某日轉勢走強</Typography>
              <Typography>2️⃣我地會detect到有冇足夠嘅聰明錢流入</Typography>
              <Typography>3️⃣刷新牛區</Typography>
              <Typography>4️⃣當日收市價高過牛區=觸發買入</Typography>
              <Typography>5️⃣測到3個Target price </Typography>
            </CustomBox>

            <CustomBox>
              <Typography>以上cycle往往會用2-3日 </Typography>
              <Typography>所以好多玩熟咗嘅舊朋友仔就會選擇偷步係Step 1-2就入場call </Typography>
            </CustomBox>

            <CustomBox>
              <Typography>偷步好處：</Typography>
              <Typography>食哂成個升幅</Typography>
              <Typography>有時Step 4嘅時候已經殺到上2T</Typography>
              <Typography>到你跟足規矩觸發咗先入嘅時候可以食嘅肉就少咗</Typography>
            </CustomBox>

            <CustomBox>
              <Typography>偷步壞處：</Typography>
              <Typography>如果你係step 2-3就入咗 最後當日後市跌返穿牛區 導致觸發唔到買入 大機會會跌返落去</Typography>
            </CustomBox>

            <CustomBox>
              <Typography>其實由Step 1開始就有跡可尋 </Typography>
              <Typography>所以有啲朋友仔 Step 1就跳咗入去 </Typography>
              <Typography>係 係會好肥 但take嘅risk就大咗</Typography>
            </CustomBox>

            <CustomBox>
              <Typography>調返轉熊口嘅偷步都一樣</Typography>
              <Typography>記住higher risk higher return</Typography>
            </CustomBox>
          </CustomAccordionDetails>
        </CustomAccordion>
      </Container>
    </>
  );
}

export default SignalTutorial;
