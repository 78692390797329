import styled from "@emotion/styled";
import { Typography } from "@mui/material";

const CustomDescription = styled(Typography)({
  // your custom styles go here
  color: "white",
  fontSize: "20px",
  margin: "1px",
  fontFamily: "inherit",
}) as typeof Typography;

export default CustomDescription;
