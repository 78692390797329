import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { AxiosError, AxiosResponse } from "axios";
import { CustomError } from "../../interface/customError";

export interface ErrorState {
  isError: boolean;
  status: number;
  code: string;
  message: string;
  extras: string;
}

const initialState: ErrorState = {
  isError: false,
  status: 404,
  code: "",
  message: "",
  extras: "",
};

export const errorSlice = createSlice({
  name: "error",
  initialState,
  reducers: {
    createError: (state, action: PayloadAction<AxiosError>) => {
      const { code, message, response } = action.payload;

      if (response) {
        const data = (response as AxiosResponse<CustomError>).data || undefined;

        state.isError = true;
        state.status = data.status || 503;
        state.code = data.code || code || "";
        state.message = data.message || message;
        state.extras = data.extras || "";
      } else {
        state.isError = true;
        state.status = 503;
        state.code = code || "";
        state.message = code === "ERR_NETWORK" ? "網絡錯誤！請檢查你的網絡連接。" : message;
        state.extras = "";
      }
    },
    clearError: (state) => {
      state.isError = false;
      state.status = 404;
      state.code = "";
      state.message = "";
      state.extras = "";
    },
  },
});

// Action creators are generated for each case reducer function
export const { createError, clearError } = errorSlice.actions;

export default errorSlice.reducer;
