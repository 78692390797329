import React from "react";
import infoAsymmetry from "../images/infoAsymmetry.png";
import { CardMedia, Container, useMediaQuery, useTheme } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import smartMoney from "../images/smartMoney.png";
import signalBeforeNews from "../images/signalBeforeNews.png";
import CustomDescription from "../styles/customDescription";
import SectionTitle from "../styles/sectionTitle";
import SubHeader from "../styles/subHeader";

const singalFeatures = [
  {
    subHeader: "聰明錢",
    descriptions: [
      "聰明錢會在股票拉升/爆升前靜靜流入",
      "股價到頂 散戶追入場時慢慢流走",
      "同時亦會在股票下跌/暴跌前賣出或沽空",
      "跌至底部時進行平倉",
      "而途中會出現散戶跟隨拋售/沽空 加劇跌勢",
      "但到聰明錢平倉時",
      "股價就會出現好大支持 然後反彈",
    ],
    image: smartMoney,
    imageAlt: "",
  },
  {
    subHeader: "資訊不對等",
    descriptions: [
      "聰明錢擁有之資訊比市場散戶更多更快",
      "往往可以快人一步進行部署",
      "而SMH就透過數據分析",
      "將聰明錢動向化成數字",
      "盡可能跟住「聰明錢」入場",
      "再跟住「聰明錢」離場",
    ],
    image: infoAsymmetry,
    imageAlt: "",
  },
  {
    subHeader: "善用SMH信號 早於消息進場",
    descriptions: [
      "「聰明錢」往往在消息前部署好",
      "之後再利用消息拉升一波後獲利離場",
      "收割一班因消息而追入的散戶",
      "既然發現消息有滯後性",
      "又沒有大戶獲取快人一步消息的渠道",
      "不如改變炒股思維 不跟炒消息",
      "利用數字化信號捕捉「聰明錢」動態",
    ],
    image: signalBeforeNews,
    imageAlt: "",
  },
];

function SignalSystem() {
  const theme = useTheme();
  const isBelowMd = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <>
      <Container maxWidth="lg" component="main">
        <SectionTitle>SMH 信號原理</SectionTitle>
      </Container>

      <Container maxWidth="lg" component="main" sx={{ py: "50px" }}>
        <Grid container spacing={3} alignItems="center" justifyContent="center" textAlign="center" color="white">
          <Grid xs={12} md={6} lg={6}>
            <SubHeader>什麼是SMH信號?</SubHeader>
            <CustomDescription>透過收集每日不同指數及股票的交易數據</CustomDescription>
            <CustomDescription>配合自製並經多年測試與調整之Program</CustomDescription>
            <CustomDescription>以AI分析捕捉Smart Money動向</CustomDescription>
          </Grid>
          <Grid xs={12} md={6} lg={6}>
            <CardMedia
              sx={{ height: "360px", borderWidth: 0, boxShadow: "0px 0px 43px 15px rgba(255, 255, 255, 0.5)" }}
              component="iframe"
              src="https://www.youtube.com/embed/SIkfcVHvQwo"
              allowFullScreen
            ></CardMedia>
          </Grid>
        </Grid>
      </Container>

      {singalFeatures.map((feature, index) => {
        return (
          <Container maxWidth="lg" component="main" sx={{ py: "25px" }} key={index}>
            {isBelowMd || index % 2 !== 0 ? (
              <Grid container spacing={3} alignItems="center" justifyContent="center" textAlign="center" color="white">
                <Grid xs={12} md={6} lg={6}>
                  {feature.subHeader && <SubHeader>{feature.subHeader}</SubHeader>}
                  {feature.descriptions.length !== 0 &&
                    feature.descriptions.map((description, index) => {
                      return <CustomDescription key={index}>{description}</CustomDescription>;
                    })}
                </Grid>

                <Grid xs={12} md={6} lg={6}>
                  {feature.image && (
                    <Container>
                      <img src={feature.image} alt={feature.imageAlt} style={{ width: "inherit" }} />
                    </Container>
                  )}
                </Grid>
              </Grid>
            ) : (
              <Grid container spacing={3} alignItems="center" justifyContent="center" textAlign="center" color="white">
                <Grid xs={12} md={6} lg={6}>
                  {feature.image && (
                    <Container>
                      <img src={feature.image} alt={feature.imageAlt} style={{ width: "inherit" }} />
                    </Container>
                  )}
                </Grid>
                <Grid xs={12} md={6} lg={6}>
                  {feature.subHeader && <SubHeader>{feature.subHeader}</SubHeader>}
                  {feature.descriptions.length !== 0 &&
                    feature.descriptions.map((description, index) => {
                      return <CustomDescription key={index}>{description}</CustomDescription>;
                    })}
                </Grid>
              </Grid>
            )}
          </Container>
        );
      })}
    </>
  );
}

export default SignalSystem;
