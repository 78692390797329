import { createSlice } from "@reduxjs/toolkit";

export interface LoadingState {
  isLoading: boolean;
  isAuthLoading: boolean;
}

const initialState: LoadingState = {
  isLoading: true,
  isAuthLoading: false,
};

export const loadingSlice = createSlice({
  name: "loading",
  initialState,
  reducers: {
    startLoading: (state) => {
      state.isLoading = true;
    },
    doneLoading: (state) => {
      state.isLoading = false;
    },
    startAuthLoading: (state) => {
      state.isAuthLoading = true;
    },
    doneAuthLoading: (state) => {
      state.isAuthLoading = false;
    },
  },
});

// Action creators are generated for each case reducer function
export const { startLoading, doneLoading, startAuthLoading, doneAuthLoading } = loadingSlice.actions;

export default loadingSlice.reducer;
