import { Box, Button, CardMedia, Container, SvgIcon, Typography, styled } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import smhLogo from "../../images/smhLogo.png";
import Homepage_Feature_1 from "../../images/Homepage_Feature_1.png";
import Homepage_Feature_2 from "../../images/Homepage_Feature_2.png";
import { useNavigate } from "react-router-dom";
import Marquee from "react-fast-marquee";

const topazFeatures = [
  {
    title: "每日開市前更新主要股指訊號",
    descriptions: ["港美市場過百隻不同板塊熱門股票、指數", "美股: 17個版塊 87隻龍頭股", "港股: 13個版塊 40隻龍頭股"],
    // 98
  },
  { title: "期權異動實時追蹤 (美股Topaz專享)", descriptions: ["零時差捕捉大戶部署", "異動一目了然", "專人教你挑選爆升期權"] },
  {
    title: "問股專區助你洞悉先機",
    descriptions: ["過萬股票任你查詢", "掌握趨勢起動黃金時機", "發掘優質個股調倉最佳位置"],
  },
  {
    title: "Topaz 期指指標Bot",
    descriptions: ["自動推送指數期貨短炒信號", "陪你捕捉每個波段", "積少成多聚沙成塔"],
  },
  {
    title: "Topaz Family Chatroom",
    descriptions: ["八大admins各有所長", "分享大市走勢、即市部署", "傳授投資心法、發掘優質個股", "幾百位同路人齊上齊落"],
  },
  {
    title: "期權工作坊",
    descriptions: ["前投行期權Trader教學 有問必答", "由零開始陪你認識期權", "傳授即市短炒/Daytrade密技", "Tradingview圖表繪製教學"],
  },
  {
    title: "馬會娛樂台",
    descriptions: ["AI賭波信號", "賭仔聚腳點", "遇上悶市/休市時齊齊贏錢嘅地方"],
  },
];

const images = require.context("../../images/profit", true);
const imageList = images.keys().map((image) => image.substring(2));

function Features() {
  // const SectionTitle = styled(Typography)({
  //   // your custom styles go here
  //   color: "#F1C024",
  //   textAlign: "center",
  //   fontSize: "50px",
  //   fontWeight: "700",
  //   textShadow: "4px 4px 20px #F1C024",
  // }) as typeof Typography;

  const CustomHeader = styled(Typography)({
    // your custom styles go here
    color: "#F1C024",
    fontSize: "50px",
    fontWeight: "600",
    margin: "20px",
    textAlign: "center",
  }) as typeof Typography;

  const SmallSubHeader = styled(Typography)({
    // your custom styles go here
    color: "#F1C024",
    fontSize: "25px",
    fontWeight: "600",
    marginBottom: "5px",
  }) as typeof Typography;

  const CustomDescription = styled(Typography)({
    // your custom styles go here
    fontSize: "20px",
    margin: "10px",
    fontFamily: "inherit",
  }) as typeof Typography;

  const SmallDescription = styled(Typography)({
    // your custom styles go here
    fontSize: "16px",
    textAlign: "left",
  }) as typeof Typography;

  const CustomButton = styled(Button)({
    // your custom styles go here
    margin: "10px",
    backgroundColor: "rgb(25, 33, 49)",
    color: "rgb(255,255,255)",
    fontWeight: "700",
    fontSize: "20px",
    borderRadius: "5px",
    "&:hover": {
      backgroundColor: "rgb(25, 33, 49)",
      color: "rgb(255,255,255)",
      boxShadow: "1px 1px 10px 0px rgba(255,255,255,0.2)",
    },
  }) as typeof Button;

  const navigate = useNavigate();

  return (
    <>
      <Container maxWidth="lg" component="main" color="white">
        <Grid container alignItems="flex-start" justifyContent="center" textAlign="center" color="white">
          <Grid xs={12} md={6} lg={6}>
            <Container>
              <img src={Homepage_Feature_1} alt="Homepage_Feature_1" style={{ width: "inherit" }} />
            </Container>
          </Grid>

          <Grid xs={12} md={6} lg={6}>
            <Container>
              <img src={Homepage_Feature_2} alt="Homepage_Feature_2" style={{ width: "inherit" }} />
            </Container>
          </Grid>
          <Grid xs={12}>
            <CustomButton variant="contained" onClick={() => navigate("/signal")}>
              了解更多
            </CustomButton>
          </Grid>
        </Grid>
      </Container>

      <Container maxWidth="lg" component="main" sx={{ py: "25px" }}>
        <CustomHeader>過千會員實戰成績見證</CustomHeader>
        <Marquee gradient={true} gradientColor={"rgb(26, 25, 46)"} gradientWidth="50px" style={{ borderRadius: "20px" }}>
          {imageList.map((item, index) => (
            <img key={index} src={require(`../../images/profit/${item}`)} alt={item} style={{ objectFit: "contain", height: "200px", padding: "10px" }} />
          ))}
        </Marquee>
      </Container>

      <Container maxWidth="lg" component="main" sx={{ py: "75px" }}>
        <Grid container spacing={3} alignItems="center" justifyContent="center" textAlign="center" color="white">
          <Grid xs={12} md={6} lg={6}>
            <CardMedia
              sx={{ height: "360px", borderWidth: 0, boxShadow: "0px 0px 43px 15px rgba(255, 255, 255, 0.5)" }}
              component="iframe"
              src="https://www.youtube.com/embed/VfV8VjduRos"
              allowFullScreen
            ></CardMedia>
          </Grid>
          <Grid xs={12} md={6} lg={6}>
            <SmallSubHeader display="flex" justifyContent="center" alignItems="center">
              點解要加入Topaz收費頻道?
              <img src={smhLogo} alt="SMH Logo" style={{ width: "75px", height: "75px" }} />
            </SmallSubHeader>
            <CustomDescription>Topaz收費頻道係Smart Money House嘅收費頻道</CustomDescription>
            <CustomDescription>除了皇牌AI訊號系統</CustomDescription>
            <CustomDescription>以AI分析捕捉Smart Money動向</CustomDescription>
            <CustomDescription>我哋總共有多個Channel/Group</CustomDescription>
            <CustomDescription>陪你玩盡正股/ETF/期貨/期權/虛擬貨幣</CustomDescription>
            <Box>
              <CustomButton variant="contained" href="https://t.me/SMH_NCO" target="_blank">
                立即加入
              </CustomButton>
            </Box>
          </Grid>
        </Grid>
      </Container>

      <Container maxWidth="lg" component="main" sx={{ py: "25px" }}>
        <SmallSubHeader>Topaz收費頻道內容豐富</SmallSubHeader>
        <Grid container spacing={3} alignItems="flex-start" justifyContent="flex-start" textAlign="center" color="white">
          {topazFeatures.map((feature, index) => {
            return (
              <Grid xs={12} sm={6} md={4} key={index}>
                <Typography
                  sx={{ color: "#F1C024", textAlign: "left", fontWeight: "700", fontSize: "20px", marginBottom: "10px", textShadow: "2px 2px 10px #F1C024" }}
                >
                  {feature.title}
                </Typography>
                {feature.descriptions.map((description, index) => {
                  return (
                    <Box display="flex" alignItems="center" key={index}>
                      <Typography p={1} display="flex">
                        <SvgIcon>
                          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                            <path
                              d="M12 2C11.5 2 11 2.19 10.59 2.59L2.59 10.59C1.8 11.37 1.8 12.63 2.59 13.41L10.59 21.41C11.37 22.2 12.63 22.2 13.41 21.41L21.41 13.41C22.2 12.63 22.2 11.37 21.41 10.59L13.41 2.59C13 2.19 12.5 2 12 2Z"
                              fill="#ffffff"
                            />
                          </svg>
                        </SvgIcon>
                      </Typography>
                      <SmallDescription>{description}</SmallDescription>
                    </Box>
                  );
                })}
              </Grid>
            );
          })}
        </Grid>
      </Container>
    </>
  );
}

export default Features;
