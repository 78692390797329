import styled from "@emotion/styled";
import { Typography, Container, Link } from "@mui/material";
import React from "react";
import CustomBox from "../../styles/CustomBox";
import { WhiteSectionTitle } from "../../utils/customStyle";

function PinoexCryptoChannelIntro() {
  const CustomDescription = styled(Typography)({
    color: "white",
  }) as typeof Typography;

  return (
    <Container maxWidth="lg" component="main" sx={{ color: "white" }}>
      <WhiteSectionTitle>SMH X Pionex派網Crypto頻道</WhiteSectionTitle>

      <CustomBox>
        <CustomDescription>交易主流加密貨幣</CustomDescription>
        <CustomDescription>設有單向頻道及Trader’s Chatroom</CustomDescription>
      </CustomBox>

      <CustomBox>
        <CustomDescription>✅最新市場資訊/經濟數據/金融新聞</CustomDescription>
        <CustomDescription>✅每日BTC 訊號</CustomDescription>
        <CustomDescription>✅派網每日提供熱門山寨幣種</CustomDescription>
        <CustomDescription>✅落trade分享</CustomDescription>
      </CustomBox>

      <CustomBox>
        <CustomDescription>派網呢個Platform好玩在提供量化交易為主，提供16款交易機器人。</CustomDescription>
        <CustomDescription>連低風險策略都有，無乜研究虛擬貨幣嘅新手都可以佛系跟單🤤</CustomDescription>
      </CustomBox>

      <CustomBox>
        <CustomDescription>🤖 自由創建網格/套利/定投等策略，派網提供智能參數，一鍵創建回報率高的策略</CustomDescription>
        <CustomDescription>🤖 選擇跟單不同高手創建的交易機械人，躺平賺取收益，24小手時自動運行，無需監察</CustomDescription>
        <CustomDescription>
          🤖 行業領先於開發量化交易功能，派網成為首家成功給合AI的交易所。不再需要用學程式，只要你的交易想法足夠，PionexGPT即可幫你寫成策略運行
        </CustomDescription>
      </CustomBox>

      <CustomBox>
        <CustomDescription>🛡 平台聚合幣安、火幣網等知名交易所，確保交易的深度與流動性。</CustomDescription>
        <CustomDescription>🛡新加坡金融管理局（MAS）認可 + 美國 FinCEN (金融犯罪執法局) MSB 監管牌照</CustomDescription>
        <CustomDescription>🛡加密貨幣保存設100%準備金，並於官網定期更新默克爾樹，提供用戶隨時查看資產詳情。不挪用用戶資金。</CustomDescription>
      </CustomBox>

      <CustomBox>
        <CustomDescription>想加入免費Crypto頻道只需要3個步驟</CustomDescription>
        <CustomDescription>使用我哋連結喺派網開戶</CustomDescription>
        <CustomDescription>完成入金</CustomDescription>
        <CustomDescription>完成 1 個交易</CustomDescription>
      </CustomBox>

      <CustomBox>
        <CustomDescription>就可以搵Admin力高妹妹核對帳戶號碼及加你入頻道一齊玩</CustomDescription>
        <CustomDescription>
          聯絡Admin Nicole妹妹報名:
          <Link sx={{ ml: 1 }} href="https://t.me/SMH_NCO">
            https://t.me/SMH_NCO
          </Link>
        </CustomDescription>
      </CustomBox>

      <CustomBox>
        <CustomDescription>🟡SMH開戶Link：</CustomDescription>
        <CustomDescription>
          開戶連結:
          <Link sx={{ ml: 1 }} href="https://www.pionex.com/zh-TW/signUp?r=IiYlZMF2">
            https://www.pionex.com/zh-TW/signUp?r=IiYlZMF2
          </Link>
        </CustomDescription>
      </CustomBox>

      <CustomBox>
        <CustomDescription>🟡邀請碼：IiYlZMF2</CustomDescription>
      </CustomBox>
    </Container>
  );
}

export default PinoexCryptoChannelIntro;
