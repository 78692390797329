import { Navigate, RouterProvider, createBrowserRouter } from "react-router-dom";
import Root from "./Root";
import { Counter } from "./pages/Counter";
import SignalSystem from "./pages/SignalSystem";
import Testimonial from "./pages/Testimonial";
import PricePlan from "./pages/PricePlan";
import ContactUs from "./pages/ContactUs";
import AboutUs from "./pages/AboutUs";
import Home from "./pages/Home";
import UploadUser from "./pages/admin/UploadUser";
import OptionBotIntro from "./pages/OptionBotIntro";
import IndicatorIntro from "./pages/IndicatorIntro";
import ChartAnalysisIntro from "./pages/ChartAnalysisIntro";
import OptionBotTutorial from "./pages/OptionBotTutorial";
import MembersOrientation from "./pages/MembersOrientation";
import SignalTutorial from "./pages/SignalTutorial";
import IndicatorTutorial from "./pages/IndicatorTutorial";
import OptionTutorial from "./pages/OptionTutorial";
import Login from "./pages/authentication/Login";
import PrivateRoute from "./router/PrivateRoute";
import { useEffect } from "react";
import { fetchAutoLogin } from "./stores/auth/thunk";
import { useAppDispatch } from "./stores/hook";
import Register from "./pages/authentication/Register";
import EarningsTutorial from "./pages/EarningsTutorial";
import CfdTutorial from "./pages/CfdTutorial";
import ChartTutorial from "./pages/ChartTutorial";
import EmailVerification from "./pages/EmailVerification";
import AdminRoute from "./router/AdminRoute";
import USSignal from "./pages/USSignal";
import USRoute from "./router/USRoute";
import HKRoute from "./router/HKRoute";
import HKSignal from "./pages/HKSignal";
import MyAccount from "./pages/MyAccount";
import ForgotPassword from "./pages/authentication/ForgotPassword";
import CfdChannelIntro from "./pages/freeChannelIntro/CfdChannelIntro";
import PinoexCryptoChannelIntro from "./pages/freeChannelIntro/PinoexCryptoChannelIntro";
import OtherChannelIntro from "./pages/freeChannelIntro/OtherChannelIntro";

function App() {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(fetchAutoLogin());
  }, []);

  const router = createBrowserRouter([
    {
      path: "",
      element: <Root />,
      children: [
        {
          path: "/",
          element: <Home />,
        },
        {
          path: "about_us",
          element: <AboutUs />,
        },
        {
          path: "signal",
          element: <SignalSystem />,
        },
        {
          path: "indicator_intro",
          element: <IndicatorIntro />,
        },
        {
          path: "option_bot_intro",
          element: <OptionBotIntro />,
        },
        {
          path: "chart_analysis_intro",
          element: <ChartAnalysisIntro />,
        },
        {
          path: "cfd_channel_intro",
          element: <CfdChannelIntro />,
        },
        {
          path: "pionex_crypto_channel_intro",
          element: <PinoexCryptoChannelIntro />,
        },
        {
          path: "other_channel_intro",
          element: <OtherChannelIntro />,
        },
        {
          path: "testimonial",
          element: <Testimonial />,
        },
        {
          path: "price_plan",
          element: <PricePlan />,
        },
        {
          path: "contact_us",
          element: <ContactUs />,
        },
        {
          path: "upload_users",
          element: (
            <AdminRoute>
              <UploadUser />
            </AdminRoute>
          ),
        },
        { path: "login", element: <Login /> },
        { path: "register", element: <Register /> },
        { path: "forgot_password", element: <ForgotPassword /> },
        { path: "verify_email", element: <EmailVerification /> },
        {
          path: "my_account",
          element: (
            <PrivateRoute>
              <MyAccount />
            </PrivateRoute>
          ),
        },
        {
          path: "members_orientation",
          element: (
            <PrivateRoute>
              <MembersOrientation />
            </PrivateRoute>
          ),
        },
        {
          path: "us_signal",
          element: (
            <USRoute>
              <USSignal />
            </USRoute>
          ),
        },
        {
          path: "hk_signal",
          element: (
            <HKRoute>
              <HKSignal />
            </HKRoute>
          ),
        },
        {
          path: "signal_tutorial",
          element: (
            <PrivateRoute>
              <SignalTutorial />
            </PrivateRoute>
          ),
        },
        {
          path: "indicator_tutorial",
          element: (
            <PrivateRoute>
              <IndicatorTutorial />
            </PrivateRoute>
          ),
        },
        {
          path: "chart_tutorial",
          element: (
            <PrivateRoute>
              <ChartTutorial />
            </PrivateRoute>
          ),
        },
        {
          path: "option_tutorial",
          element: (
            <PrivateRoute>
              <OptionTutorial />
            </PrivateRoute>
          ),
        },
        {
          path: "option_bot_tutorial",
          element: (
            <PrivateRoute>
              <OptionBotTutorial />
            </PrivateRoute>
          ),
        },
        {
          path: "earnings_tutorial",
          element: (
            <PrivateRoute>
              <EarningsTutorial />
            </PrivateRoute>
          ),
        },
        {
          path: "cfd_tutorial",
          element: (
            <PrivateRoute>
              <CfdTutorial />
            </PrivateRoute>
          ),
        },
        {
          path: "*",
          element: <Navigate to={"/"} />,
        },
      ],
    },
    {
      path: "counter",
      Component: Counter,
      loader: async () => {
        const result = await fetch("https://dummyjson.com/product");
        return result;
      },
    },
  ]);

  return <RouterProvider router={router} />;
}

export default App;
