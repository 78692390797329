import client, { buildLink } from "./config";

export function downloadUploadedUserList(fileName: string) {
  window.open(buildLink(`/v1/user/${fileName}`));
}

export async function bulkCreateUsers(subscriptionid: number, data: FormData) {
  const res = await client.post(`v1/user/bulkCreate/subscriptionid/${subscriptionid}`, data);
  const result = res.data;
  return result;
}
