import styled from "@emotion/styled";
import { Typography } from "@mui/material";

const AccordionHeader = styled(Typography)({
  // your custom styles go here
  color: "#F1C024",
  fontSize: "20px",
  fontWeight: "600",
  // margin: "5px",
}) as typeof Typography;

export default AccordionHeader;
