import styled from "@emotion/styled";
import { Typography } from "@mui/material";

const WhiteSectionTitle = styled(Typography)({
  color: "white",
  textAlign: "left",
  fontSize: "30px",
  fontWeight: "700",
  paddingTop: "20px",
  paddingBottom: "20px",
}) as typeof Typography;

export { WhiteSectionTitle };
