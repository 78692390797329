import React from "react";
import { Box, Divider, IconButton, Typography, styled } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import InstagramIcon from "@mui/icons-material/Instagram";
import TelegramIcon from "@mui/icons-material/Telegram";
import YouTubeIcon from "@mui/icons-material/YouTube";
import FacebookIcon from "@mui/icons-material/Facebook";

function Footer() {
  const CustomIcon = styled(IconButton)({
    padding: "10px",
    fontFamily: "inherit",
    cursor: "pointer",
    color: "lightgrey",
  }) as typeof IconButton;

  return (
    <>
      <Divider variant="middle" sx={{ color: "white", borderColor: "rgba(255, 255, 255, 0.2)", borderRadius: "25px" }} />
      <Grid container spacing={1}>
        <Grid xs={12} display="flex" justifyContent="center">
          <CustomIcon href="https://t.me/SMH_NCO" target="_blank">
            <TelegramIcon />
          </CustomIcon>
          <CustomIcon href="https://www.instagram.com/smart_money_house/" target="_blank">
            <InstagramIcon />
          </CustomIcon>
          <CustomIcon href="https://www.youtube.com/@hugobearbig" target="_blank">
            <YouTubeIcon />
          </CustomIcon>
          <CustomIcon href="https://www.facebook.com/profile.php?id=100066765579039" target="_blank">
            <FacebookIcon />
          </CustomIcon>
        </Grid>
        <Grid xs={12} display="flex" justifyContent="center">
          <Box sx={{ textAlign: "center" }}>
            <Typography color="lightgrey" fontSize="12px">
              免責聲明: 注意投資有風險，需謹慎。以上內容只屬個人分享和研究用途，並不構成對任何投資建議，投資邀約和本金保證。投資者需自行為投資盈虧負責。
            </Typography>
            <Typography color="lightgrey" fontSize="12px">
              Copyright © 2023 Smart Money House
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </>
  );
}

export default Footer;
