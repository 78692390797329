import { Modal, Box, Typography, Button, styled, useMediaQuery, useTheme } from "@mui/material";
import React, { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../stores/hook";
import { RootState } from "../../stores/store";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import Grid from "@mui/material/Unstable_Grid2";
import { clearSuccessMsg } from "../../stores/success/successSlice";

const belowSmStyle = {
  position: "absolute" as const,
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 300,
  bgcolor: "rgb(245, 245, 245)",
  border: "2px solid #000",
  borderRadius: "10px",
  boxShadow: 24,
  p: 2,
  textAlign: "center",
};

const belowMdStyle = {
  position: "absolute" as const,
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 350,
  bgcolor: "rgb(245, 245, 245)",
  border: "2px solid #000",
  borderRadius: "10px",
  boxShadow: 24,
  p: 2,
  textAlign: "center",
};

const style = {
  position: "absolute" as const,
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "rgb(245, 245, 245)",
  border: "2px solid #000",
  borderRadius: "10px",
  boxShadow: 24,
  p: 2,
  textAlign: "center",
};

function SuccessHandling() {
  const CustomButton = styled(Button)({
    // your custom styles go here
    my: 1,
    background: "rgb(59, 184, 120)",
    color: "white",
    "&:hover": {
      color: "white",
      backgroundColor: "rgb(59, 184, 120)",
      boxShadow:
        "box-shadow: rgba(52, 71, 103, 0.15) 0rem 0.1875rem 0.1875rem 0rem, rgba(52, 71, 103, 0.2) 0rem 0.1875rem 0.0625rem -0.125rem, rgba(52, 71, 103, 0.15) 0rem 0.0625rem 0.3125rem 0rem",
    },
  }) as typeof Button;

  const dispatch = useAppDispatch();

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    dispatch(clearSuccessMsg());
  };

  const isSuccess = useAppSelector((state: RootState) => state.success.isSuccess);
  const successMessage = useAppSelector((state: RootState) => state.success.successMessage);
  const buttonMessage = useAppSelector((state: RootState) => state.success.buttonMessage);
  const buttonUrl = useAppSelector((state: RootState) => state.success.buttonUrl);
  const successExtraMessage = useAppSelector((state: RootState) => state.success.successExtraMessage);

  useEffect(() => {
    if (isSuccess) {
      handleOpen();
    }
  }, [isSuccess]);

  const theme = useTheme();
  const isBelowSm = useMediaQuery(theme.breakpoints.down("sm"));
  const isBelowMd = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <>
      {successMessage && (
        <div>
          <Modal open={open} onClose={handleClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
            <Box sx={isBelowSm ? belowSmStyle : isBelowMd ? belowMdStyle : style}>
              <Box sx={{ maxHeight: isBelowSm ? "300px" : isBelowMd ? "350px" : "400px", overflow: "auto", mb: 2 }}>
                <Typography sx={{ mt: 1 }}>
                  <CheckCircleIcon sx={{ fontSize: isBelowSm ? "80px" : isBelowMd ? "90px" : "100px", color: "rgb(59, 184, 120)" }} />
                </Typography>

                <Typography
                  id="modal-modal-title"
                  sx={{ my: 2, fontWeight: 700, fontSize: isBelowSm ? "20px" : isBelowMd ? "25px" : "30px", color: "rgb(70, 70, 70)" }}
                >
                  {successMessage}
                </Typography>
                {successExtraMessage && (
                  <Typography id="modal-modal-title" sx={{ my: 1, fontWeight: 500, fontSize: isBelowMd ? "12px" : "16px", color: "rgb(70, 70, 70)" }}>
                    {successExtraMessage}
                  </Typography>
                )}
              </Box>

              <Grid container spacing={2}>
                <Grid xs={12}>
                  {buttonUrl ? (
                    <CustomButton variant="contained" fullWidth onClick={handleClose} href={buttonUrl}>
                      {buttonMessage}
                    </CustomButton>
                  ) : (
                    <CustomButton variant="contained" fullWidth onClick={handleClose}>
                      {buttonMessage}
                    </CustomButton>
                  )}
                </Grid>
              </Grid>
            </Box>
          </Modal>
        </div>
      )}
    </>
  );
}

export default SuccessHandling;
