import { Container, Link, Typography, styled } from "@mui/material";
import React from "react";
import CustomBox from "../../styles/CustomBox";
import { WhiteSectionTitle } from "../../utils/customStyle";

function CfdChannelIntro() {
  const CustomDescription = styled(Typography)({
    color: "white",
    // marginLeft: "10px",
  }) as typeof Typography;
  
  return (
    <Container maxWidth="lg" component="main" sx={{ color: "white" }}>
      <WhiteSectionTitle>SMH CFD 免費頻道 (使用Telegram平台)</WhiteSectionTitle>
      <CustomBox>
        <CustomDescription>主要交易外匯/黃金/原油</CustomDescription>
        <CustomDescription>設有單向頻道及Trader’s Chatroom</CustomDescription>
        <CustomDescription>7位admin及二百多位戰友每日齊上齊落</CustomDescription>
      </CustomBox>
      <CustomBox>
        <CustomDescription>✅最新市場資訊/經濟數據/金融新聞</CustomDescription>
        <CustomDescription>✅每日AI信號</CustomDescription>
        <CustomDescription>✅每日圖表分析 </CustomDescription>
        <CustomDescription>✅落trade分享</CustomDescription>
      </CustomBox>
      <CustomBox>
        <CustomDescription>除咗交易外匯/黃金/原油</CustomDescription>
        <CustomDescription>CFD都係玩指數期貨嘅一個必備工具 </CustomDescription>
        <CustomDescription>由於槓桿1:500關係 </CustomDescription>
        <CustomDescription>玩恒指/納指/道指期貨都只需要整低本金</CustomDescription>
      </CustomBox>
      <CustomBox>
        <CustomDescription>以恒指為例 </CustomDescription>
        <CustomDescription>喺股票證券商交易一張大期需要約HKD80000保證金先買到</CustomDescription>
      </CustomBox>
      <CustomBox>
        <CustomDescription>但喺CFD世界 </CustomDescription>
        <CustomDescription>只需要約HKD1640 </CustomDescription>
        <CustomDescription>大大減低入場門檻</CustomDescription>
      </CustomBox>
      <CustomBox>
        <CustomDescription>想加入免費CFD頻道只需要3個步驟</CustomDescription>
        <CustomDescription>使用我哋連結開戶</CustomDescription>
        <CustomDescription>完成入金</CustomDescription>
        <CustomDescription>完成一次交易</CustomDescription>
      </CustomBox>
      <CustomBox>
        <CustomDescription>就可以搵Admin力高妹妹核對帳戶號碼及加你入頻道一齊玩</CustomDescription>
      </CustomBox>

      <CustomBox>
        <CustomDescription>暫時以下三間CFD經紀商可供選擇(詳情可參閱常用經紀商/平台介紹) ：</CustomDescription>
      </CustomBox>

      <CustomBox>
        <CustomDescription>1️⃣ Vantage</CustomDescription>
        <CustomDescription>
          開戶連結:
          <Link sx={{ ml: 1 }} href="https://vigco.co/EBfAJj">
            https://vigco.co/EBfAJj
          </Link>
        </CustomDescription>
      </CustomBox>

      <CustomBox>
        <CustomDescription>2️⃣ TMGM</CustomDescription>
        <CustomDescription>
          開戶連結:
          <Link sx={{ ml: 1 }} href="https://portal.tmgm.com/register?node=MTExMjY0&language=zh-Hans">
            https://portal.tmgm.com/register?node=MTExMjY0&language=zh-Hans
          </Link>
        </CustomDescription>
      </CustomBox>

      <CustomBox>
        <CustomDescription>3️⃣ Ultima market</CustomDescription>
        <CustomDescription>
          開戶連結:
          <Link sx={{ ml: 1 }} href="https://ultgo.com/fQ77nq">
            https://ultgo.com/fQ77nq
          </Link>
        </CustomDescription>
      </CustomBox>
    </Container>
  );
}

export default CfdChannelIntro;
