import { Container, useMediaQuery, useTheme } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import React from "react";
import optionBotTutorial1 from "../images/optionBotTutorial/optionBotTutorial1.png";
import optionBotTutorial2 from "../images/optionBotTutorial/optionBotTutorial2.png";
import optionBotTutorial3 from "../images/optionBotTutorial/optionBotTutorial3.png";
import CustomDescription from "../styles/customDescription";
import SectionTitle from "../styles/sectionTitle";
import SubHeader from "../styles/subHeader";
import { WhiteSectionTitle } from "../utils/customStyle";
import CustomAccordion from "../styles/CustomAccordion";
import CustomAccordionDetails from "../styles/CustomAccordionDetails";
import AccordionHeader from "../styles/CustomAccordionHeader";
import CustomAccordionSummary from "../styles/CustomAccordionSummary";
import CustomBox from "../styles/CustomBox";

const singalFeatures = [
  {
    subHeader: "參考方向用途",
    descriptions: [
      "呢一類會係 TSLA QQQ SPY IWM 呢啲",
      "呢啲係常客 每一日都會瘋狂出現Bot到",
      "但大家會留意到 Call同Put都好多人買",
      "同埋買呢啲嘅機構一定係大手大手咁掃",
      "佢哋嘅成交額我唔會去做考慮因素",
      "而風向吹邊一邊 大家都可以留意番Call多定Put多",
      "而我自己就好少去跟佢地入 最多係做一個判斷方向嘅用途",
      "(除非你有時會見到啲比較瘋狂嘅Call/Put",
      "例TSLA 現價$900 佢Call $1000 幾日後到期 咁嘅話參考價值會大返少少)",
    ],
    image: optionBotTutorial1,
    imageAlt: "",
  },
  {
    subHeader: "值得考慮跟",
    descriptions: [
      "呢一類通常係啲中size少少嘅股",
      "例如 CRM/UBER/SNOW/ZS/XOP/SHOP/BABA呢啲",
      "大家可以理解做空穴來風必有因",
      "無啦啦有人大手大手掃呢啲二線股Call/Put 你可以當係佢擁有嘅資訊比你多比你快 佢嘅動作亦都會比市場快",
      "呢類嘅話我會覺得值得 有事我見到都會直接share出Channel",
    ],
    image: optionBotTutorial2,
    imageAlt: "",
  },
  {
    subHeader: "六合彩形",
    descriptions: [
      "呢一啲通常喺出邊喺邊稀客身上",
      "即係好日都唔會出現係Bot",
      "仲要一Call/Put就將行權價擺到9里遠 ",
      "張期權得$0.1-0.7左右",
      "一掃就掃10幾萬張",
      "呢啲人一係就係傻嘅 一係就係insider",
      "例如之前啲 CVNA/BBBY之類",
      "呢一類可以當六合彩咁買",
      "一係就輸晒 一係就爆唔知幾多倍",
      "大家控制返注碼就OK",
    ],
    image: optionBotTutorial3,
    imageAlt: "",
  },
];

const optionBotTutorial = [
  {
    subHeader: "1️⃣ Emoji",
    descriptions: [
      "新加入嘅一堆emoji 只會喺非常特別嘅情況出現",
      "暫時分類為12個 可以令各位更快睇到呢張值唔值得跟",
      "🆕 100%肯定係全新倉",
      "⚠️ 成交張數 大於 上一日未平倉張數",
      "📈 CALL嘅同時 行權價係5%以上",
      "📉 PUT嘅同時 行權價係5%以下",
      "🔥 當日成交量大於上一日未平倉張數10倍 (暗示大機會開新倉)",
      "🎰 行權價係10%以外 終極六合彩",
      "💉 大家最期待嘅藥股",
      "🧰 該標的屬於ETF",
      "🌎 該標的屬於 ADR",
      "📊 7天內公布業績",
      "⌛️ 14天內公布業績",
      "0️⃣ 該期權即日到期(0DTE)",
    ],
  },
  { subHeader: "2️⃣嗰位交易者嘅取態", descriptions: ["🐮CALL=大機會睇升", "🐻PUT=大機會睇跌"] },
  {
    subHeader: "3️⃣行權價",
    descriptions: [
      "大家可以睇返佢買嘅期權究竟係",
      "In the money定係Out of the money ",
      "好多時大單買In the money嘅都係平倉嘅單 ",
      "參考價值無開新倉咁大",
      "如果係大單開倉買Out of the money就更具跟單價值",
    ],
  },
  { subHeader: "4️⃣成交額", descriptions: ["呢個就直接俾大家睇條友買到幾大"] },
  {
    subHeader: "5️⃣成交方向",
    descriptions: [
      "呢part大致上會出現3個情況",
      "ASK SIDE ",
      "MID SIDE",
      "NO SIDE",
      "亦都係用嚟判斷嗰個交易者嘅取熊",
      "",
      "如果佢係LONG嘅 ",
      "ASK SIDE 姐係係嗰一刻 佢直接係Offer price到買貨 無排隊 買貴啲都唔介意 ",
      "套落LONG CALL=佢覺得張Call好快就升 急住睇好隻股",
      "套落LONG PUT=佢覺得張Put好快就升 急住睇淡隻股",
      "",
      "遲吓有唔同嘅situation再解釋其他",
    ],
  },
  {
    subHeader: "6️⃣ 異動嘅類別",
    descriptions: [
      "N/A 代表普通異動 唔代表冇參考價值",
      "以下係再加篩選 但都唔係盲gun",
      "類別分為10個",
      "",
      "Volume Over OI",
      "上日未平倉張數比例大於100 ",
      "同時當日成交量與上日未平倉張數比例大於10",
      "",
      "Repeated Hits",
      "捕捉同一條期權鏈捕捉2秒內超過10單嘅超大交易成交",
      "該10單嘅成交必須符合以下條件：",
      "成交額$2.5萬以上以該股票市值達500億以下",
      "成交額$5萬以上以該股票市值達500億以上",
      "成交額$10萬以上以該股票市值達5000億以上",
      "",
      "Repeated Hits Ascending Fill",
      "捕捉同一條期權鏈捕捉2秒內超過10單嘅超大交易成交",
      "同時該10單交易嘅嘅成交價有上升趨勢",
      "有機會係交易員追入貨",
      "該10單嘅成交必須符合以下條件：",
      "成交額$2.5萬以上以該股票市值達500億以下",
      "成交額$5萬以上以該股票市值達500億以上",
      "成交額$10萬以上以該股票市值達5000億以上",
      "",
      "Repeated Hits Descending Fill",
      "捕捉同一條期權鏈捕捉2秒內超過10單嘅超大交易成交",
      "同時該10單交易嘅嘅成交價有下降趨勢",
      "該10單嘅成交必須符合以下條件：",
      "成交額$2.5萬以上以該股票市值達500億以下",
      "成交額$5萬以上以該股票市值達500億以上",
      "成交額$10萬以上以該股票市值達5000億以上",
      "",
      "Low Historic Volume Floor",
      "捕捉同一條期權鏈捕捉1秒內多單總數成交超過$5萬 市值少於 1500億",
      "同時嗰日同一條期權嘅總成交量係明顯大於過去咁多日嘅平均成交量 (平時基本上冇人trade呢啲期權)",
      "參考價值就更大",
      "",
      "OTM Earnings Floor",
      "捕捉同一條期權鏈捕捉1秒內多單總數成交超過$25萬而行權日90日以內",
      "該交易係由場內交易員執行",
      "而該股票將會喺兩星期內公布業績",
      "可能有機會係賭業績",
      "",
      "Floor Trade Small Cap",
      "捕捉同一條期權鏈捕捉1秒內多單成交而該交易係由場內交易員執行",
      "條件為下:",
      "市值: 3-20億",
      "期權總成交額：大於10萬",
      "期權總成交數量：大於1000",
      "",
      "Floor Trade Mid Cap",
      "捕捉同一條期權鏈捕捉1秒內多單成交而該交易係由場內交易員執行",
      "條件為下:",
      "市值: 20-100億",
      "期權總成交額：大於10萬",
      "期權總成交數量：大於1000",
      "",
      "Floor Trade Large Cap",
      "捕捉同一條期權鏈捕捉1秒內多單成交而該交易係由場內交易員執行",
      "條件為下:",
      "市值: 100-500億",
      "期權總成交額：大於10萬",
      "期權總成交數量：大於1000",
    ],
  },
  {
    subHeader: "7️⃣ 當日成交量與上日未平倉張數比例",
    descriptions: [
      "近價嘅 呢個比例高唔算非常特別",
      "但如果係較為遠價嘅 呢個比例喺高嘅話參考價值更大",
      "呢個比例越接近10嘅話 頗大機會係新倉",
      "如果係遠價嘅 而呢個比例大個10嘅話 都幾乎肯定係新倉",
      "",
      "呢到大家可以參考埋 佢入貨嘅數量同未平倉合約嘅數量對比",
      "如果有時大家見到有人Long左1000張Call ",
      "而未平倉合約得400張 ",
      "咁樣就大機會係呢個交易員開新倉 咁樣嘅話gun買嘅參考價值就大咗",
      "",
      "如果有人Long左500張Call 而未平倉合約有2000張 ",
      "咁樣呢條友有可能只係幫佢嘅Short Call做平倉 咁參考價值就冇咁大(尤其係in the money嘅時候)",
    ],
  },
  { subHeader: "8️⃣ OTM比例", descriptions: ["行權價係現價幾多%以外"] },
  {
    subHeader: "9️⃣呢個係系統對個交易嘅進一步解說",
    descriptions: [
      "呢Part都會有好多唔同情境出現 ",
      "",
      "如果第一行見到個12個emoji嘅其中一個",
      "呢度就會出現詳細嘅解釋",
      "eg. ",
      " ⁃ 成交張數與上日未平倉張數比例越高嘅話 越大機會係開新倉 ",
      " ⁃ 睇吓佢係咪賭業績",
    ],
  },
];

function OptionBotTutorial() {
  const theme = useTheme();
  const isBelowMd = useMediaQuery(theme.breakpoints.down("md"));

  const [expanded, setExpanded] = React.useState<string | false>(false);

  const handleChange = (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
    setExpanded(newExpanded ? panel : false);
  };

  return (
    <Container maxWidth="lg" component="main">
      <WhiteSectionTitle>期權異動信號說明書</WhiteSectionTitle>
      <CustomBox>
        <CustomDescription>1️⃣#PACW 🆕⚠️📉📊</CustomDescription>
        <CustomDescription>2️⃣PUT 🐻</CustomDescription>
        <CustomDescription>3️⃣行權價: $7</CustomDescription>
        <CustomDescription>正股股價: $7.701</CustomDescription>
      </CustomBox>

      <CustomBox>
        <CustomDescription>到期日: 2023-08-04</CustomDescription>
        <CustomDescription>成交張數: 2,002</CustomDescription>
        <CustomDescription>未平倉張數: 628</CustomDescription>
        <CustomDescription>4️⃣成交額: $260,210</CustomDescription>
        <CustomDescription>成交價: $1.3 </CustomDescription>
        <CustomDescription>5️⃣ASK_SIDE 🛍️</CustomDescription>
      </CustomBox>

      <CustomBox>
        <CustomDescription>版塊: Financial Services - Banks</CustomDescription>
      </CustomBox>

      <CustomBox>
        <CustomDescription>6️⃣類別: Repeated Hits Ascending Fill 🔄📈</CustomDescription>
        <CustomDescription>7️⃣當日成交量與上日未平倉張數比例: 5.17</CustomDescription>
        <CustomDescription>8️⃣ 📉OTM 比例: 9.1%</CustomDescription>
        <CustomDescription>————————————————————————</CustomDescription>
        <CustomDescription>9️⃣</CustomDescription>
        <CustomDescription>🆕 #100%新倉</CustomDescription>
        <CustomDescription>⚠️成交張數與上日未平倉張數比例:3.19</CustomDescription>
        <CustomDescription>📊業績: 2023-07-25 POSTMARKET</CustomDescription>
      </CustomBox>

      <CustomBox>
        <CustomDescription>成交日期: 2023-07-26</CustomDescription>
        <CustomDescription>成交時間: 03:58:36</CustomDescription>
      </CustomBox>

      <CustomBox>
        <CustomDescription>💥💥💥💥💥💥💥💥💥💥💥💥💥💥</CustomDescription>
        <CustomDescription>上面係個example mark咗9點要留意</CustomDescription>
      </CustomBox>

      {optionBotTutorial.map((tutorial, index) => {
        return (
          <CustomAccordion expanded={expanded === `panel${index + 1}`} onChange={handleChange(`panel${index + 1}`)} key={index + 1}>
            <CustomAccordionSummary aria-controls={`panel${index + 1}d-content`} id={`panel${index + 1}d-header`}>
              <AccordionHeader>{tutorial.subHeader}</AccordionHeader>
            </CustomAccordionSummary>
            <CustomAccordionDetails>
              {tutorial.descriptions.map((description, index) => {
                return description === "" ? (
                  <div key={index} style={{ paddingBottom: "20px" }}></div>
                ) : (
                  <CustomDescription key={index}>{description}</CustomDescription>
                );
              })}
            </CustomAccordionDetails>
          </CustomAccordion>
        );
      })}

      <SectionTitle>期權異動實時追蹤(美股)</SectionTitle>
      {singalFeatures.map((feature, index) => {
        return (
          <Container maxWidth="lg" component="main" sx={{ py: "50px" }} key={index}>
            {isBelowMd || index % 2 !== 0 ? (
              <Grid container spacing={3} alignItems="center" justifyContent="center" textAlign="center" color="white">
                <Grid xs={12} md={6} lg={6}>
                  {feature.subHeader && <SubHeader>{feature.subHeader}</SubHeader>}
                  {feature.descriptions.length !== 0 &&
                    feature.descriptions.map((description, index) => {
                      return <CustomDescription key={index}>{description}</CustomDescription>;
                    })}
                </Grid>

                <Grid xs={12} md={6} lg={6}>
                  {feature.image && (
                    <Container>
                      <img src={feature.image} alt={feature.imageAlt} style={{ width: "inherit" }} />
                    </Container>
                  )}
                </Grid>
              </Grid>
            ) : (
              <Grid container spacing={3} alignItems="center" justifyContent="center" textAlign="center" color="white">
                <Grid xs={12} md={6} lg={6}>
                  {feature.image && (
                    <Container>
                      <img src={feature.image} alt={feature.imageAlt} style={{ width: "inherit" }} />
                    </Container>
                  )}
                </Grid>
                <Grid xs={12} md={6} lg={6}>
                  {feature.subHeader && <SubHeader>{feature.subHeader}</SubHeader>}
                  {feature.descriptions.length !== 0 &&
                    feature.descriptions.map((description, index) => {
                      return <CustomDescription key={index}>{description}</CustomDescription>;
                    })}
                </Grid>
              </Grid>
            )}
          </Container>
        );
      })}
    </Container>
  );
}

export default OptionBotTutorial;
