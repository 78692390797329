import { Box, Container, useMediaQuery, useTheme } from "@mui/material";
import React from "react";
import { Controller, FormContainer, SubmitHandler, useForm } from "react-hook-form-mui";
import Grid from "@mui/material/Unstable_Grid2";
import { useAppDispatch, useAppSelector } from "../../stores/hook";
import { fetchRegister } from "../../stores/auth/thunk";
import { CustomTextField } from "../../styles/customTextField";
import CustomButtonYellowBg from "../../styles/CustomButtonYellowBg";
import SimpleBackdrop from "../../components/Common/SimpleBackdrop";
import { RootState } from "../../stores/store";

export interface Register {
  tgUserName: string;
  email: string;
  password: string;
  passwordConfirmation: string;
}

function Register() {
  const formContext = useForm<Register>();
  const {
    control,
    formState: { errors },
    watch,
  } = formContext;
  const { handleSubmit } = formContext;

  const dispatch = useAppDispatch();

  const onSubmit: SubmitHandler<Register> = async (data) => {
    dispatch(fetchRegister(data));
  };

  const isAuthLoading = useAppSelector((state: RootState) => state.loading.isAuthLoading);

  const theme = useTheme();
  const isAboveSm = useMediaQuery(theme.breakpoints.up("sm"));

  return (
    <Container maxWidth="lg" component="main" sx={{ color: "white" }}>
      <Box>
        <FormContainer formContext={formContext} handleSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={2} justifyContent="center">
            {isAboveSm && <Grid xs={12} sm={2} md={3} display="flex" justifyContent="left"></Grid>}
            <Grid xs={12} sm={8} md={6} display="flex" justifyContent="center" sx={{ fontSize: "30px", fontWeight: "700" }}>
              註冊
            </Grid>
            {isAboveSm && <Grid xs={12} sm={2} md={3} display="flex" justifyContent="left"></Grid>}

            {isAboveSm && <Grid xs={12} sm={2} md={3} display="flex" justifyContent="left"></Grid>}
            <Grid xs={12} sm={8} md={6} display="flex" justifyContent="left">
              <Controller
                control={control}
                name={"tgUserName"}
                rules={{
                  required: "Telegram用戶名稱必須填寫",
                }}
                defaultValue={""}
                render={({ field }) => {
                  return (
                    <CustomTextField
                      {...field}
                      error={errors.tgUserName && true}
                      helperText={errors.tgUserName && errors.tgUserName.message}
                      id="tgUserName"
                      label="Telegram用戶名稱 (即填寫@XXXXX，不需要加上@)"
                      variant="filled"
                      size="small"
                      fullWidth
                    />
                  );
                }}
              />
            </Grid>
            {isAboveSm && <Grid xs={12} sm={2} md={3} display="flex" justifyContent="left"></Grid>}

            {isAboveSm && <Grid xs={12} sm={2} md={3} display="flex" justifyContent="left"></Grid>}
            <Grid xs={12} sm={8} md={6} display="flex" justifyContent="left">
              <Controller
                control={control}
                name={"email"}
                rules={{
                  required: "電郵地址必須填寫",
                  pattern: {
                    value: /\S+@\S+\.\S+/,
                    message: "電郵地址格式不正確",
                  },
                }}
                defaultValue={""}
                render={({ field }) => {
                  return (
                    <CustomTextField
                      {...field}
                      error={errors.email && true}
                      helperText={errors.email && errors.email.message}
                      id="email"
                      label="電郵地址"
                      variant="filled"
                      size="small"
                      fullWidth
                    />
                  );
                }}
              />
            </Grid>
            {isAboveSm && <Grid xs={12} sm={2} md={3} display="flex" justifyContent="left"></Grid>}

            {isAboveSm && <Grid xs={12} sm={2} md={3} display="flex" justifyContent="left"></Grid>}
            <Grid xs={12} sm={8} md={6} display="flex" justifyContent="left">
              <Controller
                control={control}
                name={"password"}
                rules={{ required: "密碼必須填寫" }}
                defaultValue={""}
                render={({ field }) => {
                  return (
                    <CustomTextField
                      {...field}
                      error={errors.password && true}
                      helperText={errors.password && errors.password.message}
                      id="password"
                      label="密碼"
                      variant="filled"
                      size="small"
                      type="password"
                      fullWidth
                    />
                  );
                }}
              />
            </Grid>
            {isAboveSm && <Grid xs={12} sm={2} md={3} display="flex" justifyContent="left"></Grid>}

            {isAboveSm && <Grid xs={12} sm={2} md={3} display="flex" justifyContent="left"></Grid>}
            <Grid xs={12} sm={8} md={6} display="flex" justifyContent="left">
              <Controller
                control={control}
                name={"passwordConfirmation"}
                rules={{
                  required: "確認密碼必須填寫",
                  validate: (val: string) => {
                    if (watch("password") != val) {
                      return "與上述密碼不一致";
                    }
                  },
                }}
                defaultValue={""}
                render={({ field }) => {
                  return (
                    <CustomTextField
                      {...field}
                      error={errors.passwordConfirmation && true}
                      helperText={errors.passwordConfirmation && errors.passwordConfirmation.message}
                      id="passwordConfirmation"
                      label="確認密碼"
                      variant="filled"
                      size="small"
                      type="password"
                      fullWidth
                    />
                  );
                }}
              />
            </Grid>
            {isAboveSm && <Grid xs={12} sm={2} md={3} display="flex" justifyContent="left"></Grid>}

            {isAboveSm && <Grid xs={12} sm={2} md={3} display="flex" justifyContent="left"></Grid>}
            <Grid xs={12} sm={8} md={6} display="flex" justifyContent="center">
              <CustomButtonYellowBg type={"submit"} color={"primary"} variant="contained">
                註冊
              </CustomButtonYellowBg>
            </Grid>
            {isAboveSm && <Grid xs={12} sm={2} md={3} display="flex" justifyContent="left"></Grid>}
          </Grid>
          {/* <Grid container spacing={2} justifyContent="center">
            {isAboveSm && <Grid xs={12} sm={2} md={3} display="flex" justifyContent="left"></Grid>}
            <Grid xs={12} sm={8} md={6} display="flex" justifyContent="center" sx={{ fontSize: "30px", fontWeight: "700" }}>
              註冊
            </Grid>
            {isAboveSm && <Grid xs={12} sm={2} md={3} display="flex" justifyContent="left"></Grid>}

            {isAboveSm && <Grid xs={12} sm={2} md={3} display="flex" justifyContent="left"></Grid>}
            <Grid xs={12} sm={8} md={6} display="flex" justifyContent="left">
              <Controller
                control={control}
                name={"id"}
                rules={{
                  required: "會員號碼必須填寫",
                  // pattern: {
                  //   value: /d+/,
                  //   message: "請填寫正確會員號碼",
                  // },
                  // min: {
                  //   value: 1,
                  //   message: "請填寫正確會員號碼",
                  // },
                }}
                defaultValue={""}
                // defaultValue={"" as unknown as number}
                render={({ field }) => {
                  return (
                    // <CustomTextField
                    //   {...field}
                    //   error={errors.id && true}
                    //   helperText={errors.id && errors.id.message}
                    //   id="number"
                    //   label="會員號碼"
                    //   variant="filled"
                    //   size="small"
                    //   fullWidth
                    //   type="number"
                    //   inputProps={{ inputMode: "numeric", pattern: "[0-9]*", min: "0" }}
                    //   onKeyDown={(evt) => ["e", "E", "+", "-"].includes(evt.key) && evt.preventDefault()}
                    //   onChange={(e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
                    //     if (!isNaN(parseInt(e.target.value))) {
                    //       field.onChange(parseInt(e.target.value));
                    //     } else {
                    //       field.onChange("");
                    //     }
                    //   }}
                    // />
                    <CustomTextField
                      {...field}
                      error={errors.defaultPassword && true}
                      helperText={errors.defaultPassword && errors.defaultPassword.message}
                      id="number"
                      label="會員號碼"
                      variant="filled"
                      size="small"
                      fullWidth
                    />
                  );
                }}
              />
            </Grid>
            {isAboveSm && <Grid xs={12} sm={2} md={3} display="flex" justifyContent="left"></Grid>}

            {isAboveSm && <Grid xs={12} sm={2} md={3} display="flex" justifyContent="left"></Grid>}
            <Grid xs={12} sm={8} md={6} display="flex" justifyContent="left">
              <Controller
                control={control}
                name={"defaultPassword"}
                rules={{ required: "一次性啟動碼必須填寫" }}
                defaultValue={""}
                render={({ field }) => {
                  return (
                    <CustomTextField
                      {...field}
                      error={errors.defaultPassword && true}
                      helperText={errors.defaultPassword && errors.defaultPassword.message}
                      id="defaultPassword"
                      label="一次性啟動碼"
                      variant="filled"
                      size="small"
                      fullWidth
                    />
                  );
                }}
              />
            </Grid>
            {isAboveSm && <Grid xs={12} sm={2} md={3} display="flex" justifyContent="left"></Grid>}

            {isAboveSm && <Grid xs={12} sm={2} md={3} display="flex" justifyContent="left"></Grid>}
            <Grid xs={12} sm={8} md={6} display="flex" justifyContent="left">
              <Controller
                control={control}
                name={"tgFirstName"}
                rules={{
                  required: "Telegram用戶名稱必須填寫",
                }}
                defaultValue={""}
                render={({ field }) => {
                  return (
                    <CustomTextField
                      {...field}
                      error={errors.tgFirstName && true}
                      helperText={errors.tgFirstName && errors.tgFirstName.message}
                      id="tgFirstName"
                      label="Telegram用戶名稱"
                      variant="filled"
                      size="small"
                      fullWidth
                    />
                  );
                }}
              />
            </Grid>
            {isAboveSm && <Grid xs={12} sm={2} md={3} display="flex" justifyContent="left"></Grid>}

            {isAboveSm && <Grid xs={12} sm={2} md={3} display="flex" justifyContent="left"></Grid>}
            <Grid xs={12} sm={8} md={6} display="flex" justifyContent="left">
              <Controller
                control={control}
                name={"tgUserName"}
                rules={{
                  required: "Telegram用戶ID(username)必須填寫",
                }}
                defaultValue={""}
                render={({ field }) => {
                  return (
                    <CustomTextField
                      {...field}
                      error={errors.tgUserName && true}
                      helperText={errors.tgUserName && errors.tgUserName.message}
                      id="tgUserName"
                      label="Telegram用戶ID"
                      variant="filled"
                      size="small"
                      fullWidth
                    />
                  );
                }}
              />
            </Grid>
            {isAboveSm && <Grid xs={12} sm={2} md={3} display="flex" justifyContent="left"></Grid>}

            {isAboveSm && <Grid xs={12} sm={2} md={3} display="flex" justifyContent="left"></Grid>}
            <Grid xs={12} sm={8} md={6} display="flex" justifyContent="left">
              <Controller
                control={control}
                name={"email"}
                rules={{
                  required: "電郵地址必須填寫",
                  pattern: {
                    value: /\S+@\S+\.\S+/,
                    message: "電郵地址格式不正確",
                  },
                }}
                defaultValue={""}
                render={({ field }) => {
                  return (
                    <CustomTextField
                      {...field}
                      error={errors.email && true}
                      helperText={errors.email && errors.email.message}
                      id="email"
                      label="電郵地址"
                      variant="filled"
                      size="small"
                      fullWidth
                    />
                  );
                }}
              />
            </Grid>
            {isAboveSm && <Grid xs={12} sm={2} md={3} display="flex" justifyContent="left"></Grid>}

            {isAboveSm && <Grid xs={12} sm={2} md={3} display="flex" justifyContent="left"></Grid>}
            <Grid xs={12} sm={8} md={6} display="flex" justifyContent="left">
              <Controller
                control={control}
                name={"password"}
                rules={{ required: "密碼必須填寫" }}
                defaultValue={""}
                render={({ field }) => {
                  return (
                    <CustomTextField
                      {...field}
                      error={errors.password && true}
                      helperText={errors.password && errors.password.message}
                      id="password"
                      label="密碼"
                      variant="filled"
                      size="small"
                      type="password"
                      fullWidth
                    />
                  );
                }}
              />
            </Grid>
            {isAboveSm && <Grid xs={12} sm={2} md={3} display="flex" justifyContent="left"></Grid>}

            {isAboveSm && <Grid xs={12} sm={2} md={3} display="flex" justifyContent="left"></Grid>}
            <Grid xs={12} sm={8} md={6} display="flex" justifyContent="left">
              <Controller
                control={control}
                name={"passwordConfirmation"}
                rules={{
                  required: "確認密碼必須填寫",
                  validate: (val: string) => {
                    if (watch("password") != val) {
                      return "與上述密碼不一致";
                    }
                  },
                }}
                defaultValue={""}
                render={({ field }) => {
                  return (
                    <CustomTextField
                      {...field}
                      error={errors.passwordConfirmation && true}
                      helperText={errors.passwordConfirmation && errors.passwordConfirmation.message}
                      id="passwordConfirmation"
                      label="確認密碼"
                      variant="filled"
                      size="small"
                      type="password"
                      fullWidth
                    />
                  );
                }}
              />
            </Grid>
            {isAboveSm && <Grid xs={12} sm={2} md={3} display="flex" justifyContent="left"></Grid>}

            {isAboveSm && <Grid xs={12} sm={2} md={3} display="flex" justifyContent="left"></Grid>}
            <Grid xs={12} sm={8} md={6} display="flex" justifyContent="center">
              <Button
                type={"submit"}
                color={"primary"}
                variant="contained"
                sx={{
                  backgroundColor: "#F1C024",
                  color: "rgb(26, 25, 46)",
                  fontWeight: "bolder",
                  borderRadius: "10px",
                  "&:hover": {
                    backgroundColor: "rgb(26, 25, 46)",
                    color: "white",
                  },
                }}
              >
                註冊
              </Button>
            </Grid>
            {isAboveSm && <Grid xs={12} sm={2} md={3} display="flex" justifyContent="left"></Grid>}
          </Grid> */}
        </FormContainer>
      </Box>
      {isAuthLoading && <SimpleBackdrop />}
    </Container>
  );
}

export default Register;
