import { Container } from "@mui/material";
import React from "react";
import { WhiteSectionTitle } from "../utils/customStyle";
import useWindowDimensions from "../hooks/useWindowDimensions";

function HKSignal() {
  //   const ref = React.useRef() as React.MutableRefObject<HTMLIFrameElement>;
  //   const [height, setHeight] = React.useState("0px");
  //   const onLoad = () => {
  //     setHeight(ref.current.contentWindow?.document.body.scrollHeight + "px");
  //   };

  const { height } = useWindowDimensions();

  return (
    <Container maxWidth="xl" component="main">
      <WhiteSectionTitle>HK大股list</WhiteSectionTitle>
      <iframe
        // ref={ref}
        // onLoad={onLoad}
        src="https://docs.google.com/spreadsheets/d/1xmCsOG7aU0oJ30sJRelxKvIKca1Z_IaSlc5ObZd1gu0/edit#gid=573519281"
        width="100%"
        height={height - 350}
      ></iframe>
    </Container>
  );
}

export default HKSignal;
