import styled from "@emotion/styled";
import { Typography, Container } from "@mui/material";
import React from "react";
import { WhiteSectionTitle } from "../utils/customStyle";

function ContactUs() {
  const CustomDescription = styled(Typography)({
    color: "white",
    marginLeft: "10px",
  }) as typeof Typography;

  return (
    <Container maxWidth="lg" component="main">
      <WhiteSectionTitle>聯絡我們</WhiteSectionTitle>
      <CustomDescription>💛如何加入我哋Topaz Family? 💛</CustomDescription>
      <CustomDescription>首先你要準備一張幫襯咗黃店嘅收據</CustomDescription>
      <CustomDescription>我哋只歡迎同路人 </CustomDescription>
      <CustomDescription>道不同不相為謀 你有錢都冇用🙅🏻‍♂️</CustomDescription>
      <CustomDescription>準備好之後就可以搵靚女Nicole妹妹報名啦👇🏻</CustomDescription>
    </Container>
  );
}

export default ContactUs;
