import { Container, CardMedia } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import React from "react";
import CustomDescription from "../styles/customDescription";
import SectionTitle from "../styles/sectionTitle";
import SubHeader from "../styles/subHeader";
import CustomBox from "../styles/CustomBox";
import CustomAccordion from "../styles/CustomAccordion";
import CustomAccordionDetails from "../styles/CustomAccordionDetails";
import AccordionHeader from "../styles/CustomAccordionHeader";
import CustomAccordionSummary from "../styles/CustomAccordionSummary";
import { WhiteSectionTitle } from "../utils/customStyle";

function OptionTutorial() {
  const [expanded, setExpanded] = React.useState<string | false>(false);

  const handleChange = (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
    setExpanded(newExpanded ? panel : false);
  };

  return (
    <Container maxWidth="lg" component="main">
      <SectionTitle>期權教學區</SectionTitle>
      <Container maxWidth="lg" component="main" sx={{ paddingTop: "50px", paddingBottom: "70px" }}>
        <Grid container spacing={3} alignItems="center" justifyContent="center" textAlign="center" color="white">
          <Grid xs={12} md={6} lg={6}>
            <SubHeader>期權工作坊 Live課堂重溫</SubHeader>
            <CustomDescription>SMH期權教室 教你由0開始玩轉美股期權</CustomDescription>
          </Grid>
          <Grid xs={12} md={6} lg={6}>
            <CardMedia
              sx={{ height: "360px", borderWidth: 0, boxShadow: "0px 0px 43px 15px rgba(255, 255, 255, 0.5)" }}
              component="iframe"
              src="https://www.youtube.com/embed/Ws_WFThLNY0?si=0xJiY3QA2OFc3JAd"
              allowFullScreen
            ></CardMedia>
          </Grid>
        </Grid>
      </Container>

      <CustomAccordion expanded={expanded === "panel1"} onChange={handleChange("panel1")}>
        <CustomAccordionSummary aria-controls="panel1d-content" id="panel1d-header">
          <AccordionHeader>1. 先睇信號</AccordionHeader>
        </CustomAccordionSummary>
        <CustomAccordionDetails>
          <CustomBox>
            <CustomDescription>咁係咪有信號就call/put得呢？唔係</CustomDescription>
            <CustomDescription>首先會揀高分嘅信號</CustomDescription>
            <CustomDescription>然後揀板塊 判斷個板塊有無可能係未來幾日因政策/周期性/指數信號而導致成交上升</CustomDescription>
            <CustomDescription>跟住睇信號嘅由B$去到2T有幾多% </CustomDescription>
            <CustomDescription>一般細過10%先做 </CustomDescription>
            <CustomDescription>因為我通常做末日or下星期到期</CustomDescription>
            <CustomDescription>差得遠嘅話到價可能性低 會變廢紙</CustomDescription>
          </CustomBox>

          <CustomBox>
            <CustomDescription>另外有時佢明明未觸發買入/跌穿熊口我就開始call/put 咁其實係賭緊佢會觸發/穿熊口 </CustomDescription>
            <CustomDescription>更多嘅賭博成份係到 所以會留半注係真係觸發/穿熊口再加 如果賭錯邊 嗰半注就化灰豪比佢(好似上星期隻aapl put咁)</CustomDescription>
          </CustomBox>
        </CustomAccordionDetails>
      </CustomAccordion>

      <CustomAccordion expanded={expanded === "panel2"} onChange={handleChange("panel2")}>
        <CustomAccordionSummary aria-controls="panel2d-content" id="panel2d-header">
          <AccordionHeader>2. 睇IV</AccordionHeader>
        </CustomAccordionSummary>
        <CustomAccordionDetails>
          <CustomBox>
            <CustomDescription>JW教落 大家要睇IV水平合唔合理</CustomDescription>
            <CustomDescription>如果高過平均值好多 </CustomDescription>
            <CustomDescription>比你賭啱方向 只要升/跌得唔夠急都要輸錢</CustomDescription>
            <CustomDescription>如果想day trade唔過夜就可以唔考慮小啲呢一點 </CustomDescription>
            <CustomDescription>呢兩日好多股IV都拉得太高 如果入左場 議息後IV冧落嚟 啱方向都無乜肉食 所以呢幾日都無點買嘢 </CustomDescription>
          </CustomBox>
        </CustomAccordionDetails>
      </CustomAccordion>

      <CustomAccordion expanded={expanded === "panel3"} onChange={handleChange("panel3")}>
        <CustomAccordionSummary aria-controls="panel3d-content" id="panel3d-header">
          <AccordionHeader>3. 揀價位</AccordionHeader>
        </CustomAccordionSummary>
        <CustomAccordionDetails>
          <CustomBox>
            <CustomDescription>呢part都好緊要 因為你要決定你今次落注嘅策略係咩 求其作啲名</CustomDescription>
            <CustomDescription>如果做末日 你揀到去2T-3T中間call </CustomDescription>
            <CustomDescription>咁你要預咗係唔洗做止蝕 因為係牆紙系</CustomDescription>
            <CustomDescription>因為咁樣係=octuple or nothing </CustomDescription>
            <CustomDescription>好似之前隻appl call咁 到期前兩日仲call多成5% $0.06 一晚爆到$1.20 </CustomDescription>
            <CustomDescription>但如果唔爆嘅嗰$0.06就豪比佢</CustomDescription>
            <CustomDescription>如果你揀得近嘅 當然要做止蝕 因為無咁易變牆紙 我會覺得係=triple or half </CustomDescription>
            <CustomDescription>-50%止</CustomDescription>
          </CustomBox>
        </CustomAccordionDetails>
      </CustomAccordion>

      <CustomAccordion expanded={expanded === "panel4"} onChange={handleChange("panel4")}>
        <CustomAccordionSummary aria-controls="panel4d-content" id="panel4d-header">
          <AccordionHeader>4. 成交/買賣盤</AccordionHeader>
        </CustomAccordionSummary>
        <CustomAccordionDetails>
          <CustomBox>
            <CustomDescription>大家睇得多會發現 有啲options抵call/put嘅</CustomDescription>
            <CustomDescription>但成交一晚得幾十張 買賣盤可以差咗30%</CustomDescription>
            <CustomDescription>呢類會少揀 除非真係好抵call 好似隻PM咁</CustomDescription>
          </CustomBox>
        </CustomAccordionDetails>
      </CustomAccordion>

      <CustomAccordion expanded={expanded === "panel5"} onChange={handleChange("panel5")}>
        <CustomAccordionSummary aria-controls="panel5d-content" id="panel5d-header">
          <AccordionHeader>5. 注碼</AccordionHeader>
        </CustomAccordionSummary>
        <CustomAccordionDetails>
          <CustomBox>
            <CustomDescription>5. 注碼</CustomDescription>
            <CustomDescription>講幾多次都係嗰句「均注」</CustomDescription>
            <CustomDescription>但如果你玩Octuple or nothing嘅就建議再細注啲 </CustomDescription>
            <CustomDescription>同埋唔好因為上注輸哂or上注贏幾倍 搞到下一注就衝大注 </CustomDescription>
            <CustomDescription>再有信心都唔好多過均注一倍咁落</CustomDescription>
          </CustomBox>
        </CustomAccordionDetails>
      </CustomAccordion>

      <CustomAccordion expanded={expanded === "panel6"} onChange={handleChange("panel6")}>
        <CustomAccordionSummary aria-controls="panel6d-content" id="panel6d-header">
          <AccordionHeader>6. 止賺</AccordionHeader>
        </CustomAccordionSummary>
        <CustomAccordionDetails>
          <CustomBox>
            <CustomDescription>呢個真係睇胃口 如果你覺得一倍心足咪賣左佢 </CustomDescription>
            <CustomDescription>我成日share嘅圖都好似好柒咁半山腰就開始逐啲食 但咁樣其實係保障你自己嘅利潤 </CustomDescription>
            <CustomDescription>萬一個半山腰其實山頂嚟 咁回落去你再食賣都唔會輸錢</CustomDescription>
          </CustomBox>
        </CustomAccordionDetails>
      </CustomAccordion>

      <CustomAccordion expanded={expanded === "panel7"} onChange={handleChange("panel7")}>
        <CustomAccordionSummary aria-controls="panel7d-content" id="panel7d-header">
          <AccordionHeader>7. 加注/溝貨</AccordionHeader>
        </CustomAccordionSummary>
        <CustomAccordionDetails>
          <CustomBox>
            <CustomDescription>個人覺得溝貨就盡量唔好發生係期權身上</CustomDescription>
            <CustomDescription>當你見佢跌開始唸溝貨嘅時候就要留意啦 </CustomDescription>
            <CustomDescription>因為呢個時候係應該斬</CustomDescription>
            <CustomDescription>如果佢係咁升 你覺得自己買得唔夠 加注係可以嘅 如果你第一次買得抵 </CustomDescription>
          </CustomBox>
        </CustomAccordionDetails>
      </CustomAccordion>

      <CustomAccordion expanded={expanded === "panel8"} onChange={handleChange("panel8")}>
        <CustomAccordionSummary aria-controls="panel8d-content" id="panel8d-header">
          <AccordionHeader>8. 即市資金流</AccordionHeader>
        </CustomAccordionSummary>
        <CustomAccordionDetails>
          <CustomBox>
            <CustomDescription>呢個唔係好識教 通常好得閒先會睇</CustomDescription>
            <CustomDescription>會用係即日超短線call/put 例如8分鐘拎佢+60%走人</CustomDescription>
          </CustomBox>
        </CustomAccordionDetails>
      </CustomAccordion>

      <WhiteSectionTitle>揀期權策略</WhiteSectionTitle>
      <CustomBox>
        <CustomDescription>一般揀期權可以有兩種策略，第一種係有stop loss，第二種係冇stop loss預咗all or nothing</CustomDescription>
        <CustomDescription>我多數會用all or nothing 既方式做，好處唔洗睇盤止蝕輸左就打</CustomDescription>
      </CustomBox>

      <CustomBox>
        <CustomDescription>Rules:</CustomDescription>
        <CustomDescription> </CustomDescription>
        <CustomDescription>除非係痴線股好似GME果d，其他股票我一般50IV以下先會買，因為IV高option愈貴</CustomDescription>
        <CustomDescription>少過300 未平倉合約唔好買</CustomDescription>
        <CustomDescription>揀2-3星期 到期option，太長option會貴，太短會到唔到價變廢紙</CustomDescription>
        <CustomDescription>行使價會用信號2T-3T之間，同埋太高會唔到，太低會太貴</CustomDescription>
        <CustomDescription>
          Option 價要係正股價1% 或以下，例如正股$50 我只會買0.5 或以下既option，因為愈貴就愈少槓桿，一般跟我上面既條件揀option只會值0.5%-1%
        </CustomDescription>
        <CustomDescription>有財報另一種玩法，再講</CustomDescription>
      </CustomBox>

      <CustomBox>
        <CustomDescription>跟住你要定左你呢個trade最多可以輸幾多錢，去決定入幾多張</CustomDescription>
        <CustomDescription>
          一般你預升到2T可以賺到 300-400%，視乎佢幾快升到同你買果陣隻option IV幾高，愈高就賺愈少，當你每隻買$100，就會賺$300-$400
        </CustomDescription>
        <CustomDescription>建議平均分注咁你每次3隻中一隻都會賺錢</CustomDescription>
        <CustomDescription>呢個方法嘅好處係當大家本金唔夠跟晒咁多隻信號嘅時候就可以分散落住</CustomDescription>
      </CustomBox>
    </Container>
  );
}

export default OptionTutorial;
