import React from "react";
import { Container } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import SectionTitle from "../styles/sectionTitle";
import CustomDescription from "../styles/customDescription";
import SubHeader from "../styles/subHeader";
import indicatorIntro1 from "../images/indicatorIntro/indicatorIntro1.png";
import indicatorIntro2 from "../images/indicatorIntro/indicatorIntro2.png";

function IndicatorIntro() {
  return (
    <>
      <Container maxWidth="lg" component="main">
        <SectionTitle>短炒指標教學</SectionTitle>
        <Container maxWidth="lg" component="main" sx={{ py: "50px" }}>
          <Grid container spacing={3} alignItems="center" justifyContent="center" textAlign="center" color="white">
            <Grid xs={12} md={6} lg={6}>
              <SubHeader>每日股市ATM㩒錢</SubHeader>
              <CustomDescription>有沒有想過每日只需要花開市一小時作Daytrade就可以幫自己多出一份糧？</CustomDescription>
              <CustomDescription>
                SMH Admin針對港美股市場專門研發Daytrade指標組合，無須事前度位，開市即睇即Trade，快狠準在股票市場Daytrade「㩒錢」
              </CustomDescription>
            </Grid>
            <Grid xs={12} md={6} lg={6}>
              <Container>
                <img src={indicatorIntro1} alt="indicatorIntro1" style={{ width: "inherit" }} />
              </Container>
            </Grid>
          </Grid>
          <Grid container spacing={3} alignItems="center" justifyContent="center" textAlign="center" color="white">
            <Grid xs={12} md={6} lg={6}>
              <Container>
                <img src={indicatorIntro2} alt="indicatorIntro2" style={{ width: "inherit" }} />
              </Container>
            </Grid>
            <Grid xs={12} md={6} lg={6}>
              <SubHeader>教學齊全 跨市場應用</SubHeader>
              <CustomDescription>
                由SMH Admin親自撰寫並錄制教學影片，方便隨時重溫，更精選市場實例作參考，謝絕紙上談兵；學成後可以應用於各大市場，畢生受用。
              </CustomDescription>
            </Grid>
          </Grid>
        </Container>
      </Container>
    </>
  );
}

export default IndicatorIntro;
