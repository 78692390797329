import React from "react";
import { RootState } from "../stores/store";
import { useAppSelector } from "../stores/hook";
import { Navigate } from "react-router-dom";
import RoleId from "../enums/roleId";

interface Props {
  children: JSX.Element;
  path?: string;
}

export const AdminRoute: React.FC<Props> = ({ children, path }) => {
  const isAuth = useAppSelector((state: RootState) => state.auth.isAuth);
  const isLoading = useAppSelector((state: RootState) => state.loading.isLoading);
  const roleId = useAppSelector((state: RootState) => state.auth.roleId);

  if (isLoading && !isAuth) {
    <div style={{ color: "white" }}>Loading...</div>;
  } else if (!isLoading && isAuth && roleId === RoleId.ADMIN) {
    return children;
  } else if (!isLoading && !isAuth && roleId !== RoleId.ADMIN) {
    return <Navigate to={"/"} replace state={{ path }} />;
  }
};

export default AdminRoute;
