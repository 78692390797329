import styled from "@emotion/styled";
import { TextField } from "@mui/material";

export const CustomTextField = styled(TextField)({
  "& .MuiInputBase-root": {
    background: "white",
    color: "black",
  },
  "& .MuiInputBase-root:after": {
    borderBottom: "2px solid #F1C024 !important",
  },
  "& .Mui-focused.MuiFormLabel-root": {
    color: "rgb(26, 25, 46) !important",
  },
  "& .MuiFilledInput-root:hover": {
    backgroundColor: "white",
    // Reset on touch devices, it doesn't add specificity
    "@media (hover: none)": {
      backgroundColor: "white",
    },
  },
  "& .MuiFilledInput-root.Mui-focused": {
    backgroundColor: "rgb(250, 241, 232)",
  },

  // "& .MuiInputBase-root": {
  //   background: "white",
  // },
  // "& .Mui-focused.MuiFormLabel-root": {
  //   color: "#F1C024 !important",
  // },
  // "& .Mui-error.MuiFormLabel-root": {
  //   color: "#d32f2f !important",
  // },
  // "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
  //   borderColor: "#F1C024 !important",
  // },
  // "& .Mui-error .MuiOutlinedInput-notchedOutline": {
  //   borderColor: "#d32f2f !important",
  // },
});
