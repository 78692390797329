import { Container } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import React from "react";
import CustomDescription from "../styles/customDescription";
import SectionTitle from "../styles/sectionTitle";
import SubHeader from "../styles/subHeader";
import optionBotIntro1 from "../images/optionBotIntro/optionBotIntro1.png";
import optionBotIntro2 from "../images/optionBotIntro/optionBotIntro2.png";

function OptionBotIntro() {
  return (
    <>
      <Container maxWidth="lg" component="main">
        <SectionTitle>大戶期權異動Bot</SectionTitle>
        <Container maxWidth="lg" component="main" sx={{ py: "50px" }}>
          <Grid container spacing={3} alignItems="center" justifyContent="center" textAlign="center" color="white">
            <Grid xs={12} md={6} lg={6}>
              <SubHeader>洞悉大戶部署</SubHeader>
              <CustomDescription>
                Smart Money
                House除了有獨家AI系統分析聰明錢取態外，更設有大戶期權異動Bot，0時差於美股開市時段捕捉大戶利用期權進行之大額異動交易，從中發掘炒賣機會。
              </CustomDescription>
            </Grid>
            <Grid xs={12} md={6} lg={6}>
              <Container>
                <img src={optionBotIntro1} alt="optionBotIntro1" style={{ width: "inherit" }} />
              </Container>
            </Grid>
          </Grid>
          <Grid container spacing={3} alignItems="center" justifyContent="center" textAlign="center" color="white">
            <Grid xs={12} md={6} lg={6}>
              <Container>
                <img src={optionBotIntro2} alt="optionBotIntro2" style={{ width: "inherit" }} />
              </Container>
            </Grid>
            <Grid xs={12} md={6} lg={6}>
              <SubHeader>Admin協助篩選值博機會</SubHeader>
              <CustomDescription>
                每日Bot提供之大戶期權異動數量可多可少，SMH專業Admin幫到你！幫你人手分析及篩選值博炒賣機會，令你更輕鬆地緊跟大戶步伐「刀仔鋸大樹」！
              </CustomDescription>
            </Grid>
          </Grid>
        </Container>
      </Container>
    </>
  );
}

export default OptionBotIntro;
