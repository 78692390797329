import { Modal, Box, Typography, Button, styled, useMediaQuery, useTheme } from "@mui/material";
import React, { useEffect } from "react";
import { useAppSelector } from "../../stores/hook";
import { RootState } from "../../stores/store";
import CancelIcon from "@mui/icons-material/Cancel";
import Grid from "@mui/material/Unstable_Grid2";
// import { Snackbar, Alert } from "@mui/material";

const belowSmStyle = {
  position: "absolute" as const,
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 300,
  bgcolor: "rgb(245, 245, 245)",
  border: "2px solid #000",
  borderRadius: "10px",
  boxShadow: 24,
  p: 2,
  textAlign: "center",
};

const belowMdStyle = {
  position: "absolute" as const,
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 350,
  bgcolor: "rgb(245, 245, 245)",
  border: "2px solid #000",
  borderRadius: "10px",
  boxShadow: 24,
  p: 2,
  textAlign: "center",
};

const style = {
  position: "absolute" as const,
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "rgb(245, 245, 245)",
  border: "2px solid #000",
  borderRadius: "10px",
  boxShadow: 24,
  p: 2,
  textAlign: "center",
};

function ErrorHandling() {
  const CustomButton = styled(Button)({
    // your custom styles go here
    my: 1,
    background: "rgb(252,87,100)",
    color: "white",
    "&:hover": {
      color: "white",
      backgroundColor: "rgb(252,87,100)",
      boxShadow:
        "box-shadow: rgba(52, 71, 103, 0.15) 0rem 0.1875rem 0.1875rem 0rem, rgba(52, 71, 103, 0.2) 0rem 0.1875rem 0.0625rem -0.125rem, rgba(52, 71, 103, 0.15) 0rem 0.0625rem 0.3125rem 0rem",
    },
  }) as typeof Button;

  const ContactAdminButton = styled(Button)({
    // your custom styles go here
    my: 1,
    background: "rgb(108,116,126)",
    color: "white",
    "&:hover": {
      color: "white",
      backgroundColor: "rgb(108,116,126)",
      boxShadow:
        "box-shadow: rgba(52, 71, 103, 0.15) 0rem 0.1875rem 0.1875rem 0rem, rgba(52, 71, 103, 0.2) 0rem 0.1875rem 0.0625rem -0.125rem, rgba(52, 71, 103, 0.15) 0rem 0.0625rem 0.3125rem 0rem",
    },
  }) as typeof Button;

  // useEffect(() => {
  //   if (isError) {
  //     setOpen(true);
  //   }
  // }, [isError]);

  // const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
  //   if (reason === "clickaway") {
  //     return;
  //   }
  //   setOpen(false);
  // };

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const isError = useAppSelector((state: RootState) => state.error.isError);
  const errorStatus = useAppSelector((state: RootState) => state.error.status);
  const errorCode = useAppSelector((state: RootState) => state.error.code);
  const errorMessage = useAppSelector((state: RootState) => state.error.message);
  const errorExtras = useAppSelector((state: RootState) => state.error.extras);

  useEffect(() => {
    if (isError) {
      handleOpen();
    }
  }, [isError]);

  const theme = useTheme();
  const isBelowSm = useMediaQuery(theme.breakpoints.down("sm"));
  const isBelowMd = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <>
      {/* {errorMessage && (
        <Snackbar open={open} autoHideDuration={3000} onClose={handleClose}>
          <Alert onClose={handleClose} severity="error" sx={{ width: "100%" }}>
            {errorExtras !== "" ? errorMessage + " - " + errorExtras : errorMessage}
          </Alert>
        </Snackbar>
      )} */}
      {errorMessage && (
        <div>
          <Modal open={open} onClose={handleClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
            <Box sx={isBelowSm ? belowSmStyle : isBelowMd ? belowMdStyle : style}>
              <Box sx={{ maxHeight: isBelowSm ? "300px" : isBelowMd ? "350px" : "400px", overflow: "auto", mb: 2 }}>
                <Typography sx={{ mt: 1 }}>
                  <CancelIcon sx={{ fontSize: isBelowSm ? "80px" : isBelowMd ? "90px" : "100px", color: "rgb(252,87,100)" }} />
                </Typography>

                <Typography
                  id="modal-modal-title"
                  sx={{ my: 2, fontWeight: 700, fontSize: isBelowSm ? "20px" : isBelowMd ? "30px" : "40px", color: "rgb(70, 70, 70)" }}
                >
                  抱歉，發現錯誤!
                </Typography>

                <Typography sx={{ mt: 1, mb: 2, fontWeight: 500, fontSize: isBelowSm ? "12px" : isBelowMd ? "14px" : "16px", color: "rgb(70, 70, 70)" }}>
                  {errorMessage}
                </Typography>

                {errorExtras && (
                  <Box sx={{ mt: 1, mb: 2 }}>
                    {Array.isArray(errorExtras) &&
                      errorExtras.length !== 0 &&
                      errorExtras.map((extra, index) => (
                        <Typography
                          key={index}
                          sx={{
                            wordWrap: "break-word",
                            mb: 1,
                            fontWeight: 500,
                            fontSize: "12px",
                            color: "rgb(70, 70, 70)",
                          }}
                        >
                          {extra}
                        </Typography>
                      ))}
                  </Box>
                )}

                <Typography sx={{ mt: 1, mb: 1, fontWeight: 500, fontSize: "12px", color: "rgb(70, 70, 70)" }}>如需協助，請與管理員聯絡。</Typography>

                <Typography id="modal-modal-description" sx={{ color: "rgb(70, 70, 70)", fontSize: "10px", textAlign: "center", mb: 2 }}>
                  錯誤代碼: {errorStatus} - {errorCode}
                </Typography>
              </Box>

              <Grid container spacing={2}>
                <Grid xs={12} md={6} lg={6}>
                  <ContactAdminButton variant="contained" fullWidth href="https://t.me/SMH_NCO" target="_blank" onClick={handleClose}>
                    聯絡管理員
                  </ContactAdminButton>
                </Grid>
                <Grid xs={12} md={6} lg={6}>
                  <CustomButton variant="contained" fullWidth onClick={handleClose}>
                    再試一次
                  </CustomButton>
                </Grid>
              </Grid>
            </Box>
          </Modal>
        </div>
      )}
    </>
    // <Snackbar open={open} autoHideDuration={3000} onClose={handleClose}>
    //   <Alert onClose={handleClose} severity="error" sx={{ width: "100%" }}>
    //     {errorExtras !== "" ? errorMessage + " - " + errorExtras : errorMessage}
    //   </Alert>
    // </Snackbar>
  );
}

export default ErrorHandling;
