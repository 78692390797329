import styled from "@emotion/styled";
import { Button } from "@mui/material";

const CustomButtonYellowBg = styled(Button)({
  backgroundColor: "#F1C024",
  color: "rgb(26, 25, 46)",
  fontWeight: "bolder",
  borderRadius: "10px",
  "&:hover": {
    backgroundColor: "#F1C024",
    color: "rgb(26, 25, 46)",
    boxShadow: "1px 1px 10px 0px rgba(255,255,255,0.5)",
  },
});

export default CustomButtonYellowBg;
