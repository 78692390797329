import styled from "@emotion/styled";
import { Typography, Container, Link } from "@mui/material";
import React from "react";

import CustomBox from "../../styles/CustomBox";
import { WhiteSectionTitle } from "../../utils/customStyle";

function OtherChannelIntro() {
  // { title: "港股", path: "", href: "https://t.me/smartmoneyhouse_hkmarket" },
  // { title: "美股", path: "", href: "https://t.me/smartmoneyhouse_usmarket" },
  // { title: "CFD", path: "", href: "https://linktr.ee/smart_money_house" },

  const CustomDescription = styled(Typography)({
    color: "white",
    // marginLeft: "10px",
  }) as typeof Typography;

  return (
    <Container maxWidth="lg" component="main" sx={{ color: "white" }}>
      <WhiteSectionTitle>其他頻道連結</WhiteSectionTitle>

      <CustomBox>
        <CustomDescription>
          港股免費頻道 (Telegram):
          <Link sx={{ ml: 1 }} href="https://t.me/smartmoneyhouse_hkmarket">
            https://t.me/smartmoneyhouse_hkmarket
          </Link>
        </CustomDescription>
      </CustomBox>

      <CustomBox>
        <CustomDescription>
          美股免費頻道（Telegram）：
          <Link sx={{ ml: 1 }} href="https://t.me/smartmoneyhouse_usmarket">
            https://t.me/smartmoneyhouse_usmarket
          </Link>
        </CustomDescription>
      </CustomBox>

      <CustomBox>
        <CustomDescription>
          市場新聞Bot (繁體＋Forward from 金十APP)：
          <Link sx={{ ml: 1 }} href="https://t.me/+CjjwSMH__M1hOTBl">
            https://t.me/+CjjwSMH__M1hOTBl
          </Link>
        </CustomDescription>
      </CustomBox>
    </Container>
  );
}

export default OtherChannelIntro;
