import React from "react";
import { Box, Container } from "@mui/material";
import NavBar from "./components/Common/NavBar";
import { Outlet } from "react-router-dom";
import Footer from "./components/Common/Footer";
import ErrorHandling from "./components/Error/ErrorHandling";
import { useAppSelector } from "./stores/hook";
import { RootState } from "./stores/store";
import SuccessHandling from "./components/Success/SuccessHandling";
import SimpleBackdrop from "./components/Common/SimpleBackdrop";

function Root() {
  const isLoading = useAppSelector((state: RootState) => state.loading.isLoading);

  return (
    <Box
      width="100%"
      sx={{ display: "flex", flexDirection: "column", justifyContent: "space-between", backgroundColor: "rgb(26, 25, 46)", minHeight: "100vh" }}
      // style={{
      // backgroundColor: "rgb(26, 25, 46)",
      // backgroundImage: "radial-gradient(at center left, #FFFFFF6E 0%, #FFFFFF00 40%)",
      // backgroundImage: `linear-gradient(to left, rgba(20,20,20,0) 0%, rgba(20,20,20,0.7)), url(${backgroundImage})`,
      // backgroundSize: "cover",
      // backgroundPosition: "center",
      // }}
    >
      <Box>
        <Container maxWidth="lg">
          <NavBar />
        </Container>
        {!isLoading && <Outlet />}
        {isLoading && <SimpleBackdrop />}
        {!isLoading && <ErrorHandling />}
        {!isLoading && <SuccessHandling />}
      </Box>

      <Container maxWidth="lg" component="footer" sx={{ paddingTop: "25px", paddingBottom: "50px" }}>
        <Footer />
      </Container>
    </Box>
  );
}

export default Root;
