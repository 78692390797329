import { Box, Container, useMediaQuery, useTheme } from "@mui/material";
import React, { useEffect } from "react";
import { Controller, FormContainer, SubmitHandler, useForm } from "react-hook-form-mui";
import Grid from "@mui/material/Unstable_Grid2";
import { useAppDispatch, useAppSelector } from "../../stores/hook";
import { RootState } from "../../stores/store";
import { useNavigate } from "react-router-dom";
import { CustomTextField } from "../../styles/customTextField";
import { forgotPassword } from "../../api/auth.api";
import { AxiosError } from "axios";
import { clearError, createError } from "../../stores/error/errorSlice";
import { startLoading, doneLoading } from "../../stores/loading/loadingSlice";
import { createSuccessMsg } from "../../stores/success/successSlice";
import CustomButtonYellowBg from "../../styles/CustomButtonYellowBg";

interface ForgotPassword {
  tgUserName: string;
  email: string;
  password: string;
  passwordConfirmation: string;
}

function ForgotPassword() {
  const formContext = useForm<ForgotPassword>();
  const {
    control,
    formState: { errors },
    watch,
  } = formContext;
  const { handleSubmit } = formContext;
  const dispatch = useAppDispatch();

  const onSubmit: SubmitHandler<ForgotPassword> = async (data) => {
    try {
      dispatch(clearError());
      dispatch(startLoading());
      const result = await forgotPassword(data);
      if (result.success) {
        dispatch(
          createSuccessMsg({
            successMessage: "修改密碼成功!",
            buttonMessage: "前往登入頁面",
            buttonUrl: (process.env.REACT_APP_SERVER as string) + "/login",
          })
        );
      }

      dispatch(doneLoading());
    } catch (error) {
      dispatch(createError(error as AxiosError));
      dispatch(doneLoading());
    }
  };

  const isAuth = useAppSelector((state: RootState) => state.auth.isAuth);

  const navigate = useNavigate();

  useEffect(() => {
    if (isAuth) {
      navigate("/my_account");
    }
  }, [isAuth]);

  const theme = useTheme();
  const isAboveSm = useMediaQuery(theme.breakpoints.up("sm"));

  return (
    <Container maxWidth="lg" component="main" sx={{ color: "white" }}>
      <Box>
        <FormContainer formContext={formContext} handleSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={2} justifyContent="center">
            {isAboveSm && <Grid xs={12} sm={2} md={3} display="flex" justifyContent="left"></Grid>}
            <Grid xs={12} sm={8} md={6} display="flex" justifyContent="center" sx={{ fontSize: "30px", fontWeight: "700" }}>
              忘記密碼
            </Grid>
            {isAboveSm && <Grid xs={12} sm={2} md={3} display="flex" justifyContent="left"></Grid>}

            {isAboveSm && <Grid xs={12} sm={2} md={3} display="flex" justifyContent="left"></Grid>}
            <Grid xs={12} sm={8} md={6} display="flex" justifyContent="left">
              <Controller
                control={control}
                name={"tgUserName"}
                rules={{
                  required: "Telegram用戶名稱必須填寫",
                }}
                defaultValue={""}
                render={({ field }) => {
                  return (
                    <CustomTextField
                      {...field}
                      error={errors.tgUserName && true}
                      helperText={errors.tgUserName && errors.tgUserName.message}
                      id="tgUserName"
                      label="Telegram用戶名稱 (即填寫@XXXXX，不需要加上@)"
                      variant="filled"
                      size="small"
                      type="text"
                      fullWidth
                    />
                  );
                }}
              />
            </Grid>
            {isAboveSm && <Grid xs={12} sm={2} md={3} display="flex" justifyContent="left"></Grid>}

            {isAboveSm && <Grid xs={12} sm={2} md={3} display="flex" justifyContent="left"></Grid>}
            <Grid xs={12} sm={8} md={6} display="flex" justifyContent="left">
              <Controller
                control={control}
                name={"email"}
                rules={{
                  required: "電郵地址必須填寫",
                  pattern: {
                    value: /\S+@\S+\.\S+/,
                    message: "電郵地址格式不正確",
                  },
                }}
                defaultValue={""}
                render={({ field }) => {
                  return (
                    <CustomTextField
                      {...field}
                      error={errors.email && true}
                      helperText={errors.email && errors.email.message}
                      id="email"
                      label="電郵地址"
                      variant="filled"
                      size="small"
                      fullWidth
                    />
                  );
                }}
              />
            </Grid>
            {isAboveSm && <Grid xs={12} sm={2} md={3} display="flex" justifyContent="left"></Grid>}

            {isAboveSm && <Grid xs={12} sm={2} md={3} display="flex" justifyContent="left"></Grid>}
            <Grid xs={12} sm={8} md={6} display="flex" justifyContent="left">
              <Controller
                control={control}
                name={"password"}
                rules={{ required: "密碼必須填寫" }}
                defaultValue={""}
                render={({ field }) => {
                  return (
                    <CustomTextField
                      {...field}
                      error={errors.password && true}
                      helperText={errors.password && errors.password.message}
                      id="password"
                      label="密碼"
                      variant="filled"
                      size="small"
                      type="password"
                      fullWidth
                    />
                  );
                }}
              />
            </Grid>
            {isAboveSm && <Grid xs={12} sm={2} md={3} display="flex" justifyContent="left"></Grid>}

            {isAboveSm && <Grid xs={12} sm={2} md={3} display="flex" justifyContent="left"></Grid>}
            <Grid xs={12} sm={8} md={6} display="flex" justifyContent="left">
              <Controller
                control={control}
                name={"passwordConfirmation"}
                rules={{
                  required: "確認密碼必須填寫",
                  validate: (val: string) => {
                    if (watch("password") != val) {
                      return "與上述密碼不一致";
                    }
                  },
                }}
                defaultValue={""}
                render={({ field }) => {
                  return (
                    <CustomTextField
                      {...field}
                      error={errors.passwordConfirmation && true}
                      helperText={errors.passwordConfirmation && errors.passwordConfirmation.message}
                      id="passwordConfirmation"
                      label="確認密碼"
                      variant="filled"
                      size="small"
                      type="password"
                      fullWidth
                    />
                  );
                }}
              />
            </Grid>
            {isAboveSm && <Grid xs={12} sm={2} md={3} display="flex" justifyContent="left"></Grid>}

            {isAboveSm && <Grid xs={12} sm={2} md={3} display="flex" justifyContent="left"></Grid>}
            <Grid xs={12} sm={8} md={6} display="flex" justifyContent="center">
              <CustomButtonYellowBg type={"submit"} color={"primary"} variant="contained">
                重設密碼
              </CustomButtonYellowBg>
            </Grid>
            {isAboveSm && <Grid xs={12} sm={2} md={3} display="flex" justifyContent="left"></Grid>}
          </Grid>
        </FormContainer>
      </Box>
    </Container>
  );
}

export default ForgotPassword;
