import React from "react";
import styled from "@emotion/styled";
import { Typography, Box, Container, Link } from "@mui/material";
import { WhiteSectionTitle } from "../utils/customStyle";

function MembersOrientation() {
  const CustomDescription = styled(Typography)({
    color: "white",
    // marginLeft: "10px",
  }) as typeof Typography;

  const CustomBox = styled(Box)({
    paddingBottom: "30px",
  });

  return (
    <Container maxWidth="lg" component="main" sx={{ color: "white" }}>
      <WhiteSectionTitle>新人資訊區</WhiteSectionTitle>
      <CustomBox>
        <CustomDescription>Hello 歡迎嚟到SMH 美股Topaz會員Channel</CustomDescription>
        <CustomDescription>呢條IG link有解釋返每個group嘅用途</CustomDescription>
        <CustomDescription>
          <Link href="https://www.instagram.com/p/CdQM-cIBSQR/?igshid=YmMyMTA2M2Y=">https://www.instagram.com/p/CdQM-cIBSQR/?igshid=YmMyMTA2M2Y=</Link>
        </CustomDescription>
        <CustomDescription>==============================</CustomDescription>
      </CustomBox>

      <CustomBox>
        <CustomDescription>介紹多少少 SMH一共分咗4個門派</CustomDescription>
        <CustomDescription>佢哋啲老屎忽個個都學晒4個門派</CustomDescription>
        <CustomDescription>然後再睇情況運用</CustomDescription>
      </CustomBox>

      <CustomBox>
        <CustomDescription>信號派</CustomDescription>
        <CustomDescription>SMH主系統嘅股票/指數/ETF信號 </CustomDescription>
        <CustomDescription>(建議先學識)</CustomDescription>
        <CustomDescription>睇晒信號說明書(或者睇詳細教學片)</CustomDescription>
        <CustomDescription>學識睇我哋嘅核心信號先</CustomDescription>
        <CustomDescription>你會知道大方向邊隻升邊隻跌升到幾時跌到幾時 </CustomDescription>
        <CustomDescription>我哋嘅主Excel表會係每日開市前update and post出嚟 入面有成100幾隻主要指數/EFT/股票嘅信號 </CustomDescription>
        <CustomDescription>唔啱玩唔緊要 可以去問股區問股 逐隻幫你睇</CustomDescription>
      </CustomBox>

      <CustomBox>
        <CustomDescription>無腦派</CustomDescription>
        <CustomDescription>學睇異動Bot嘅信號 跟大戶玩</CustomDescription>
        <CustomDescription>識得自己分析邊張跟得邊張唔跟得 </CustomDescription>
        <CustomDescription>期權異動Channel嘅Pinned message 有</CustomDescription>
        <CustomDescription>文字教學 有唔明嘅地方記得問</CustomDescription>
      </CustomBox>

      <CustomBox>
        <CustomDescription>指標派</CustomDescription>
        <CustomDescription>期權工作坊x操作分享會有詳情教學</CustomDescription>
        <CustomDescription>學用指標短炒 1-15分鐘收工嗰隻</CustomDescription>
      </CustomBox>

      <CustomBox>
        <CustomDescription>圖表派</CustomDescription>
        <CustomDescription>學習自己畫圖搵出重要嘅位 </CustomDescription>
        <CustomDescription>再配合返信號/指標去用 事半功倍</CustomDescription>
        <CustomDescription>有唔明嘅地方記得問</CustomDescription>
      </CustomBox>

      <CustomBox>
        <CustomDescription>
          個人建議優先次序: 信號{">"}圖表{">"}無腦{">"}指標
        </CustomDescription>
      </CustomBox>

      <CustomBox>
        <CustomDescription>歡迎各位新朋友🙆🏻‍♂️</CustomDescription>
        <CustomDescription>我係Matthew 係SMH嘅Owner </CustomDescription>
      </CustomBox>

      <CustomBox>
        <CustomDescription>大家會係SMH見到其餘7位Admin</CustomDescription>
        <CustomDescription>JW (期權之鬼)</CustomDescription>
        <CustomDescription>JC (心態/基本面之鬼)</CustomDescription>
        <CustomDescription>熊大Hugo (指標派之鬼)</CustomDescription>
        <CustomDescription>齋騰曈 (圖表之鬼)</CustomDescription>
        <CustomDescription>Soul姐 (大市走勢/指數之鬼)</CustomDescription>
        <CustomDescription>肥貓 (乜都唔識 淨係識贏錢)</CustomDescription>
        <CustomDescription>Mi姐 (FX/畫圖之鬼)</CustomDescription>
      </CustomBox>

      <CustomBox>
        <CustomDescription>如果分辨邊個係admin呢？</CustomDescription>
        <CustomDescription>呢一個單向Channel只有admin可以講嘢 講完嘢後面會寫咗佢係邊個</CustomDescription>
        <CustomDescription>Admin係chatroom講嘢嘅話 佢哋個名後面會寫係admin</CustomDescription>
        <CustomDescription>佢哋各有所長 大家會慢慢認識佢哋</CustomDescription>
        <CustomDescription>佢哋會陪住大家玩</CustomDescription>
      </CustomBox>

      <CustomBox>
        <CustomDescription>建議大家唔好心急 </CustomDescription>
        <CustomDescription>睇咗資訊包嘅教學先</CustomDescription>
        <CustomDescription>呢到唔存在伸手黨/帶單/盲跟 </CustomDescription>
        <CustomDescription>全部都要求你肯花時間學同做決定</CustomDescription>
      </CustomBox>

      <CustomBox>
        <CustomDescription>大家有任何問題都可以隨便係chatroom問 或者PM任何一個admin問 </CustomDescription>
        <CustomDescription>千祈唔好怕醜</CustomDescription>
        <CustomDescription>祝大家旅途愉快😘</CustomDescription>
      </CustomBox>
    </Container>
  );
}

export default MembersOrientation;
