import styled from "@emotion/styled";
import { Typography } from "@mui/material";

const SubHeader = styled(Typography)({
  // your custom styles go here
  color: "#F1C024",
  fontSize: "40px",
  fontWeight: "600",
  margin: "15px",
}) as typeof Typography;

export default SubHeader;
