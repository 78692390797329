import React from "react";
import { RootState } from "../stores/store";
import { useAppSelector } from "../stores/hook";
import { Navigate } from "react-router-dom";

interface Props {
  children: JSX.Element;
  path?: string;
}

export const PrivateRoute: React.FC<Props> = ({ children, path }) => {
  const isLoading = useAppSelector((state: RootState) => state.loading.isLoading);
  const isAuth = useAppSelector((state: RootState) => state.auth.isAuth);

  if (isLoading && !isAuth) {
    <div style={{ color: "white" }}>Loading...</div>;
  } else if (!isLoading && isAuth) {
    return children;
  } else if (!isLoading && !isAuth) {
    return <Navigate to={"/"} replace state={{ path }} />;
  }
};

export default PrivateRoute;
