import client from "./config";

interface InitialValidation {
  tgUserName: string;
}

interface Register {
  tgUserName: string;
  email: string;
  password: string;
  passwordConfirmation: string;
}

interface Login {
  email: string;
  password: string;
}

interface DeauthorizeDiscord {
  databaseUserId: string;
  discordUserId: string;
}

interface ForgotPassword {
  tgUserName: string;
  email: string;
  password: string;
  passwordConfirmation: string;
}

export async function initialValidation(data: InitialValidation) {
  const res = await client.post("v1/user/initialValidation", data);
  const result = res.data;
  return result;
}

export async function register(data: Register) {
  const res = await client.patch("v1/user/register", data);
  const result = res.data;
  return result;
}

export async function login(data: Login) {
  const res = await client.post("v1/user/login", data);
  const result = res.data;
  return result;
}

export async function forgotPassword(data: ForgotPassword) {
  const res = await client.patch("v1/user/forgotPassword", data);
  const result = res.data;
  return result;
}

export async function deauthorizeDiscord(data: DeauthorizeDiscord) {
  const res = await client.patch("v1/user/logout/discord", data);
  const result = res.data;
  return result;
}

export async function verifyEmail(token: string | null) {
  const res = await client.patch(`v1/user/verify?token=${token}`);
  const result = res.data;
  return result;
}
