import styled from "@emotion/styled";
import { Typography } from "@mui/material";

const SectionTitle = styled(Typography)({
  // your custom styles go here
  color: "#F1C024",
  textAlign: "center",
  fontSize: "50px",
  fontWeight: "700",
  textShadow: "4px 4px 20px #F1C024",
}) as typeof Typography;

export default SectionTitle;
