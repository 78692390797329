import React, { useEffect } from "react";
import Typed from "typed.js";
import Grid from "@mui/material/Unstable_Grid2";
import { Container, Typography, useMediaQuery, useTheme } from "@mui/material";
import Features from "../components/Home/Features";
import PriceCard from "../components/Home/PriceCard";

function Home() {
  const theme = useTheme();
  const isBelowSm = useMediaQuery(theme.breakpoints.down("sm"));
  const isBelowMd = useMediaQuery(theme.breakpoints.down("md"));
  const isBelowLg = useMediaQuery(theme.breakpoints.down("lg"));

  const el = React.useRef(null);

  useEffect(() => {
    const typed = new Typed(el.current, {
      strings: ["以AI分析捕捉Smart Money動向"],
      typeSpeed: 70,
      backSpeed: 70,
      backDelay: 200,
      startDelay: 500,
      // loop: true,
    });

    return () => {
      // Destroy Typed instance during cleanup to stop animation
      typed.destroy();
    };
  }, [isBelowSm, isBelowMd, isBelowLg]);

  return (
    <>
      <Container maxWidth="lg" sx={{ py: "25px" }}>
        <Grid container>
          <Grid xs={12} flexDirection="column" justifyContent="center" alignItems="center" textAlign="center" px={{ xs: 3, lg: 6 }}>
            <Typography variant={isBelowSm ? "h6" : isBelowMd ? "h4" : isBelowLg ? "h3" : "h2"} color="white">
              <span ref={el} />
            </Typography>
            <Typography variant="body1" color="white" mt={1} mb={3} px={{ xs: 3, lg: 6 }}>
              唔想再做收割一班因消息而追入的散戶?
              <br />
              不如改變炒股思維 不跟炒消息
              <br />
              利用SMH皇牌AI訊號系統信號捕捉「聰明錢」動態
            </Typography>
          </Grid>
        </Grid>
      </Container>

      <Features />

      <Container maxWidth="lg" sx={{ py: "50px" }}>
        <PriceCard />
      </Container>
    </>
  );
}

export default Home;
