import { Box, Container } from "@mui/material";
import React, { useRef } from "react";
import { WhiteSectionTitle } from "../utils/customStyle";
import Marquee from "react-fast-marquee";
import { useOnLoadImages } from "../hooks/useOnLoadImages";

const stockImages = require.context("../images/testimonials/stocks", true);
const stockImageList = stockImages.keys().map((image) => image.substring(2));

const optionImages = require.context("../images/testimonials/options", true);
const optionImageList = optionImages.keys().map((image) => image.substring(2));

const cfdImages = require.context("../images/testimonials/cfd", true);
const cfdImageList = cfdImages.keys().map((image) => image.substring(2));

function Testimonial() {
  const wrapperRef = useRef<HTMLDivElement>(null);
  const imagesLoaded = useOnLoadImages(wrapperRef);

  return (
    <Container maxWidth="lg" component="main">
      {/* <Box>
        <WhiteSectionTitle>會員成果</WhiteSectionTitle>
        <Grid container spacing={4} alignItems="stretch">
          {feedbacks.map((feedback, index) => {
            return (
              <Grid xs={12} sm={6} md={4} key={index} style={{ display: "flex" }}>
                <Card
                  style={{ display: "flex", flexDirection: "column", padding: "24px", borderRadius: "20px", justifyContent: "space-between", width: "100%" }}
                >
                  <Box sx={{ marginTop: "8px", marginBottom: "16px" }}>
                    <Box sx={{ display: "flex", justifyContent: "center", position: "relative" }}>
                      <Avatar alt="Remy Sharp" src="/static/images/avatar/1.jpg" />
                    </Box>
                    <Typography noWrap sx={{ color: "#344767", fontSize: "18px", fontWeight: "700", textAlign: "center", mb: "16px" }}>
                      {feedback.name}
                    </Typography>
                    {feedback.comments.map((comment, index) => {
                      return (
                        <Typography key={index} sx={{ color: "rgb(123, 128, 154)", fontSize: "16px" }}>
                          {comment}
                        </Typography>
                      );
                    })}
                  </Box>
                  <Box>
                    <Typography sx={{ color: "grey", textAlign: "center" }}>
                      <FormatQuoteIcon sx={{ fontSize: "60px" }} />
                    </Typography>
                  </Box>
                </Card>
              </Grid>
            );
          })}
        </Grid>
      </Box> */}
      <Box sx={{ py: "25px" }} ref={wrapperRef}>
        {!imagesLoaded ? (
          <WhiteSectionTitle>Loading images...</WhiteSectionTitle>
        ) : (
          <>
            <WhiteSectionTitle>過千會員實戰成績見證 🚀</WhiteSectionTitle>
            <WhiteSectionTitle>正股</WhiteSectionTitle>

            <Marquee gradient={true} gradientColor={"rgb(26, 25, 46)"} gradientWidth="50px" style={{ borderRadius: "20px" }}>
              {stockImageList.map((item, index) => (
                <img
                  key={index}
                  src={require(`../images/testimonials/stocks/${item}`)}
                  alt={item}
                  style={{ objectFit: "contain", height: "200px", padding: "10px" }}
                />
              ))}
            </Marquee>
            <WhiteSectionTitle>期權</WhiteSectionTitle>
            <Marquee gradient={true} gradientColor={"rgb(26, 25, 46)"} gradientWidth="50px" style={{ borderRadius: "20px" }}>
              {optionImageList.map((item, index) => (
                <img
                  key={index}
                  src={require(`../images/testimonials/options/${item}`)}
                  alt={item}
                  style={{ objectFit: "contain", height: "200px", padding: "10px" }}
                />
              ))}
            </Marquee>
            <WhiteSectionTitle>CFD</WhiteSectionTitle>
            <Marquee gradient={true} gradientColor={"rgb(26, 25, 46)"} gradientWidth="50px" style={{ borderRadius: "20px" }}>
              {cfdImageList.map((item, index) => (
                <img
                  key={index}
                  src={require(`../images/testimonials/cfd/${item}`)}
                  alt={item}
                  style={{ objectFit: "contain", height: "200px", padding: "10px" }}
                />
              ))}
            </Marquee>
          </>
        )}
      </Box>
    </Container>
  );
}
export default Testimonial;
