import React from "react";
import { Box, Container, Input, styled } from "@mui/material";
import { Controller, FormContainer, SelectElement, SubmitHandler, useForm } from "react-hook-form-mui";
import Grid from "@mui/material/Unstable_Grid2";
import { WhiteSectionTitle } from "../../utils/customStyle";
import { AxiosError } from "axios";
import { clearError, createError } from "../../stores/error/errorSlice";
import { startAuthLoading, doneAuthLoading } from "../../stores/loading/loadingSlice";
import { useAppDispatch, useAppSelector } from "../../stores/hook";
import { bulkCreateUsers } from "../../api/user.api";
import { createSuccessMsg } from "../../stores/success/successSlice";
import CustomButtonYellowBg from "../../styles/CustomButtonYellowBg";
import { buildLink } from "../../api/config";
import SimpleBackdrop from "../../components/Common/SimpleBackdrop";
import { RootState } from "../../stores/store";
// import { downloadInvoice } from "../../api/invoice.api";

interface UploadUser {
  subscriptionid: number;
  memberList: File;
}

function UploadUser() {
  const BootstrapInput = styled(SelectElement)(() => ({
    "& .MuiInputBase-root": {
      background: "white",
      color: "black",
    },
    "& .MuiInputBase-root:after": {
      borderBottom: "2px solid #F1C024 !important",
    },
    "& .Mui-focused.MuiFormLabel-root": {
      color: "rgb(26, 25, 46) !important",
    },
    "& .MuiFilledInput-root:hover": {
      backgroundColor: "white",
      // Reset on touch devices, it doesn't add specificity
      "@media (hover: none)": {
        backgroundColor: "white",
      },
    },
    "& .MuiFilledInput-root.Mui-focused": {
      backgroundColor: "rgb(250, 241, 232)",
    },
  }));

  const CustomFileInput = styled(Input)(() => ({
    "& .MuiInputBase-root": {
      color: "white",
    },
  }));

  const formContext = useForm<UploadUser>();
  const {
    control,
    // formState: { errors },
  } = formContext;
  const { handleSubmit } = formContext;
  const dispatch = useAppDispatch();

  const isAuthLoading = useAppSelector((state: RootState) => state.loading.isAuthLoading);

  const onSubmit: SubmitHandler<UploadUser> = async (data) => {
    try {
      dispatch(startAuthLoading());
      dispatch(clearError());
      const formData = new FormData();
      formData.append("memberList", data.memberList);

      const response = await bulkCreateUsers(data.subscriptionid, formData);
      if (response.success) {
        const fileName = response.data;

        dispatch(
          createSuccessMsg({
            successMessage: "上載成功!",
            buttonMessage: "下載最新的Excel檔案",
            buttonUrl: buildLink(`/v1/user/${fileName}`),
          })
        );
      }

      // const response = await generateInvoice(data.subscriptionid, formData);
      dispatch(doneAuthLoading());
    } catch (error) {
      dispatch(createError(error as AxiosError));
      dispatch(doneAuthLoading());
    }
  };

  return (
    <Container maxWidth="lg" component="main">
      <Box>
        <WhiteSectionTitle>上載會員資料</WhiteSectionTitle>
      </Box>
      <Box>
        <FormContainer formContext={formContext} handleSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={4} justifyContent="center">
            <Grid xs={12} display="flex">
              <BootstrapInput
                label="訂閱"
                name="subscriptionid"
                fullWidth
                variant="filled"
                options={[
                  {
                    id: 1,
                    label: "Topaz收費頻道 (美股🇺🇸 + 港股🇭🇰)",
                  },
                  {
                    id: 2,
                    label: "Topaz收費頻道 (港股🇭🇰)",
                  },
                  {
                    id: 3,
                    label: "Topaz收費頻道 (美股🇺🇸)",
                  },
                ]}
              />
            </Grid>

            <Grid xs={12} display="flex" justifyContent="left">
              <Controller
                control={control}
                name={"memberList"}
                rules={{ required: "Member List is required" }}
                render={({ field: { ref, onChange } }) => {
                  return (
                    <CustomFileInput
                      sx={{ color: "white" }}
                      ref={ref}
                      onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                        onChange(event.target.files?.[0]);
                      }}
                      type="file"
                      id="picture"
                    />
                  );
                }}
              />
            </Grid>

            <Grid xs={12} display="flex" justifyContent="center">
              <CustomButtonYellowBg type={"submit"} color={"primary"} variant="contained">
                上載
              </CustomButtonYellowBg>
            </Grid>
          </Grid>
        </FormContainer>
      </Box>
      {isAuthLoading && <SimpleBackdrop />}
    </Container>
  );
}

export default UploadUser;
